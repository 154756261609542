import EventEmitter from 'eventemitter3';
var eventEmitter = new EventEmitter();
var EmitterService = {
  on: function on(event, fn) {
    return eventEmitter.on(event, fn);
  },
  once: function once(event, fn) {
    return eventEmitter.once(event, fn);
  },
  off: function off(event, fn) {
    return eventEmitter.off(event, fn);
  },
  emit: function emit(event, payload) {
    return eventEmitter.emit(event, payload);
  }
};
Object.freeze(EmitterService);
export default EmitterService;