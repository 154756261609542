var FormId = require("../../../components/Form/FormId").default;
var FormType = require("../../../components/Form/FormType").default;
var InputValidator = require("../../../components/Form/InputValidator").default;
module.exports = {
  header: {
    title: "",
    backButtonText: ""
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.PASSWORD_OLD,
      label: "Current password",
      type: FormType.PASSWORD,
      validator: InputValidator.NOT_EMPTY,
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true
    }, {
      id: FormId.PASSWORD_NEW,
      label: "New password",
      type: FormType.PASSWORD,
      validator: InputValidator.NOT_EMPTY,
      containerStyle: {
        marginTop: '1.3rem'
      },
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true
    }, {
      id: FormId.PASSWORD_NEW_CONFIRM,
      label: "Confirm password",
      type: FormType.PASSWORD,
      validator: InputValidator.NOT_EMPTY,
      containerStyle: {
        marginTop: '1.3rem'
      },
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true
    }],
    submitButtonText: "UPDATE"
  }]
};