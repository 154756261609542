import styled from 'styled-components';
import { ShadowLayer, Tooltip } from '..';

export const COLLECT_GRAND = {
  TOOLTIP: styled(Tooltip)`
    width: 50vh;
    top: 59vh;
    left: 8vh;
    @media only screen and (min-height: 750px) {
      top: 57vh;
    }

    @media only screen and (min-height: 950px) {
      top: 55vh;
    }
  `,
  TOP: styled(ShadowLayer)`
    height: 73.1vh;
    width: 39.6vh;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 8vh;

    @media only screen and (min-height: 800px) {
      height: 71.2vh;
    }

    @media only screen and (min-height: 930px) {
      height: 70.5vh;
    }

    @media only screen and (min-height: 1130px) {
      height: 69vh;
    }
  `,
  BOTTOM: styled(ShadowLayer)`
    height: 20vh;
    width: 39.6vh;
    top: 77px;
    margin-top: 73.4vh;
    bottom: 0px;
    right: 0px;
    left: 8vh;
    border-bottom-color: #fff;
    border-bottom-width: 4px;
  `,
  RIGHT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 100vw;
    left: 47.6vh;
  `,
  LEFT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 8vh;
    left: 0px;
  `,
};

