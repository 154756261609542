import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_ASSET_BASE_URL, SET_APP_BACKGROUND, SET_ASSETS_URLS, SET_TARGET, SHOW_CUSTOM_MODAL, SET_CACHE_ID, SET_RESTART_SESSION, SET_CURRENT_SCREEN, SET_LANGUAGE } from '../actions/types';
var initialState = {
  assetBaseUrl: '',
  appBackground: '',
  assetsUrls: {},
  target: null,
  showCustomModal: false,
  cacheId: null,
  restartSession: false,
  currentScreen: null,
  language: 'en'
};
var appReducer = function appReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_ASSET_BASE_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        assetBaseUrl: action.payload
      });
    case SET_APP_BACKGROUND:
      return _objectSpread(_objectSpread({}, state), {}, {
        appBackground: action.payload
      });
    case SET_ASSETS_URLS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assetsUrls: action.payload
      });
    case SET_RESTART_SESSION:
      return _objectSpread(_objectSpread({}, state), {}, {
        restartSession: action.payload
      });
    case SET_TARGET:
      return _objectSpread(_objectSpread({}, state), {}, {
        target: action.payload
      });
    case SHOW_CUSTOM_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        showCustomModal: action.payload
      });
    case SET_CACHE_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        cacheId: action.payload
      });
    case SET_CURRENT_SCREEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentScreen: action.payload
      });
    case SET_LANGUAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        language: action.payload
      });
    default:
      return state;
  }
};
export default appReducer;