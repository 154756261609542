import LevelUp from './LevelUp';
import { connect } from '../../node_modules/react-redux';
import { shouldShowTooltip as _shouldShowTooltip, tooltipDetails as _tooltipDetails } from '../../state/actions/tooltip';
import { setGameId as _setGameId } from '../../state/actions/game';
var nextGameUnlock = function nextGameUnlock(games, level) {
  return games.find(function (game) {
    return game.UNLOCK_LEVEL == level;
  });
};
var mapStateToProps = function mapStateToProps(state) {
  return {
    coins: state.levelInfo.previousLevelBonusCoins,
    maxBet: state.levelInfo.maxBet,
    gameUnlock: state.slotGames && state.slotGames.gameList && state.slotGames.gameList.length > 0 && state.slotGames.gameList[0].games && nextGameUnlock(state.slotGames.gameList[0].games, state.levelInfo.level)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    tooltipDetails: function tooltipDetails(value) {
      dispatch(_tooltipDetails(value));
    },
    shouldShowTooltip: function shouldShowTooltip(value) {
      dispatch(_shouldShowTooltip(value));
    },
    setGameId: function setGameId(gameId) {
      dispatch(_setGameId(gameId));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LevelUp);