import OnboardingLayer from './OnboardingLayer';
import { connect } from '../../node_modules/react-redux';
import { clearOnboarding as _clearOnboarding } from '../../state/actions/currentOnboarding';
var mapStateToProps = function mapStateToProps(_ref) {
  var actionCommands = _ref.actionCommands,
    _ref$currentOnboardin = _ref.currentOnboarding,
    position = _ref$currentOnboardin.position,
    tooltip = _ref$currentOnboardin.tooltip,
    ftueStep = _ref$currentOnboardin.ftueStep;
  return {
    actionCommands: actionCommands.actions[0],
    position: position,
    tooltip: tooltip,
    ftueStep: ftueStep
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearOnboarding: function clearOnboarding() {
      dispatch(_clearOnboarding());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLayer);