module.exports = {
  dimensions: {
    width: 34,
    height: 34
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  nine: {
    top: 9,
    left: 9,
    right: 9,
    bottom: 9
  }
};