module.exports = {
  dimensions: {
    width: 49,
    height: 74
  },
  optical: {
    top: 4,
    left: 7,
    right: 7,
    bottom: 11
  },
  nine: {
    top: 1,
    left: 4,
    right: 4,
    bottom: 1
  },
  content: {
    top: 6,
    left: 9,
    right: 9,
    bottom: 13
  }
};