import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SFormInputs from 'shared/screens/LinkAccountForm/components/FormInputs';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { filterCustomProperties } from 'shared/utils/properties';
import AutoFormInput from '../../../../components/AutoFormInput';
import PropertySelector from '../../../../components/PropertySelector';
import { styles } from '../../styles';
import CopySelector from 'shared/utils/copySelector';
import { GENERIC_PROPERTY } from 'shared/screens/LinkAccountForm/constants';
import DOBInputs from './components';

export const DefaultFormInputs = ({ handleInputChange, formValues, copys }) => {
  return (
    <SFormInputs
      render={args => {
        const { userProperty, propertySelector } = args;

        const customPropertyOption =
          userProperty === GENERIC_PROPERTY
            ? null
            : propertySelector.filter(filterCustomProperties(userProperty)).map(property => ({
                value: property.propertyId,
                label: property.propertyName,
              }));

        return (
          <>
            <div style={styles.inputRow}>
              <AutoFormInput
                emitInput={handleInputChange('accountNumber')}
                value={formValues.accountNumber}
                label=""
                type="text"
                containerStyle={styles.inputContainer}
                customClassName="transparentInput"
                inputStyle={true}
                placeholder={`${CopySelector.getCopy(CopySelector.COPY_TYPES.WILDCARD, copys)} ${getText(
                  TEXT_KEY.NUMBER
                )}`}
                trimInput={true}
              />
              {ENVIRONMENT_VARIABLES.SHOW_PROPERTY_SELECTOR === 'true' && (
                <div style={{ width: '51%', pointerEvents: customPropertyOption ? 'none' : 'auto' }}>
                  <PropertySelector
                    onChangeDOB={handleInputChange('propertyId')}
                    selectedPropertyId={formValues.propertyId}
                    styles={styles.propertyIdContainer}
                    customOptions={customPropertyOption}
                    hidePlaceHolder={customPropertyOption ? true : false}
                  />
                </div>
              )}
            </div>
            <p style={styles.rowLabel}>{getText(TEXT_KEY.DATE_OF_BIRTH)}</p>
            <DOBInputs handleInputChange={handleInputChange} formValues={formValues} />
          </>
        );
      }}
    />
  );
};

export default DefaultFormInputs;

