import { SET_IS_DOWNLOADING, CLEAR_IS_DOWNLOADING, SET_MAX_DOWNLOADED_GAMES, SET_DOWNLOAD_PROGRESS, CLEAR_DOWNLOAD_PROGRESS, SET_DOWNLOADED_GAMES, REMOVE_DOWNLOADED_GAME, SET_DOWNLOAD_ERROR_MESSAGE, CLEAR_DOWNLOAD_ERROR_MESSAGE, SET_DOWNLOAD_FAILED_GAMES } from './types';
export var setIsDownloading = function setIsDownloading(id) {
  return {
    type: SET_IS_DOWNLOADING,
    payload: id
  };
};
export var clearIsDownloading = function clearIsDownloading() {
  return {
    type: CLEAR_IS_DOWNLOADING
  };
};
export var setMaxDownloadedGames = function setMaxDownloadedGames(maxDownloadedGames) {
  return {
    type: SET_MAX_DOWNLOADED_GAMES,
    payload: maxDownloadedGames
  };
};
export var setDownloadedGames = function setDownloadedGames(downloadedGames) {
  return {
    type: SET_DOWNLOADED_GAMES,
    payload: downloadedGames
  };
};
export var setDownloadProgress = function setDownloadProgress(progress) {
  return {
    type: SET_DOWNLOAD_PROGRESS,
    payload: progress
  };
};
export var clearDownloadProgress = function clearDownloadProgress() {
  return {
    type: CLEAR_DOWNLOAD_PROGRESS
  };
};
export var removeDownloadedGame = function removeDownloadedGame(gameId) {
  return {
    type: REMOVE_DOWNLOADED_GAME,
    payload: gameId
  };
};
export var setDownloadErrorMessage = function setDownloadErrorMessage(errorMessage) {
  return {
    type: SET_DOWNLOAD_ERROR_MESSAGE,
    payload: errorMessage
  };
};
export var clearDownloadErrorMessage = function clearDownloadErrorMessage() {
  return {
    type: CLEAR_DOWNLOAD_ERROR_MESSAGE
  };
};
export var setDownloadFailedGames = function setDownloadFailedGames(downloadFailedGame) {
  return {
    type: SET_DOWNLOAD_FAILED_GAMES,
    payload: downloadFailedGame
  };
};