/**
 * TARGET_TYPES is a key-value pair of analytics destinations. These will usually
 * diverge between web and mobile, but some can be shared.
 *
 * CONST_CASE key maps to a filename on the desired platform
 */
export var TARGET_TYPES = {
  FACEBOOK_APP_EVENTS: 'facebookAppEvents',
  DIVE: 'dive',
  FIREBASE: 'firebase'
};