import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_IS_CONNECTED, SET_CONNECTION_TYPE, SAVE_DEVICE_INFO } from '../actions/types';
var initialState = {
  isConnected: true,
  connectionType: '',
  deviceInfo: {}
};
export var deviceReducer = function deviceReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_IS_CONNECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnected: action.payload
      });
    case SET_CONNECTION_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        connectionType: action.payload
      });
    case SAVE_DEVICE_INFO:
      var deviceInfo = _objectSpread(_objectSpread({}, state.deviceInfo), {}, {
        deviceInfo: action.payload
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        deviceInfo: deviceInfo
      });
    default:
      return state;
  }
};