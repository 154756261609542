import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBoundsWrapper from '../../components/ImageBoundsWrapper';

const OPTInputStyle = {
  width: '45px',
  height: '45px',
  borderRadius: '7px',
  border: '0px',
  marginLeft: '8px',
  marginRight: '8px',
  background: '#dddddd',
  fontSize: '20px',
};

const ModalContainer = styled.div`
  height: 40vh;
  margin-bottom: -1vw;
  margin-top: -1vw;
  width: 100%;
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 12% 0 6% 0;
`;

const HeaderContainer = styled(ImageBoundsWrapper)`
  left: 0;
  top: 0;
  object-fit: contain;
  width: 100%;
  z-index: 11;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  color: ${theme.palette.common[1]};
  font-size: 1.9rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 3.7rem;
  letter-spacing: 1px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
`;

const Message = styled.p`
  color: ${({ status }) =>
    status === 0 ? '#fff' : status === 1 || status === 3 ? theme.palette.success[1] : theme.palette.error[1]};
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const ButtonImage = styled.img`
  width: 50%;
`;

const ButtonText = styled.p`
  -webkit-text-stroke-color: ${theme.palette.primary[1]};
  -webkit-text-stroke-width: 1px;
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
`;

export {
  ButtonImage,
  ButtonText,
  HeaderContainer,
  Message,
  Button,
  ModalContainer,
  ModalContent,
  Title,
  TitleContainer,
  OPTInputStyle,
  ActionsContainer,
};

