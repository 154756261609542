import React, { useContext } from 'react';
import styled from 'styled-components';
import { DefaultTooltipContext } from './TooltipContainer';

function TooltipBorder() {
  const { bottomArrow, DefaultTooltipColorTheme } = useContext(DefaultTooltipContext);

  const stopColor1 = bottomArrow
    ? DefaultTooltipColorTheme.Border.stopColor2
    : DefaultTooltipColorTheme.Border.stopColor1;
  const stopColor2 = bottomArrow
    ? DefaultTooltipColorTheme.Border.stopColor1
    : DefaultTooltipColorTheme.Border.stopColor2;

  return <ContainerBorder colors={{ stopColor1, stopColor2 }} />;
}

export default TooltipBorder;

const ContainerBorder = styled.div`
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(
    to bottom,
    ${({ colors }) => colors?.stopColor1} 0%,
    ${({ colors }) => colors?.stopColor2} 100%
  );
  border-radius: 10px;
  z-index: 1;
`;

