import WebFinishPurchase from './WebFinishPurchase';
import { connect } from '../../node_modules/react-redux';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { clearSensitiveInfo as _clearSensitiveInfo, isOutOfCoinsPurchase as _isOutOfCoinsPurchase, isLTOPurchase as _isLTOPurchase } from '../../state/actions/prePurchase';
import { showVipAccessModal as _showVipAccessModal, clearLTOOffer as _clearLTOOffer } from '../../state/slices/consumables/consumables';
var mapStateToProps = function mapStateToProps(state) {
  var _state$gameInfo;
  return {
    cardNickname: state.prePurchase.cardNickname,
    route: state.prePurchase.route,
    ltoPurchase: state.prePurchase.ltoPurchase,
    promoCode: state.prePurchase.promoCode,
    extraParams: state.prePurchase.routeParams,
    purchaseType: state.prePurchase.purchaseType,
    creditCardType: state.prePurchase.creditCardType,
    lastDigits: state.prePurchase.lastDigits,
    transactionUUID: state.purchase.transaction_uuid,
    actionTrigger: state.purchase.actionTrigger,
    promoLink: state.pendingMessages.find(function (pendingMessage) {
      return pendingMessage.type === 'PROMO_LINK';
    }),
    coin: state.coin,
    level: state.levelInfo.level,
    loyalty: state.loyalty,
    target: state.app.target,
    gameId: (_state$gameInfo = state.gameInfo) === null || _state$gameInfo === void 0 ? void 0 : _state$gameInfo.gameId
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    clearSensitiveInfo: function clearSensitiveInfo() {
      dispatch(_clearSensitiveInfo());
    },
    showVipAccessModal: function showVipAccessModal(value) {
      dispatch(_showVipAccessModal(value));
    },
    isOutOfCoinsPurchase: function isOutOfCoinsPurchase(value) {
      dispatch(_isOutOfCoinsPurchase(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    clearLTOOffer: function clearLTOOffer() {
      dispatch(_clearLTOOffer());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WebFinishPurchase);