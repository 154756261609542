export const styles = {
  inputContainer: {
    flex: 1,
    '& input': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    },
  },
  captcha: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5px 0',
  },
  scrollBar: {
    height: '100%',
  },
  backArrow: {
    cursor: 'pointer',
    objectFit: 'contain',
  },
};

