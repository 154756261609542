import theme from 'shared/assets/style/theme';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  z-index: -10;
`;

const KycPopUpContainer = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const KycContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const KycCheckTextWrapper = styled.div`
  height: 15%;
  justify-content: center;
  align-items: center;
`;

const KycRewardMessageWrapper = styled.div`
  top: 1%;
  bottom: 0;
  width: 100%;
  height: 40%;
  flex-direction: column;
`;

const KycRewardMessageStyle = styled.span`
  color: ${theme.palette.common[1]};
  font-size: 16px;
  text-align: center;
`;

const KycContinueButtonWrapper = styled.div`
  height: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const kycConsentPopupStyles = {
  kycCheckTextStyle: {
    height: '10%',
    fontSize: '24px',
    fontWeight: '500',
    marginTop: '0',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common[1],
    textTransform: 'uppercase',
    textShadowColor: theme.palette.common[25],
    textShadowOffset: { width: '0', height: '0.6px' },
    textShadowRadius: '2px',
  },
  modalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6vh 20vw',
    zIndex: '20',
  },
  modalMainBodyStyle: {
    height: 238,
    width: 530,
  },
};

export {
  kycConsentPopupStyles,
  ModalBackground,
  KycPopUpContainer,
  KycContentWrapper,
  KycCheckTextWrapper,
  KycRewardMessageWrapper,
  KycRewardMessageStyle,
  KycContinueButtonWrapper,
};

