module.exports = {
  dimensions: {
    width: 89,
    height: 100
  },
  optical: {
    top: 14,
    left: 14,
    right: 10,
    bottom: 12
  }
};