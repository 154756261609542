import styled, { css } from 'styled-components';

export const VipAccessHaderTitle = css`
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
`;

export const DefaultVipHeaderTitle = styled.span`
  ${VipAccessHaderTitle}
`;

