import React from 'react';

export default function DefaultText({ style, string, isTimeInDanger, className }) {
  if (isTimeInDanger) style = { ...style, color: 'red' };

  return (
    <div style={{ fontFeatureSettings: '"tnum"', ...style }} className={className}>
      {string}
    </div>
  );
}

