import React, { Component } from 'react';
import { connect } from 'shared/node_modules/react-redux';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import AutoFormInput from '../AutoFormInput';

class PropertySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.favouritesOption = React.createRef();
  }

  handlePropertyList = () => {
    const propertiesList = !this.props.registerCard
      ? this.props.propertySelector.filter(properties => properties.showProperty)
      : this.props.propertySelector.filter(properties => properties.showProperty && properties.enableOrderCard);
    const newPropertyList = propertiesList.map(property => ({
      value: property.propertyId,
      label: property.propertyName,
    }));
    return newPropertyList;
  };

  render() {
    return (
      <AutoFormInput
        customClassName="transparentInput SelectBoxInput"
        emitInput={this.props.onChangeDOB}
        value={this.props.selectedPropertyId}
        label=""
        type="dropdown"
        options={this.props.customOptions ? this.props.customOptions : this.handlePropertyList()}
        placeholder={!this.props.hidePlaceHolder ? `${getText(TEXT_KEY.PROPERTY_SELECTOR)}` : ''}
        containerStyle={this.props.styles}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    propertySelector: state.properties.data.propertySelector,
  };
};

export default connect(mapStateToProps, null)(PropertySelector);

