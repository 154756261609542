import ENVIRONMENT_VARIABLES from '../../../../utils/environmentVariables';
var TOTAL_LOGIN_METHODS = 4; // We need to confirm it for web when we need to use it.
export var availableLoginMethodsCount = function availableLoginMethodsCount() {
  var isGuestAvailable = (ENVIRONMENT_VARIABLES === null || ENVIRONMENT_VARIABLES === void 0 ? void 0 : ENVIRONMENT_VARIABLES.PLAY_AS_GUEST) === 'true';
  var isFacebookAvailable = (ENVIRONMENT_VARIABLES === null || ENVIRONMENT_VARIABLES === void 0 ? void 0 : ENVIRONMENT_VARIABLES.FACEBOOK_LOGIN) === 'true';
  var totalLoginMethods = TOTAL_LOGIN_METHODS;
  if (!isGuestAvailable) totalLoginMethods--;
  if (!isFacebookAvailable) totalLoginMethods--;
  return {
    isGuestAvailable: isGuestAvailable,
    isFacebookAvailable: isFacebookAvailable,
    totalLoginMethods: totalLoginMethods
  };
};