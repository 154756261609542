module.exports = {
  dimensions: {
    width: 900,
    height: 56
  },
  nine: {
    top: 39,
    left: 58,
    right: 58,
    bottom: 3
  },
  optical: {
    top: 3,
    left: 1,
    right: 1,
    bottom: 1
  }
};