import { EVENT_TYPES } from '../constants';

export default {
  handleEvent: (eventType, payload = {}) => {
    let eventHandler;
    switch (eventType) {
      case EVENT_TYPES.VIEW:
        eventHandler = () => {
          FB.AppEvents.logPageView();
        };

        break;
      case EVENT_TYPES.REGISTRATION_COMPLETE:
        eventHandler = () => {
          FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, {
            [FB.AppEvents.ParameterNames.REGISTRATION_METHOD]: payload.type === 'facebook' ? 'facebook' : 'signUp',
            [FB.AppEvents.ParameterNames.CURRENCY]: 'USD',
          });
        };

        break;
      case EVENT_TYPES.PURCHASE_COMPLETE:
        eventHandler = () => {
          FB.AppEvents.logPurchase(payload.price, 'USD', {
            [FB.AppEvents.ParameterNames.CONTENT_ID]: payload.currencyPackageId,
            [FB.AppEvents.ParameterNames.CONTENT_TYPE]: payload.purchaseType,
          });
        };

        break;
      case EVENT_TYPES.TUTORIAL_COMPLETE:
        eventHandler = () => {
          FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_TUTORIAL, null, {
            [FB.AppEvents.ParameterNames.SUCCESS]: 1,
          });
        };

        break;
      default:
        break;
    }

    if (window.facebookHasInitialized && eventHandler) {
      eventHandler();
    } else if (eventHandler) {
      if (window.facebookAppEventQueue) {
        window.facebookAppEventQueue.push(eventHandler);
      } else {
        window.facebookAppEventQueue = [eventHandler];
      }
    }
  },
};
