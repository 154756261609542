import News from './News';
import { connect } from '../../node_modules/react-redux';
var mapStateToProps = function mapStateToProps(state) {
  return {
    newsData: state.promotions.welcomeNews,
    gameInfo: state.gameInfo,
    coin: state.coin.value,
    assetsUrls: state.app.assetsUrls
  };
};
export default connect(mapStateToProps, null)(News);