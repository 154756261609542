import styled from 'styled-components';
import { Tooltip } from '..';

export const CHECK_OUT = {
  TOOLTIP: styled(Tooltip)`
    width: 360px;
    bottom: 160px;
    right: calc(50vw - 595px);
    z-index: 100;
  `,
};

