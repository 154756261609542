import React from 'react';
import styled from 'styled-components';
const EventsRibbon = ({ item, renderElements }) => {
  const { isMainEvent } = item;
  const { Ribbon } = renderElements;

  return (
    <RibbonContainer isMainRibbon={isMainEvent}>
      {isMainEvent ? (
        <>
          <MainRibbonHeader style={{}}>
            <MainRibbonTrumboneLeft src={Ribbon.Trombone} />
            <MainRibbonCrown src={Ribbon.Crown} />
            <MainRibbonTrumboneRight src={Ribbon.Trombone} />
          </MainRibbonHeader>
          <MainRibbonTitle src={Ribbon.Title} />
        </>
      ) : (
        <CommonRibbon src={Ribbon} />
      )}
    </RibbonContainer>
  );
};

export default EventsRibbon;

const RibbonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  z-index: 10;
  width: 37vh;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  top: ${({ isMainRibbon }) => (isMainRibbon ? '-5.5rem' : '0px')};
`;
const MainRibbonTitle = styled.img`
  left: -6.5vh;
  top: -7.5vh;
  width: 135%;
  position: relative;
  @media only screen and (max-height: 800px) {
    top: -10.5vh;
  }
  @media only screen and (min-height: 800px) and (max-height: 900px) {
    top: -9.5vh;
  }
  @media only screen and (min-height: 900px) and (max-height: 950px) {
    top: -9vh;
  }
  @media only screen and (min-height: 955px) and (max-height: 1000px) {
    top: -8.5vh;
  }
`;
const MainRibbonCrown = styled.img`
  position: relative;
  bottom: 0.5rem;
  height: 40%;
  width: 40%;
  object-fit: contain;
`;
const MainRibbonTrumboneLeft = styled.img`
  height: 75%;
  width: 75%;
  @media only screen and (max-height: 700px) {
    height: 70%;
    width: 70%;
  }
`;
const MainRibbonTrumboneRight = styled.img`
  height: 75%;
  width: 75%;
  transform: rotateY(180deg);
  @media only screen and (max-height: 700px) {
    height: 70%;
    width: 70%;
  }
`;

const CommonRibbon = styled.img`
  z-index: 10;
  width: 10rem;
  position: relative;
  left: -0.5rem;
  top: -1rem;
  @media only screen and (max-height: 700px) {
    width: 8rem;
    left: -0.4rem;
  }
`;

const MainRibbonHeader = styled.div`
  display: flex;
  left: 0vh;
  top: -12vh;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
  position: relative;
  top: 1rem;

  @media only screen and (max-height: 700px) {
    top: 1.8rem;
  }
`;

