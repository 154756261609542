import { useContext } from 'react';
import NotificationContext from '../context/NotificationContext';
var useNotification = function useNotification() {
  var _useContext = useContext(NotificationContext),
    notification = _useContext.notification,
    addNotification = _useContext.addNotification,
    clearNotification = _useContext.clearNotification;
  return {
    notification: notification,
    addNotification: addNotification,
    clearNotification: clearNotification
  };
};
export default useNotification;