import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import generalUtils from '../../utils';
import { TooltipTypes } from '../../utils/tooltipConstants';
import { isTi } from '../../utils/targetApplication';
import { TEXT_KEY, getText } from '../localization';
export var missionFinished = function missionFinished(RFValue, deviceInfo) {
  var detail = {
    popUpType: TooltipTypes.MISSIONS,
    title: getText(TEXT_KEY.MISSION_DONE_CLAIM_REWARD_TOOLTIP)
  };
  if (generalUtils.getPlatform() === 'web') {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'AnimationSlideFromTop',
      style: {
        zIndex: 1000,
        left: tooltipPositionCheck()
      }
    });
  } else {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'in',
      containerStyle: {
        width: RFValue(200),
        height: deviceInfo.isTablet() ? RFValue(50) : RFValue(54),
        top: deviceInfo.isTablet() ? '12%' : '16%',
        right: '9%'
      }
    });
  }
  return detail;
};
export var missionInDangerZone = function missionInDangerZone(RFValue, deviceInfo) {
  var detail = {
    popUpType: TooltipTypes.MISSIONS,
    title: getText(TEXT_KEY.MISSION_ENDS_SOON_TOOLTIP)
  };
  if (generalUtils.getPlatform() === 'web') {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'AnimationSlideFromTop',
      style: {
        zIndex: 1000,
        left: tooltipPositionCheck()
      }
    });
  } else {
    detail = _objectSpread(_objectSpread({}, detail), {}, {
      animationState: 'in',
      containerStyle: {
        width: RFValue(210),
        height: deviceInfo.isTablet() ? RFValue(50) : RFValue(55),
        top: deviceInfo.isTablet() ? '12%' : '16%',
        right: '9%'
      }
    });
  }
  return detail;
};
var tooltipPositionCheck = function tooltipPositionCheck() {
  var _window = window,
    width = _window.innerWidth;
  var leftPosition;
  if (isTi) {
    leftPosition = width < 1600 ? '60%' : width < 2000 ? '64%' : '62.5%';
  } else {
    leftPosition = width < 1600 ? '60%' : '57%';
  }
  return leftPosition;
};