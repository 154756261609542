import { SET_ASSET_REFERENCE_COUNT, MARK_ASSET_LOADED, SET_LAST_INIT_APP, SET_INIT_APP_RESPONSE, SET_IS_INIT_APP_EXECUTING, SET_IS_DIVE_INITIALIZED, SET_INIT_ASSETS_LOADED, SET_INIT_TEXTS_LOADED } from './types';
export var setAssetReferenceCount = function setAssetReferenceCount(count) {
  return {
    type: SET_ASSET_REFERENCE_COUNT,
    payload: count
  };
};
export var markAssetLoaded = function markAssetLoaded(payload) {
  return {
    type: MARK_ASSET_LOADED,
    payload: payload
  };
};
export var setInitAssetsLoaded = function setInitAssetsLoaded(payload) {
  return {
    type: SET_INIT_ASSETS_LOADED,
    payload: payload
  };
};
export var setInitTextsLoaded = function setInitTextsLoaded(payload) {
  return {
    type: SET_INIT_TEXTS_LOADED,
    payload: payload
  };
};
export var setLastInitApp = function setLastInitApp(payload) {
  return {
    type: SET_LAST_INIT_APP,
    payload: payload
  };
};
export var setInitAppResponse = function setInitAppResponse(payload) {
  return {
    type: SET_INIT_APP_RESPONSE,
    payload: payload
  };
};
export var setIsInitAppExecuting = function setIsInitAppExecuting(payload) {
  return {
    type: SET_IS_INIT_APP_EXECUTING,
    payload: payload
  };
};
export var setIsDiveInitialized = function setIsDiveInitialized(payload) {
  return {
    type: SET_IS_DIVE_INITIALIZED,
    payload: payload
  };
};