import store from '../../state/store';
export var TUTORIAL_NAME = {
  MISSIONS_TUTORIAL: ' MISSIONS_TUTORIAL'
};
export var MissionTutorialStepsKeys = {
  CHECK_OUT: 0,
  EXPLORE: 1,
  CHECK_GOAL: 2,
  OPEN_GRADUALLY: 3,
  CHECK_REWARD: 4,
  COMPLETE_REQUESTED: 5,
  COLLECT_GRAND: 6,
  START_MISSION: 7,
  SEE_PROGRESS: 8,
  COMPLETED: 9
};
export var shouldChangeTutorialStep = function shouldChangeTutorialStep(tutorialStep) {
  return tutorialStep !== MissionTutorialStepsKeys.SEE_PROGRESS && tutorialStep !== MissionTutorialStepsKeys.COMPLETED;
};
export var tutorialOpacity = function tutorialOpacity(condition) {
  var activeTutorial = store.getState().tutorial.activeTutorial;
  return activeTutorial && condition ? 0.2 : 1;
};
export var tutorialActive = function tutorialActive(condition) {
  var activeTutorial = store.getState().tutorial.activeTutorial;
  return activeTutorial && condition;
};
export var tutorialPointerEvents = function tutorialPointerEvents(condition) {
  return condition ? 'none' : 'auto';
};