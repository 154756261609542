/**
 * Example Object for Sounds
 * {
 *  url: require(`./<filename>.<filetype>`),
 *  loop?: true | false
 * }
 */
var sounds = {
  bonus: {
    url: require("./bonus_large.mp3")
  },
  alert: {
    url: require("./alert.mp3")
  },
  ambient: {
    url: require("./ambient.mp3"),
    loop: true
  },
  backgroundMusic: {
    url: require('./MUS_LoobyMusic_BBGames_Loop.mp3'),
    loop: true
  },
  crowdsTalking: {
    url: require('./SFX_Crowd_Chatter.mp3'),
    loop: true
  },
  buttonClick: {
    url: require('./SFX_Button_Click_2.mp3')
  },
  coinDropLoop: {
    url: require('./SFX_CoinWithBell_Loop_1a.mp3')
  },
  coinDropTerminator: {
    url: require('./SFX_CoinWithBell_Terminator_1a.mp3')
  },
  featureCompletion: {
    url: require('./SFX_Feature_Complete.mp3')
  },
  lockOpen: {
    url: require('./SFX_New_Game_Unlocked_2.mp3')
  },
  popUp: {
    url: require('./SFX_SwishIn_1_soft.mp3')
  },
  popUpReverse: {
    url: require('./SFX_SwishIn_1_soft_reversed.mp3')
  }
};
export default sounds;