import React from 'react';
import { hasExplorerType, hasReadyState, hasRunningState } from 'shared/screens/Missions/utils/missionsPerformance';
import ProgressBarShineAnimation from './ProgressBarShineAnimation';
import styled from 'styled-components';
import { generateLayoutProgressbar } from 'shared/screens/Missions/utils/generateMissionRendering';

const ProgressBar = ({
  missionStatus,
  missionType,
  counter,
  shouldRunShineAnimation,
  missionsAssets,
  clientProgress,
}) => {
  const { percentage, goal, value } = counter;

  const {
    LayoutBar = null,
    LayoutBarPurple = null,
    ShadowBar = null,
    IconLocked = null,
    IconReady = null,
    FillBarStart = null,
    FillBarProgess = null,
    FillBarEnd = null,
  } = generateLayoutProgressbar(missionsAssets)[missionStatus];

  const currentPercentageText = () => {
    if (hasExplorerType(missionType)) {
      return hasRunningState(missionStatus) ? `${Math.floor(clientProgress)}%` : `${percentage}%`;
    } else return `${value}/${goal}`;
  };

  const currentPercentageBar =
    hasExplorerType(missionType) && hasRunningState(missionStatus) ? Math.floor(clientProgress) : percentage;

  return (
    <>
      {IconLocked && <IconStatusLocked src={IconLocked} resizeMode="contain" />}
      {IconReady && <IconStatusReady src={IconReady} />}
      {shouldRunShineAnimation && <ProgressBarShineAnimation shineAsset={missionsAssets.ANIMATIONS_LIGHT_SWEEP_17} />}
      {FillBarStart && (
        <FillBar>
          <FillStart src={FillBarStart} />
          <TextProgress>{currentPercentageText()}</TextProgress>
          <FillProgress src={FillBarProgess} percentage={currentPercentageBar} resizeMode="stretch" />
          <FillEnd src={FillBarEnd} />
        </FillBar>
      )}
      {!hasReadyState(missionStatus) && (
        <>
          <ShadowBarCard src={ShadowBar} />
          <BarCard src={hasExplorerType(missionType) ? LayoutBar : LayoutBarPurple} />
        </>
      )}
    </>
  );
};

export default ProgressBar;

const barTotalWidthVh = 36;
const barTotalHeightVh = 5;
const horizontalMarginVh = 2.4;
const barFillWidthVh = 2.5;
const barFillMarginVh = 0.2;
const barFillHeightVh = 4.6;
const textProgressFontSizeVh = barTotalHeightVh - barFillMarginVh * 2;

const BarCard = styled.img`
  position: absolute;
  bottom: 19vh;
  left: 5vh;
  height: ${barTotalHeightVh}vh;
  width: ${barTotalWidthVh}vh;
  z-index: 3;
`;

const ShadowBarCard = styled.img`
  position: absolute;
  bottom: 19vh;
  left: 5vh;
  height: ${barTotalHeightVh}vh;
  width: ${barTotalWidthVh}vh;
  z-index: 5;
`;

const FillBar = styled.div`
  position: absolute;
  bottom: 19.4vh;
  z-index: 4;
  left: 5.3vh;
`;

const FillStart = styled.img`
  margin-left: 0;
  margin-right: -1px;
  height: ${barFillHeightVh}vh;
  width: ${barFillWidthVh}vh;
  z-index: 4;
`;
const FillProgress = styled.img`
  height: ${barFillHeightVh}vh;
  width: ${props =>
    (Math.min(props.percentage, 98.5) * (barTotalWidthVh - horizontalMarginVh - barFillWidthVh)) / 100}vh;
  z-index: 4;
`;
const FillEnd = styled.img`
  margin-left: -1px;
  height: ${barFillHeightVh}vh;
  width: ${barFillWidthVh}vh;
  z-index: 4;
  transform: scaleX(-1);
`;

const TextProgress = styled.p`
  position: absolute;
  z-index: 5;
  color: white;
  font-weight: 900;
  font-style: italic;
  left: 14vh;
  top: -${barTotalHeightVh - textProgressFontSizeVh + barFillMarginVh * 2}vh;
  font-size: ${textProgressFontSizeVh}vh;
  text-shadow: 0 0px 3px #000;
`;

const IconStatusLocked = styled.img`
  position: absolute;
  bottom: 14.5vh;
  left: 15.5vh;
  height: 16vh;
  width: 15vh;
  z-index: 6;
`;

const IconStatusReady = styled.img`
  position: absolute;
  bottom: 13vh;
  left: 14vh;
  height: 18vh;
  width: 18vh;
  z-index: 6;
`;

