import styled from 'styled-components';
import { Tooltip } from '..';

export const COMPLETED = {
  TOOLTIP: styled(Tooltip)`
    width: 410px;
    top: 106px;
    right: calc(50vw - 610px);
    z-index: 1000;
  `,
};

