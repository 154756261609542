export var REWARD_CENTER_CATEGORY_TYPE = {
  COINS: 'Coins',
  //'COINS',
  HIGH_LIMIT: 'High Limit',
  //HIGH_LIMIT',
  ENTERTAINMENT: 'ENTERTAINMENT',
  ROCK_SHOP: 'Rock Shop',
  //'ROCK_SHOP',
  HOTEL: 'Hotel',
  //'HOTEL',
  FREE_PLAY: 'FREE_PLAY',
  UNITY_REWARDS: 'UNITY_REWARDS'
};
export var REWARD_CENTER_OFFERS_STATUS = {
  SOLD_OUT: 'SOLD_OUT',
  LIMITED: 'LIMITED',
  HOT: 'HOT',
  NEW: 'NEW',
  EXCLUSIVE: 'EXCLUSIVE',
  FEATURED: 'FEATURED'
};