export var ShopTabs = {
  BACK: 'go_back',
  COINS: 'coins',
  LOYALTY: 'loyalty',
  VIP: 'vip'
};
export var KYC_STATUS = {
  UNSET: '',
  PASS: 'Pass',
  FAILED: 'Fail'
};