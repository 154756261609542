import { connect } from 'shared/node_modules/react-redux';
import { setTutorialStep } from 'shared/state/slices/tutorial/tutorial';
import Home from './Home';

const mapStateToProps = state => {
  return {
    isTutorialActive: state.tutorial.activeTutorial,
    tutorialStep: state.tutorial.tutorialStep,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTutorialStep: payload => {
      dispatch(setTutorialStep(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
