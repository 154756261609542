import React, { Component } from 'react';
import MiddleLayout from './MiddleLayout';
import SplashScreenFooter from '../../components/AssetPreloader/components/SplashScreenFooter';

class Hri extends Component {
  componentDidMount() {
    let newSessionToken, newRestartToken;

    const getSession = () => {
      const elements = document.cookie.split(';');

      let sessionTkn = elements.find(e => e.indexOf('sessionToken') > -1);
      sessionTkn && (newSessionToken = sessionTkn.split('sessionToken=')[1]);

      let restartTkn = elements.find(e => e.indexOf('restartToken') > -1);
      restartTkn && (newRestartToken = restartTkn.split('restartToken=')[1]);
    };
    getSession();

    if (newSessionToken && newRestartToken) {
      this.props.setToken(newSessionToken);
      this.props.setRestart(newRestartToken);
      setTimeout(() => {
        document.cookie = 'sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = 'restartToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        window.location.href = window.location.href;
      }, 1000);
    }
  }

  render() {
    return (
      <MiddleLayout {...this.props}>
        <SplashScreenFooter />
      </MiddleLayout>
    );
  }
}

export default Hri;

