import theme from 'shared/assets/style/theme';
import styled from 'styled-components';

const ArrowButton = styled.img`
  position: absolute;
  bottom: 16px;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${({ isRightArrow }) =>
    isRightArrow &&
    `
  transform: rotate(180deg) translateY(2px);
  right: 0;
  `}

  ${({ customArrowStyle }) => customArrowStyle};
`;

const CustomRenderView = styled.div`
  ${({ customStyle }) => customStyle}
`;

const VerticalScrollTrack = styled.div`
  background-color: ${theme.palette.primary[24]};
  width: ${({ width }) => width}rem;
  top: 8px;
  right: 0px;
  bottom: 8px;
  border-radius: 8px;
  position: absolute;
  display: ${({ display }) => (display ? 'block' : 'none')};
  ${({ customStyle }) => customStyle}
`;

const VerticalScrollThumb = styled.div`
  background-color: ${theme.palette.primary[25]};
  width: ${({ width }) => width}rem;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  display: ${({ display }) => (display ? 'block' : 'none')};
  ${({ customStyle }) => customStyle}
`;

const HorizontalScrollTrack = styled.div`
  background-color: ${theme.palette.primary[24]};
  width: 97%;
  left: 14px;
  bottom: 30px;
  border-radius: 8px;
  height: ${({ height }) => height}px !important;
  position: absolute;
  display: ${({ display }) => (display ? 'block' : 'none')};

  @media only screen and (max-height: 700px) {
    bottom: 5vh;
  }
  ${({ customTrackStyle }) => customTrackStyle};
`;

const HorizontalScrollThumb = styled.div`
  background-color: ${theme.palette.primary[25]};
  height: ${({ height }) => height}px !important;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  display: ${({ display }) => (display ? 'block' : 'none')};
  ${({ customThumbStyle }) => customThumbStyle};
`;

export {
  ArrowButton,
  CustomRenderView,
  VerticalScrollTrack,
  VerticalScrollThumb,
  HorizontalScrollTrack,
  HorizontalScrollThumb,
};

