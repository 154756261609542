import React, { useState, useEffect, useCallback } from 'react';
import ModalContentWrapper from './ModalContentWrapper';
import ErrorHandlingWrapper from './ErrorHandlingWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { LINK_ACCOUNT_ERROR_TYPES } from 'shared/constants/linkAccountErrorTypes';
import { usePostLinkGuestAccountMutation } from 'shared/state/query/linkGuestAccount';
import { clearGoogleToken } from 'shared/state/actions/auth';
import store from 'shared/state/store';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';

export default function GoogleLinkOption(props) {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorObj);
  const [postLinkGuestAccount] = usePostLinkGuestAccountMutation();

  const renderGoogleButton = useCallback(() => {
    window.isGsiLoaded &&
      window.google.accounts.id.renderButton(document.getElementById('googleSignInBtn'), {
        theme: 'outline',
        width: 198,
        text: 'signin_with',
        hl: 'en',
        use_fedcm_for_prompt: true,
      });
  }, []);

  const handleLinkAccount = useCallback(() => {
    props.googleToken &&
      postLinkGuestAccount({
        credential: props.googleToken,
        loginMethod: AUTH_TYPES.GOOGLE,
      })
        .unwrap()
        .then(() => store.dispatch(clearGoogleToken()))
        .catch(error => {
          setErrorMessage({
            error: error?.data?.message || generalLoginError(),
            type: LINK_ACCOUNT_ERROR_TYPES.SERVER_ERROR,
          });
          store.dispatch(clearGoogleToken());
          setShowModal(true);
        });
  }, [props.googleToken]);

  useEffect(() => {
    renderGoogleButton();
  }, [renderGoogleButton]);

  useEffect(() => {
    handleLinkAccount();
  }, [handleLinkAccount]);

  return (
    <>
      <div style={{ height: '44px', width: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }} id="googleSignInBtn" />
      </div>
      <ModalContentWrapper isVisible={showModal} setModalState={setShowModal}>
        <ErrorHandlingWrapper
          setModalState={setShowModal}
          buttonClickHandler={false}
          error={errorMessage.error}
          buttonText={
            errorMessage.type === LINK_ACCOUNT_ERROR_TYPES.THIRD_PARTY_ERROR
              ? getText(TEXT_KEY.TRY_AGAIN)
              : getText(TEXT_KEY.CONTACT_BUTTON)
          }
        />
      </ModalContentWrapper>
    </>
  );
}

const generalLoginError = () => getText(TEXT_KEY.GENERIC_LOGIN_ERROR_MESSAGE);

const defaultErrorObj = {
  error: '',
  type: '',
};

