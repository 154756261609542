import OTPFormFooter from './OTPFormFooter';
import generateRender from 'shared/utils/generateRender';
import DefaultFormFooter from './DefaultFormFooter';

const FormFooter = generateRender({
  default: DefaultFormFooter,
  hri: OTPFormFooter,
  sga: OTPFormFooter,
});

export default FormFooter;

