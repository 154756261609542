export var Name = 'JsonParser';

/** @type {import("./parseEvent").Parser} */
export default function jsonParser(data) {
  // TODO: this try catch is needed because Greentube and Everi games
  // sometimes send invalid JSON, please check with them why this happens
  try {
    var payload = JSON.parse(data);
    if (payload && typeof payload === 'object' && payload !== null) {
      /** @type {import("./parseEvent").ParsedEvent} */
      var event = {
        payload: payload,
        _from: Name
      };
      return event;
    }
  } catch (e) {
    return false;
  }
  return false;
}