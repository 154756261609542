import React, { useContext } from 'react';
import { PromotionWrapper, PromotionSwitchHolder, CustomSwitch, PromotioSwitchText } from '../styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const PromotionSection = props => {
  const { Account } = useContext(ThemeContext);
  const { handle, value } = props;

  return (
    <PromotionWrapper>
      <PromotioSwitchText onClick={handle} theme={Account.Label} enabled={value}>
        {getText(TEXT_KEY.PROMOTIONAL_EMAIL_OPTION)}
      </PromotioSwitchText>
      <PromotionSwitchHolder>
        <CustomSwitch className="custom-switch">
          <>
            <input type="checkbox" checked={value} readOnly />
            <span className="slider" onClick={handle} />
          </>
        </CustomSwitch>
      </PromotionSwitchHolder>
    </PromotionWrapper>
  );
};
export default PromotionSection;

