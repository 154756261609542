export default {
  USERNAME: 'username',
  PASSWORD: 'password',
  PASSWORD_OLD: 'password_old',
  PASSWORD_NEW: 'password_new',
  PASSWORD_NEW_CONFIRM: 'password_new_confirm',
  TOS: 'termsOfService',
  PASSWORD_CONFIRM: 'password_confirm',
  FIRSTNAME: 'firstName',
  MIDDLENAME: 'middleName',
  LASTNAME: 'lastName',
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  EMAIL: 'email',
  DATE_OF_BIRTH: 'dateOfBirth',
  STATE: 'state',
  COUNTRY: 'country',
  ADDRESS: 'address',
  CITY: 'city',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  ZIP: 'zip',
  BILLING_PROFILE: 'billingProfile',
  CARD_HOLDER_NAME: 'cardHolderName',
  CARD_TYPE: 'cardType',
  CARD_NUMBER: 'cardNumber',
  CARD_EXPIRY: 'cardExpiry',
  CARD_EXPIRY_MONTH: 'cardExpiryMonth',
  CARD_EXPIRY_YEAR: 'cardExpiryYear',
  CARD_CVV: 'cardCVV',
  CARD_NICKNAME: 'cardNickname',
  SAVE_CARD: 'saveCard',
  PROMO_CODE: 'promoCard',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  DRIVER_LICENCE_NUMBER: 'driverLicenceNumber',
  DRIVER_LICENCE_CARD_NUMBER: 'driverLicenceCardNumber',
  PASSPORT_COUNTRY: 'passportCountry',
  UNIT_NUMBER: 'unitNumber',
  STREET_NUMBER: 'streetNumber',
  STREET_NAME: 'streetName',
  STREET_TYPE: 'streetType',
  POSTAL_CODE: 'postalCode',
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  SUBJECT: 'subject',
  BODY: 'body',
  CURRENT_EMAIL: 'current_email',
  EMAIL_OPT_IN: 'emailOptIn'
};