import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'TUTORIAL';
var initialState = {
  activeTutorial: false,
  tutorialName: null,
  tutorialStep: 0,
  blockLobby: false
};
var missionsTutorialSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setTutorialStep: function setTutorialStep(state, action) {
      state.tutorialStep = action.payload;
      state.blockLobby = state.tutorialStep == 0 ? true : false;
    },
    setActiveTutorial: function setActiveTutorial(state, action) {
      var _action$payload$block;
      state.activeTutorial = action.payload.activeTutorial;
      state.blockLobby = (_action$payload$block = action.payload.blockLobby) !== null && _action$payload$block !== void 0 ? _action$payload$block : action.payload.activeTutorial;
      state.tutorialName = action.payload.tutorialName;
    }
  }
});
var actions = missionsTutorialSlice.actions,
  reducer = missionsTutorialSlice.reducer;
var setTutorialStep = actions.setTutorialStep,
  setActiveTutorial = actions.setActiveTutorial;
export { setTutorialStep, setActiveTutorial };
export default reducer;