module.exports = {
  dimensions: {
    width: 553,
    height: 553
  },
  content: {
    top: 80,
    left: 82,
    right: 80,
    bottom: 80
  },
  optical: {
    top: 68,
    left: 68,
    right: 68,
    bottom: 68
  }
};