import { SET_ONBOARDING_HIGHLIGHTED_POSITION, SET_FTUE_STATE, SET_FTUE_STEP, SET_FTUE_GAME, SET_ONBOARDING_STEP, CLEAR_ONBOARDING } from './types';
export var setOnboardingHighlightedPosition = function setOnboardingHighlightedPosition(position) {
  var tooltip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    message: null
  };
  return {
    type: SET_ONBOARDING_HIGHLIGHTED_POSITION,
    payload: {
      position: position,
      tooltip: tooltip
    }
  };
};
export var setOnboardingStep = function setOnboardingStep(step) {
  return {
    type: SET_ONBOARDING_STEP,
    payload: step
  };
};
export var clearOnboarding = function clearOnboarding() {
  return {
    type: CLEAR_ONBOARDING
  };
};
export var setFtueState = function setFtueState(state) {
  return {
    type: SET_FTUE_STATE,
    payload: state
  };
};
export var setFtueGameId = function setFtueGameId(state) {
  return {
    type: SET_FTUE_GAME,
    payload: state
  };
};
export var setOnboardingFtueStep = function setOnboardingFtueStep(step) {
  return {
    type: SET_FTUE_STEP,
    payload: step
  };
};