module.exports = {
  dimensions: {
    width: 140,
    height: 71
  },
  optical: {
    top: 2,
    left: 0,
    right: 4,
    bottom: 4
  },
  content: {
    top: 10,
    left: 8,
    right: 9,
    bottom: 10
  },
  nine: {
    top: 0,
    left: 90,
    right: 0,
    bottom: 0
  }
};