import React, { useEffect, useReducer } from 'react';
import TrackingUrl from './TrackingURL';
import Screens from 'shared/screens';
import { connect } from 'shared/node_modules/react-redux';
import { setAssetsUrls } from 'shared/state/actions/app';
import { setUserAffId } from 'shared/state/actions/playerInfo';
import { store } from 'shared/state/store';
import { getUrls } from 'shared/state/query/urls';
import routeParams, { MOUNT_INTENTS } from 'shared/utils/routeParamConstants';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import audio from 'shared/utils/audio';
import { useGetWebTrackingUrlDataQuery } from 'shared/state/query/login';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import { Logger } from '../../lib/analytics/logger';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import useNotification from 'shared/hooks/useNotification';
import ERROR_CODE from 'shared/utils/errorCodes';
import { onGuestActivate } from 'shared/utils/guestLogin';

const TrackingURLComponent = props => {
  const { navigation, setUserAffId, termsAndConditionsUrl, privacyPolicyUrl } = props;
  const aff_id = React.useRef(0);
  const user_authorized = React.useRef(false);
  const { addNotification } = useNotification();
  const { data: landingPageData, error: landingPageError } = useGetWebTrackingUrlDataQuery(
    navigation.getParam(routeParams.PARAM_TRACKING_URL_AFF_ID)
  );

  const [landingPageState, updateLandingPageState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      template: null,
      backgroundImage: '',
      mainImage: '',
      firstCheckBoxClicked: false,
      secondCheckBoxClicked: false,
      trackingPageDescription: '',
      trackingPageBottomTxt: '',
      emailPermissionAccepted: null,
      authLoading: false,
      statusMessage: false,
      socialError: false,
      socialErrorType: null,
      error: null,
      isTermsModalVisible: false,
      terms: false,
      termsAccepted: false,
      selectedTerms: null,
      mountIntent: '',
    }
  );

  const closeModal = () => {
    audio.onClick();
    updateLandingPageState({
      isTermsModalVisible: false,
      termsAccepted: false,
      statusMessage: null,
      socialError: false,
      socialErrorType: null,
      error: null,
    });
  };

  const closeTermsModal = callback => {
    updateLandingPageState({
      isTermsModalVisible: false,
      selectedTerms: null,
    });
    callback && callback();
  };

  const openModal = mountIntent => {
    audio.playSfx('popUp', true, 1);
    updateLandingPageState({
      isTermsModalVisible: true,
      terms: false,
      termsAccepted: false,
      mountIntent: mountIntent,
    });
  };

  const setSuccessState = () => {
    updateLandingPageState({
      authLoading: true,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: null,
    });
  };

  const setErrorState = () => {
    updateLandingPageState({
      authLoading: false,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: null,
    });
  };

  const setCanceledState = ({ statusMessage, emailPermissionAccepted, socialErrorType }) => {
    updateLandingPageState({
      authLoading: false,
      statusMessage: statusMessage,
      emailPermissionAccepted: emailPermissionAccepted,
      socialError: true,
      socialErrorType: socialErrorType,
      error: null,
    });
  };

  const checkSelectedTerms = item => updateLandingPageState({ selectedTerms: item });

  const updatePageData = dataObj => {
    if (dataObj.active === 'no') {
      redirectToSplash(0);
      return;
    }
    updateLandingPageState({
      template: dataObj.template,
      trackingPageDescription: dataObj.text1,
      trackingPageBottomTxt: dataObj.text2,
    });
    getImageUrl(dataObj.bgimage, dataObj.bodyimage);
    aff_id.current = dataObj.aff_id;
  };

  const getImageUrl = async (backImageName, mainImageName) => {
    try {
      const url = await store.dispatch(getUrls.initiate()).unwrap();
      if (backImageName)
        updateLandingPageState({ backgroundImage: `${url.baseUrl}/app/images/backgrounds/${backImageName}` });
      if (mainImageName) updateLandingPageState({ mainImage: `${url.baseUrl}/app/images/general/${mainImageName}` });
    } catch (error) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        body: JSON.stringify(error),
        code: ERROR_CODE.GET_URLS_FAIL,
      });
    }
  };

  const changePageAppClass = () => {
    if (navigation.state.key === Screens.TrackingURL) {
      let element = document.getElementsByClassName('App');
      if (element[0]) element[0].className = 'AppStyle';
    }
  };

  const redirectToSplash = aff_id => {
    navigation.navigate(user_authorized.current ? Screens.Home : Screens.Start, {
      aff_id,
    });
  };

  const handleGuestActivate = () =>
    onGuestActivate(
      landingPageState.termsAccepted,
      landingPageState.mountIntent,
      updateLandingPageState,
      openModal,
      closeTermsModal,
      addNotification
    );

  const onFacebookActivate = () => {
    const { termsAccepted, mountIntent } = landingPageState;

    audio.onClick();
    updateLandingPageState({ termsAccepted: true });
    closeTermsModal();

    if (termsAccepted && mountIntent === MOUNT_INTENTS.FACEBOOK_LOGIN) {
      SocialLoginInterface.facebookLoginHandler({
        FB,
        emailPermissionAccepted: landingPageState.emailPermissionAccepted,
        setSuccessState: setSuccessState,
        setErrorState: setErrorState,
        setCanceledState: setCanceledState,
      });
    } else {
      openModal(MOUNT_INTENTS.FACEBOOK_LOGIN);
    }
  };

  const onSubmit = () => {
    audio.onClick();
    updateLandingPageState({ termsAccepted: true });
    closeModal();
    switch (landingPageState.mountIntent) {
      case MOUNT_INTENTS.FACEBOOK_LOGIN:
        onFacebookActivate();
        break;
      case MOUNT_INTENTS.GUEST_LOGIN:
        handleGuestActivate();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const token = store.getState().token.token;
    user_authorized.current = !!token;

    if (landingPageData) {
      updatePageData(landingPageData);
    } else if (landingPageError) {
      redirectToSplash(0);
    }

    changePageAppClass();
  }, [landingPageData, landingPageError]);

  useEffect(() => {
    const listener = () => {
      updateLandingPageState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
      let element = document.getElementsByClassName('AppStyle');
      element[0].className = 'App';
    };
  }, []);

  useEffect(() => {
    if (navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID)) {
      let affIdParam = navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID);
      Array.isArray(affIdParam) ? setUserAffId(affIdParam[0]) : setUserAffId(affIdParam);
    }
  }, []);

  return (
    <TrackingUrl
      {...props}
      landingPageState={landingPageState}
      updateLandingPageState={updateLandingPageState}
      termsAndConditionsUrl={termsAndConditionsUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      onFacebookActivate={onFacebookActivate}
      onGuestActivate={handleGuestActivate}
      redirectToSplash={redirectToSplash}
      aff_id={aff_id}
      closeModal={closeModal}
      onSubmit={onSubmit}
      closeTermsModal={closeTermsModal}
      checkSelectedTerms={checkSelectedTerms}
    />
  );
};

const mapStateToProps = state => {
  return {
    termsAndConditionsUrl: state.app.assetsUrls?.termsAndConditions ?? '',
    privacyPolicyUrl: state.app.assetsUrls?.privacyPolicy ?? '',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAssetsUrls: assetsUrls => {
      dispatch(setAssetsUrls(assetsUrls));
    },
    setUserAffId: assetsUrls => {
      dispatch(setUserAffId(assetsUrls));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingURLComponent);

