import { diveSdkInit } from '../../logger';
import QueueService from 'shared/services/QueueService';

const diveEventQueue = new QueueService();
export const sendDiveEvent = (diveEvent, isDiveInitialized) => {
  if (isDiveInitialized) {
    diveEvent();
  } else {
    diveEventQueue.enqueue(diveEvent);
    diveSdkInit();
  }
};

// processing the dive event queue
export const processDiveEventQueue = () => {
  while (!diveEventQueue.isEmpty()) {
    const diveEvent = diveEventQueue.dequeue();
    diveEvent && diveEvent();
  }
};

