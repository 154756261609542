import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { HeaderContainer, HeaderWrapper, HeaderHeight, SubTitle, HighlightedHeaderContainer } from './styleComponents';

const MillelacsHeader = () => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <HighlightedHeaderContainer>{getText(TEXT_KEY.SORRY)}!</HighlightedHeaderContainer>
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderHeight />
      <SubTitle>{getText(TEXT_KEY.SOMETHING_WENT_WRONG)}</SubTitle>
    </>
  );
};

export default MillelacsHeader;

