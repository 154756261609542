import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { EVENT_DOMAINS } from './domains';

/**
 * EVENT_SCREENS defines a specific section of the current domain
 */
export var EVENT_SCREENS = _defineProperty(_defineProperty({}, EVENT_DOMAINS.GENERAL, {
  LOBBY: 'LOBBY',
  TUTORIAL: 'TUTORIAL',
  MAIN: 'MAIN',
  INFO: 'INFO'
}), EVENT_DOMAINS.ACTIONS_COMMANDS, {
  APP_MODAL: 'APP_MODAL',
  NATIVE_MODAL: 'NATIVE_MODAL',
  ONBOARDING_TUTORIAL: 'ONBOARDING_TUTORIAL',
  MISSIONS_TUTORIAL: 'MISSIONS_TUTORIAL',
  APP_TOOLTIP: 'APP_TOOLTIP'
});