import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import { MissonsTextWrapper, TooltipBackground } from './styledComponent';

export default function MissionsTooltip({ props }) {
  const { displayText, animationClassName, missionAssets } = props;
  const tooltipBG = missionAssets && missionAssets['TOOLTIP_BACKING'];
  let style = props.style;
  const textLength = displayText && displayText.length;
  if (textLength > 80) {
    const tooltipDimention = {
      minWidth: '28rem',
      minHeight: '7.5rem',
    };
    style = { ...style, ...tooltipDimention };
  }

  return (
    <Animated.div
      className={`Mission-Tooltip-Container ${animationClassName ? animationClassName : 'AnimationFadeIn'}`}
      style={{
        ...style,
      }}
    >
      <TooltipBackground src={tooltipBG} />
      <MissonsTextWrapper>{displayText}</MissonsTextWrapper>
    </Animated.div>
  );
}

