import ConfirmEmailStep from './ConfirmEmailStep';
import DefaultStep from './DefaultStep';
import ShowMessageStep from './ShowMessage';

const FlowSteps = {
  SHOW_MESSAGE: ShowMessageStep,
  CONFIRM_EMAIL: ConfirmEmailStep,
  DEFAULT: DefaultStep,
};

const FlowStep = props => (FlowSteps[props.flowStep] || FlowSteps['DEFAULT'])(props);

export default FlowStep;
