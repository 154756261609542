import React from 'react';
import DefaultText from './DefaultText';
import MissionCardsTimerDangerText from './MissionCardsTimerDangerText';
import MissionCardsTimerText from './MissionCardsTimerText';
import timerGenerateRender from 'shared/components/TimeRemainingText/timerGenerateRender';
import EventsTimerText from './EventsTimerText';

const Components = {
  MISSION_CARD_TIMER_DANGER: MissionCardsTimerDangerText,
  MISSION_CARD_TIMER: MissionCardsTimerText,
  DEFAULT: DefaultText,
  EVENTS_CARD_TIMER: EventsTimerText,
};

export default function TextComponent(props) {
  const { inNavBar, isTimeInDanger, counterType } = props;
  const componentName = timerGenerateRender(inNavBar, isTimeInDanger, counterType);

  const TextOutput = Components[componentName];

  return <TextOutput {...props} />;
}

