import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { MISSIONS_STATES, MISSIONS_REWARD_BUNDLE_TYPE } from '../constants/missionsInfo';
import { hasGameIds, hasGrandType } from './missionsPerformance';
import { numberWithCommas, timeFormatter } from '../../../utils/number';
import { TEXT_KEY, getText } from '../../../utils/localization';
import cdnPath from '../../../utils/cdnPath';
import store from '../../../state/store';
import Utils from '../../../utils';
import { ASSET_KEY, assetSource } from '../../../utils/assetSource';
var generateBackgroundCard = function generateBackgroundCard(missionStatus, missionAssets) {
  return missionStatus === MISSIONS_STATES.RUNNING ? missionAssets === null || missionAssets === void 0 ? void 0 : missionAssets.CARD_BLUE : missionAssets === null || missionAssets === void 0 ? void 0 : missionAssets.CARD_PURPLE;
};
var generateFrameCard = function generateFrameCard(missionStatus, missionAssets) {
  return missionStatus === MISSIONS_STATES.RUNNING ? missionAssets === null || missionAssets === void 0 ? void 0 : missionAssets.CARD_BLUE_FRAME : missionAssets === null || missionAssets === void 0 ? void 0 : missionAssets.CARD_PURPLE_FRAME;
};
var generateBottomCard = function generateBottomCard(missionStatus, missionsAssets) {
  return missionStatus === MISSIONS_STATES.REWARD ? missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.CARD_GREEN : missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.CARD_ORANGE;
};
var generateImage = function generateImage(_ref, missionsAssets) {
  var counterType = _ref.counterType,
    params = _ref.params;
  return hasGameIds(params === null || params === void 0 ? void 0 : params.gameIds) ? Utils.getPlatform() === 'web' ? "".concat(cdnPath.SLOT_ICON_WEB).concat(params === null || params === void 0 ? void 0 : params.gameIds[0], ".png") : {
    uri: "".concat(cdnPath.SLOT_ICON_MOBILE).concat(params === null || params === void 0 ? void 0 : params.gameIds[0], ".png")
  } : generateImageCard(missionsAssets)[counterType];
};
var generateText = function generateText(_ref2, missionsAssets) {
  var counterType = _ref2.counterType;
  return generateTextCard(missionsAssets)[counterType];
};
var generateRewardType = function generateRewardType(_ref3) {
  var rewardBundleType = _ref3.rewardBundleType,
    rewardItems = _ref3.rewardItems;
  return rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT && !rewardItems[0].isRevealed ? getText(TEXT_KEY.MYSTERY_REWARD) : getText(TEXT_KEY.WIN_TEXT);
};
var coinImage = _defineProperty(_defineProperty(_defineProperty({}, MISSIONS_REWARD_BUNDLE_TYPE.COINS, assetSource(ASSET_KEY.COIN_ICON)), MISSIONS_REWARD_BUNDLE_TYPE.COINS_AND_XP_POINTS, assetSource(ASSET_KEY.COIN_ICON)), MISSIONS_REWARD_BUNDLE_TYPE.LOYALTY_POINTS, assetSource(ASSET_KEY.LOYALTY_ICON));
var generateCoinImage = function generateCoinImage(_ref4) {
  var rewardBundleType = _ref4.rewardBundleType,
    rewardItems = _ref4.rewardItems;
  if (rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT) {
    if (!rewardItems[0].isRevealed) return null;else return coinImage[rewardItems[0].rewardValue.rewardItems[0].rewardType];
  } else return coinImage[rewardBundleType];
};
var rewardValue = function rewardValue(rewardBundleType, rewardItems) {
  switch (rewardBundleType) {
    case MISSIONS_REWARD_BUNDLE_TYPE.COINS:
    case MISSIONS_REWARD_BUNDLE_TYPE.LOYALTY_POINTS:
      return numberWithCommas(rewardItems[0].rewardValue);
    case MISSIONS_REWARD_BUNDLE_TYPE.XP_MULTIPLIER:
      return "".concat(timeFormatter(rewardItems[0].rewardDurationSeconds), " of x").concat(rewardItems[0].rewardValue, " XP");
    case MISSIONS_REWARD_BUNDLE_TYPE.TIMED_BONUS_MULTIPLIER:
      return "".concat(timeFormatter(rewardItems[0].rewardDurationSeconds), " of x").concat(rewardItems[0].rewardValue, " Timed Bonus");
    case MISSIONS_REWARD_BUNDLE_TYPE.COINS_AND_XP_POINTS:
      return "".concat(Utils.truncateFormat(rewardItems[0].rewardValue), " + XP");
  }
};
var generateRewardValue = function generateRewardValue(_ref5) {
  var rewardBundleType = _ref5.rewardBundleType,
    rewardItems = _ref5.rewardItems;
  if (rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT) {
    if (!rewardItems[0].isRevealed) return null;else return rewardValue(rewardItems[0].rewardValue.rewardItems[0].rewardType, rewardItems[0].rewardValue.rewardItems);
  } else return rewardValue(rewardBundleType, rewardItems);
};
export var generateDescription = function generateDescription(missionStatus, _ref6) {
  var goalMessage = _ref6.goalMessage;
  return missionStatus !== MISSIONS_STATES.REWARD && goalMessage;
};
var generateGrandPrizeCard = function generateGrandPrizeCard(_ref7, missionsAssets, _ref8) {
  var rewardItems = _ref7.rewardItems;
  var goalMessage = _ref8.goalMessage;
  return {
    GrandPrizeCard: missionsAssets.GRAND_PRIZE,
    BackgroundCard: missionsAssets.CARD_PURPLE,
    FrameCard: missionsAssets.CARD_PURPLE_FRAME,
    BottomCard: missionsAssets.CARD_ORANGE,
    Image: missionsAssets.GRAND_PRIZE_IMAGE,
    RewardType: getText(TEXT_KEY.WIN_TEXT),
    RewardCoinImage: assetSource(ASSET_KEY.COIN_ICON),
    RewardValue: numberWithCommas(rewardItems[0].rewardValue),
    Description: goalMessage
  };
};
var generateImageCard = function generateImageCard(missionsAssets) {
  return {
    SPIN_X_TIMES: missionsAssets.FREE_SPINS_IMAGE,
    SPIN_X_TIMES_Y_GAMES: missionsAssets.FREE_SPINS_IMAGE,
    WIN_X_TIMES: missionsAssets.COIN_IMAGE,
    WIN_X_TIMES_Y_GAMES: missionsAssets.COIN_IMAGE,
    WIN_X_AMOUNT: missionsAssets.COIN_IMAGE,
    WIN_X_AMOUNT_Y_GAMES: missionsAssets.COIN_IMAGE,
    WAGE_X_AMOUNT: missionsAssets.COIN_IMAGE,
    COLLECT_BONUS_X_TIMES: missionsAssets.COLLECT_BONUS_IMAGE,
    COMPLETE_MISSION_X_TIMES: missionsAssets.COLLECT_BONUS_IMAGE
  };
};
var generateTextCard = function generateTextCard(missionsAssets) {
  return {
    SPIN_X_TIMES: missionsAssets.SPIN_TEXT,
    SPIN_X_TIMES_Y_GAMES: missionsAssets.SPIN_TEXT,
    WIN_X_TIMES: missionsAssets.WIN_TEXT,
    WIN_X_TIMES_Y_GAMES: missionsAssets.WIN_TEXT,
    WIN_X_AMOUNT: missionsAssets.WIN_TEXT,
    WIN_X_AMOUNT_Y_GAMES: missionsAssets.WIN_TEXT,
    WAGE_X_AMOUNT: missionsAssets.BET_TEXT,
    COLLECT_BONUS_X_TIMES: missionsAssets.COLLECT_BONUS_TEXT,
    COMPLETE_MISSION_X_TIMES: missionsAssets.COLLECT_BONUS_TEXT
  };
};
var generateMissionCardDataRendering = function generateMissionCardDataRendering(_ref9) {
  var missionType = _ref9.missionType,
    missionStatus = _ref9.missionStatus,
    counter = _ref9.counter,
    reward = _ref9.reward;
  var missionsAssets = store.getState().assetsPackage.assets.MISSIONS_FEATURE;
  if (hasGrandType(missionType)) return generateGrandPrizeCard(reward, missionsAssets, counter);
  return {
    BackgroundCard: generateBackgroundCard(missionStatus, missionsAssets),
    FrameCard: generateFrameCard(missionStatus, missionsAssets),
    BottomCard: generateBottomCard(missionStatus, missionsAssets),
    Image: generateImage(counter, missionsAssets, missionsAssets),
    Text: generateText(counter, missionsAssets),
    RewardType: generateRewardType(reward),
    RewardCoinImage: generateCoinImage(reward),
    RewardValue: generateRewardValue(reward),
    Description: generateDescription(missionStatus, counter)
  };
};
export default generateMissionCardDataRendering;