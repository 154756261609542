export var EVENTS_FEATURE_STATUS = {
  ACTIVE: 'ACTIVE',
  MAINTENANCE: 'MAINTENANCE',
  COMING_SOON: 'COMING_SOON',
  INACTIVE: 'INACTIVE',
  MUST_UPDATE: 'MUST_UPDATE'
};
export var EVENTS_GAMEPLAY_STATUS = {
  PENDING: 'PENDING',
  TUTORIAL: 'TUTORIAL',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  PAUSED: 'PAUSED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED'
};
export var EVENTS_STATUS = {
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  FINISHED: 'FINISHED'
};
export var EVENTS_TYPE = {
  LIVE_OPS: 'LIVEOPS',
  SWEEPSTAKE: 'SWEEPSTAKE'
};
export var EVENTS_CARD_TYPE = {
  SINGLE_GAME: 'SINGLE_GAME',
  MULTIPLE_GAMES: 'MULTIPLE_GAMES',
  MULTIPLE_ACTIVITIES: 'MULTIPLE_ACTIVITIES',
  TOP_BANNER: 'TOP_BANNER',
  ALL_GAMES: 'ALL_GAMES'
};
export var EVENTS_ACTIONS = {
  NOTIFY_EVENT_DISPLAYED: 'NOTIFY_EVENT_DISPLAYED',
  NOTIFY_EVENT_TOOLTIP_DISPLAYED: 'NOTIFY_EVENT_TOOLTIP_DISPLAYED',
  NOTIFY_EVENTS_TUTORIAL_COMPLETED_REQUEST: 'NOTIFY_EVENTS_TUTORIAL_COMPLETED_REQUEST',
  HTTP_REQUEST: 'HTTP_REQUEST'
};