import { SHOULD_SHOW_TOOLTIP, TOOLTIP_DETAILS } from './types';
export var shouldShowTooltip = function shouldShowTooltip(value) {
  return {
    type: SHOULD_SHOW_TOOLTIP,
    payload: value
  };
};
export var tooltipDetails = function tooltipDetails(value) {
  return {
    type: TOOLTIP_DETAILS,
    payload: value
  };
};