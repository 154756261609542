import styled, { css } from 'styled-components';

const TooltipContainerStyle = css`
  padding: 0;
  position: relative;
  @media (max-height: 800px) {
    top: 1.5rem;
    left: -1rem;
  }
  @media (min-height: 800px) and (max-height: 865px) {
    top: 3rem;
    left: 0rem;
  }
  @media (min-height: 950px) and (max-height: 990px) {
    top: 5.5rem;
    left: -1.5rem;
  }
  @media (min-height: 870px) and (max-height: 950px) {
    top: 3.5rem;
    left: 0rem;
  }
  @media (min-height: 1000px) {
    top: 8rem;
    left: 1.5rem;
  }
`;

const PurchaseConfirmationTitle = styled.p`
  font-size: 1.5vmin;
  font-weight: 500;
  position: relative;
  top: -0.5rem;
  margin: 0 auto;
  @media (min-height: 1000px) {
    font-size: 1.3vmin;
    top: 0rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-height: 1000px) {
    gap: 0.5rem;
  }
`;

export { PurchaseConfirmationTitle, TooltipContainerStyle, ActionsContainer };

