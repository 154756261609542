/**
 * EVENT_ACTIONS actions performed with/on the widget
 */
export var EVENT_ACTIONS = {
  DISPLAY: 'DISPLAY',
  PRESS: 'PRESS',
  COMPLETE: 'COMPLETE',
  SCROLL: 'SCROLL',
  FAIL: 'FAIL',
  START: 'START'
};