import React, { createContext } from 'react';
import styled from 'styled-components';
import TooltipBackground from './TooltipBackground';
import TooltipBorder from './TooltipBorder';
import TooltipArrow from './TooltipArrow';
import useThemeContext from 'shared/context/hooks/useThemeContext';

export const DefaultTooltipContext = createContext();
function TooltipContainer({
  children,
  bottomArrow,
  containerStyle,
  arrowStyle,
  actionHandler,
  showArrow = true,
  arrowOverStyle = {},
}) {
  const { DefaultTooltip } = useThemeContext();
  const defaultColor = DefaultTooltip?.Background?.stopColor1;
  const onPressTooltip = () => typeof actionHandler === 'function' && actionHandler();

  return (
    <DefaultTooltipContext.Provider
      value={{ bottomArrow, arrowStyle, DefaultTooltipColorTheme: DefaultTooltip, arrowOverStyle }}
    >
      <Container containerStyle={containerStyle} onClick={onPressTooltip} bgColor={defaultColor}>
        <TooltipContainer.Background />
        <TooltipContainer.Border />
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {showArrow && <TooltipContainer.Arrow />}
      </Container>
    </DefaultTooltipContext.Provider>
  );
}

export default TooltipContainer;

TooltipContainer.Background = TooltipBackground;
TooltipContainer.Border = TooltipBorder;
TooltipContainer.Arrow = TooltipArrow;

const Container = styled.div`
  visibility: visible;
  opacity: 1;
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: ${({ bgColor }) => bgColor};
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  ${({ containerStyle }) => containerStyle}
`;

const ChildrenWrapper = styled.div`
  z-index: 3;
`;

