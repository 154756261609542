import React, { useContext } from 'react';
import styled from 'styled-components';
import { DefaultTooltipContext } from './TooltipContainer';

function TooltipBackground() {
  const { bottomArrow, DefaultTooltipColorTheme } = useContext(DefaultTooltipContext);

  const stopColor1 = bottomArrow
    ? DefaultTooltipColorTheme.Background.stopColor2
    : DefaultTooltipColorTheme.Background.stopColor1;
  const stopColor2 = bottomArrow
    ? DefaultTooltipColorTheme.Background.stopColor1
    : DefaultTooltipColorTheme.Background.stopColor2;

  return <ContainerBackground colors={{ stopColor1, stopColor2 }} />;
}

export default TooltipBackground;

const ContainerBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(
    to bottom,
    ${({ colors }) => colors?.stopColor1} 0%,
    ${({ colors }) => colors?.stopColor2} 100%
  );
  border-radius: 10px;
  z-index: 2;
`;

