import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';
function useControlUpgradeModal(_ref) {
  var _ref$queryResult = _ref.queryResult,
    queryResult = _ref$queryResult === void 0 ? {} : _ref$queryResult;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showModal = _useState2[0],
    setShowModal = _useState2[1];
  useEffect(function () {
    !showModal && !(queryResult !== null && queryResult !== void 0 && queryResult.isUninitialized) && (queryResult === null || queryResult === void 0 ? void 0 : queryResult.reset());
  }, [showModal, queryResult.isUninitialized]);
  return {
    showModal: showModal,
    setShowModal: setShowModal
  };
}
export default useControlUpgradeModal;