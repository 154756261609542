import generateRender from 'shared/utils/generateRender';
import { DefaultTermsModal } from './TermsModalComponents/DefaulTermsModal';
import { StarTermsModal } from './TermsModalComponents/StarTermsModal';

const TermsModal = generateRender({
  default: DefaultTermsModal,
  star: StarTermsModal,
});

export default TermsModal;
