//termsAndConditions
import { termsAndConditionsIsFetching, termsAndConditionsRequestDataSuccess, termsAndConditionsRequestError } from '../slices/termsAndConditions/termsAndConditions';
import { progressiveJackpotsIsFetching, progressiveJackpotsRequestDataSuccess, progressiveJackpotsRequestError } from '../slices/progressiveJackpots/progressiveJackpots';
import { propertiesIsFetching, propertiesRequestDataSuccess, propertiesRequestError } from '../slices/properties/properties';
import { getTermsAndConditions } from '../../api/TermsAndConditions';
import { getProgressiveJackpots } from '../api/ProgressiveJackpots';
import { getTexts, initProperty } from '../api/properties';
var termsAndConditionsAction = {
  fetchData: getTermsAndConditions,
  isFetching: termsAndConditionsIsFetching,
  requestDataSuccess: termsAndConditionsRequestDataSuccess,
  requestError: termsAndConditionsRequestError
};
var progressiveJackpotsAction = {
  fetchData: getProgressiveJackpots,
  isFetching: progressiveJackpotsIsFetching,
  requestDataSuccess: progressiveJackpotsRequestDataSuccess,
  requestError: progressiveJackpotsRequestError
};
var propertiesAction = {
  fetchData: initProperty,
  fetchTexts: getTexts,
  isFetching: propertiesIsFetching,
  requestDataSuccess: propertiesRequestDataSuccess,
  requestError: propertiesRequestError
};
export var ACTION = {
  TERMS_AND_CONDITIONS: termsAndConditionsAction,
  PROGRESSIVE_JACKPOTS: progressiveJackpotsAction,
  PROPERTIES: propertiesAction
};