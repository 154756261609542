import styled from 'styled-components';
import {
  CHECK_GOAL,
  CHECK_OUT,
  EXPLORE,
  OPEN_GRADUALLY,
  CHECK_REWARD,
  COMPLETE_REQUESTED,
  COLLECT_GRAND,
  START_MISSION,
  SEE_PROGRESS,
  COMPLETED,
} from './';

export const ShadowLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 5;
`;

export const Container = styled.div`
  position: absolute;
  flex: 1;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ activeTutorial }) => (activeTutorial ? 'flex' : 'none')};
  z-index: 410;
`;

export const Layer = styled.div`
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  position: absolute;
  flex: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const SkipButtonContainer = styled.div`
  position: absolute;
  width: 20vh;
  z-index: 10;
  top: 82px;
  left: 2vh;
  cursor: pointer;
`;

export const SkipButton = styled.img`
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const Tooltip = styled.img`
  position: absolute;
  z-index: 6;
`;

export const tutorialStepLayout = missionsAssets => [
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_1,
    TOOLTIP: CHECK_OUT.TOOLTIP,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_2,
    TOOLTIP: EXPLORE.TOOLTIP,
    TOP: EXPLORE.TOP,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_3,
    TOOLTIP: CHECK_GOAL.TOOLTIP,
    TOP: CHECK_GOAL.TOP,
    BOTTOM: CHECK_GOAL.BOTTOM,
    RIGHT: CHECK_GOAL.RIGHT,
    LEFT: CHECK_GOAL.LEFT,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_4,
    TOOLTIP: OPEN_GRADUALLY.TOOLTIP,
    TOP: OPEN_GRADUALLY.TOP,
    BOTTOM: OPEN_GRADUALLY.BOTTOM,
    RIGHT: OPEN_GRADUALLY.RIGHT,
    LEFT: OPEN_GRADUALLY.LEFT,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_5,
    TOOLTIP: CHECK_REWARD.TOOLTIP,
    TOP: CHECK_REWARD.TOP,
    BOTTOM: CHECK_REWARD.BOTTOM,
    RIGHT: CHECK_REWARD.RIGHT,
    LEFT: CHECK_REWARD.LEFT,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_6,
    TOOLTIP: COMPLETE_REQUESTED.TOOLTIP,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_7,
    TOOLTIP: COLLECT_GRAND.TOOLTIP,
    TOP: COLLECT_GRAND.TOP,
    BOTTOM: COLLECT_GRAND.BOTTOM,
    RIGHT: COLLECT_GRAND.RIGHT,
    LEFT: COLLECT_GRAND.LEFT,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_8,
    TOOLTIP: START_MISSION.TOOLTIP,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_9,
    TOOLTIP: SEE_PROGRESS.TOOLTIP,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_10,
    TOOLTIP: COMPLETED.TOOLTIP,
  },
];

