import React from 'react';
import AutoFormInput from '../../../../components/AutoFormInput';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SFormInputs from 'shared/screens/LinkAccountForm/components/FormInputs';
import { filterCustomProperties } from 'shared/utils/properties';
import { GENERIC_PROPERTY } from 'shared/screens/LinkAccountForm/constants';
import { styles } from '../../styles';
import PropertySelector from '../../../../components/PropertySelector';

const MillelacsFormInputs = ({ handleInputChange, formValues }) => {
  return (
    <SFormInputs
      render={args => {
        const { userProperty, propertySelector } = args;
        const customPropertyOption =
          userProperty === GENERIC_PROPERTY
            ? null
            : propertySelector.filter(filterCustomProperties(userProperty)).map(property => ({
                value: property.propertyId,
                label: property.propertyName,
              }));

        return (
          <>
            <div style={styles.inputRow}>
              <AutoFormInput
                emitInput={handleInputChange('accountNumber')}
                value={formValues.accountNumber}
                label=""
                type="text"
                containerStyle={styles.inputContainer}
                customClassName="transparentInput"
                inputStyle={true}
                placeholder={`${getText(TEXT_KEY.LOYALTY_CARD_TITLE)} ${getText(TEXT_KEY.NUMBER)}`}
                trimInput={true}
              />
              {ENVIRONMENT_VARIABLES.SHOW_PROPERTY_SELECTOR === 'true' && (
                <div style={{ width: '51%', pointerEvents: customPropertyOption ? 'none' : 'auto' }}>
                  <PropertySelector
                    onChangeDOB={handleInputChange('propertyId')}
                    selectedPropertyId={formValues.propertyId}
                    styles={styles.propertyIdContainer}
                    customOptions={customPropertyOption}
                    hidePlaceHolder={customPropertyOption ? true : false}
                  />
                </div>
              )}
            </div>
            <div style={styles.inputRow}>
              <AutoFormInput
                emitInput={handleInputChange('pin')}
                value={formValues.pin}
                label=" "
                containerStyle={styles.inputContainer}
                placeholder={getText(TEXT_KEY.PIN)}
                type="text"
                customClassName="transparentInput"
                customLabelStyle={styles.customInputLabel}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default MillelacsFormInputs;

