export var MISSIONS_GAMEPLAY_STATUS = {
  PENDING: 'PENDING',
  TUTORIAL: 'TUTORIAL',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  PAUSED: 'PAUSED',
  CANCELED: 'CANCELED'
};
export var MISSIONS_TYPES = {
  //TODO: we must to delete master property in a future
  GRAND: 'GRAND',
  MASTER: 'MASTER',
  EXPLORER: 'EXPLORER',
  EXPERT: 'EXPERT',
  FLASH: 'FLASH',
  BATTLES: 'BATTLES'
};
export var MISSIONS_STATES = {
  LOCKED: 'LOCKED',
  READY: 'READY',
  RUNNING: 'RUNNING',
  REWARD: 'REWARD',
  COMPLETED: 'COMPLETED',
  FINISHED: 'FINISHED'
};
export var MISSIONS_COUNTER = {
  SPIN_X_TIMES: 'SPIN_X_TIMES',
  SPIN_X_TIMES_Y_GAMES: 'SPIN_X_TIMES_Y_GAMES',
  WIN_X_TIMES: 'WIN_X_TIMES',
  WIN_X_TIMES_Y_GAMES: 'WIN_X_TIMES_Y_GAMES',
  WIN_X_AMOUNT: 'WIN_X_AMOUNT',
  WIN_X_AMOUNT_Y_GAMES: 'WIN_X_AMOUNT_Y_GAMES',
  WAGE_X_AMOUNT: 'WAGE_X_AMOUNT',
  COLLECT_BONUS_X_TIMES: 'COLLECT_BONUS_X_TIMES',
  COMPLETE_MISSION_X_TIMES: 'COMPLETE_MISSION_X_TIMES'
};
export var MISSIONS_DESCRIPTION = {
  LOCKED: 'You can only start one mission at a time.\n Finish your current mission to access more!'
};
export var MISSIONS_REWARD_BUNDLE_TYPE = {
  COINS: 'COINS',
  MYSTERY_GIFT: 'MYSTERY_GIFT',
  LOYALTY_POINTS: 'LOYALTY_POINTS',
  TIMED_BONUS_MULTIPLIER: 'TIMED_BONUS_MULTIPLIER',
  XP_MULTIPLIER: 'XP_MULTIPLIER',
  COINS_AND_XP_POINTS: 'COINS_+_XP_POINTS'
};
export var MISSIONS_REWARD_TYPE = {
  COINS: 'COINS',
  LOYALTY_POINTS: 'LOYALTY_POINTS',
  MYSTERY_GIFT: 'MYSTERY_GIFT',
  TIMED_BONUS_MULTIPLIER: 'TIMED_BONUS_MULTIPLIER',
  XP_MULTIPLIER: 'XP_MULTIPLIER',
  XP_POINTS: 'XP_POINTS'
};