import styled from 'styled-components';
import { ShadowLayer, Tooltip } from '..';

export const CHECK_GOAL = {
  TOOLTIP: styled(Tooltip)`
    position: absolute;
    z-index: 6;
    width: 50vh;
    top: 68vh;
    left: 84vh;
    @media only screen and (min-height: 900px) {
      top: 65vh;
    }
  `,
  TOP: styled(ShadowLayer)`
    width: 39.6vh;
    top: 0px;
    height: 83.6vh;
    bottom: 0px;
    right: 0px;
    left: 94.5vh;
    border-bottom-left-radius: 2.1vh;
    border-bottom-right-radius: 2.1vh;

    @media only screen and (min-height: 800px) {
      height: 81.6vh;
    }

    @media only screen and (min-height: 930px) {
      height: 80.6vh;
    }

    @media only screen and (min-height: 1130px) {
      height: 79.6vh;
    }
  `,
  BOTTOM: styled(ShadowLayer)`
    height: 20vh;
    width: 39.6vh;
    top: 77px;
    margin-top: 82vh;
    bottom: 0px;
    right: 0px;
    left: 94.5vh;
    border-bottom-color: #fff;
    border-bottom-width: 4px;
  `,
  RIGHT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 100vw;
    left: 134.1vh;
  `,
  LEFT: styled(ShadowLayer)`
    top: 0px;
    bottom: 0px;
    width: 94.5vh;
    left: 0px;
  `,
};

