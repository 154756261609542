import React, { Component } from 'react';
import SComingSoon from 'shared/screens/ComingSoon';

export default class ComingSoon extends Component {
  state = {};

  render() {
    return (
      <SComingSoon
        render={args => {
          const { title } = args;

          return <h1 style={{ textAlign: 'center' }}>{title}</h1>;
        }}
      />
    );
  }
}
