import React from 'react';
import useDailySpinOnboarding from 'shared/screens/DailySpin/hooks/useDailySpinOnboarding';
import { useSelector } from 'shared/node_modules/react-redux';
import { OnboardingContainer, OnboardingImage } from './components/styledComponents';
export default function DailySpinOnboarding() {
  const dailyWheelAssets = useSelector(state => state.assetsPackage.assets.DAILY_WHEEL);
  const { clickHandler } = useDailySpinOnboarding();
  return (
    <OnboardingContainer onClick={clickHandler}>
      <OnboardingImage src={dailyWheelAssets.DAILY_SPIN_INFO_POPUP} alt="Daily Spin Onboarding" />
    </OnboardingContainer>
  );
}

DailySpinOnboarding.hideDefaultBackground = true;
DailySpinOnboarding.AsModalProps = {
  dialogClassName: 'gtp-modal-daily-spin-onboarding',
};
