import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const OTPWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const OTPTextWrapper = styled.p`
  padding-top: 0px;
  text-align: center;
  color: ${theme.palette.primary[27]};
  margin: 0px;
  font-size: 13px;
`;

export const TermsText = styled.p`
  padding-top: 2px;
  text-align: center;
  color: ${theme.palette.primary[27]};
  margin: 0px;
`;

export const Styles = {
  otpConsentsCheckbox: {
    width: 21,
    height: 20,
    marginRight: 5,
  },
};

