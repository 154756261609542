const findRewardContentStyleSga = (rewardValue, hasMysteryRewardTypeNoRevealed, RewardCoinImage) => {
  let iconSizeMultiplier = 0.7;
  const dynamicStyle = {
    fontSize: '2.5vh',
    marginBottom: '4vh',
    lineHeight: '2.5vh',
    winFontSize: hasMysteryRewardTypeNoRevealed ? '3vh' : '3.4vh',
    winMarginBottom: '7%',
  };
  const textLength = rewardValue?.length || 0;

  if (textLength < 29 && !RewardCoinImage) {
    dynamicStyle.lineHeight = '4vh';
  }

  if (textLength < 25) {
    iconSizeMultiplier = 0.5;
    dynamicStyle.marginBottom = '4.5vh';
  }

  if (textLength < 23) {
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed ? '3.8vh' : '4.5vh';
    dynamicStyle.lineHeight = '3vh';
    dynamicStyle.winMarginBottom = '6%';
  }

  if (textLength < 20) {
    dynamicStyle.fontSize = '3vh';
    dynamicStyle.lineHeight = '4vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed ? '3.5vh' : '4.2vh';
    iconSizeMultiplier = 0.7;
    dynamicStyle.winMarginBottom = '5%';
  }

  if (textLength < 13) {
    dynamicStyle.fontSize = '4.5vh';
    dynamicStyle.marginBottom = '2.5vh';
    dynamicStyle.lineHeight = '6vh';
    dynamicStyle.winFontSize = hasMysteryRewardTypeNoRevealed ? '3.8vh' : '4.5vh';
    iconSizeMultiplier = 1;
  }

  if (textLength === 0) {
    dynamicStyle.winMarginBottom = '6%';
  }

  return {
    dynamicStyle,
    iconSizeMultiplier,
  };
};

export default findRewardContentStyleSga;

