export default {
  type: 'object',
  properties: {
    privacyPolicy: {
      description: 'Privacy Policy for current client',
      type: 'string'
    },
    termsAndConditions: {
      description: 'Terms and Conditions for current client',
      type: 'string'
    }
  },
  required: ['privacyPolicy', 'termsAndConditions']
};