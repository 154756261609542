const styles = {
  divider: {
    width: '100%',
    height: '0.2rem',
    zIndex: 500,
  },
  editButtonImage: {
    objectFit: 'contain',
  },
};

export default styles;

