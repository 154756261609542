export default {
  type: 'object',
  properties: {
    assetsPackage: {
      type: 'object',
      properties: {
        assetsPackageId: {
          type: 'string'
        },
        assetsPackageCategory: {
          type: 'string'
        },
        baseUrl: {
          type: 'string'
        },
        items: {
          type: 'object',
          properties: {
            'coin-icon.png': {
              type: 'number'
            },
            'coin.png': {
              type: 'number'
            }
          }
        }
      }
    }
  }
};