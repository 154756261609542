export default {
  NEWS: 'news',
  // untested
  SALES: 'sales',
  // untested
  EVENTS: 'events',
  // untested
  GAMES: 'game',
  // untested
  FEATURED_GAMES: 'featured-game',
  NEW_GAMES: 'new-game' // untested
};