import React, { useContext } from 'react';
import { ButtonText, ButtonWrapper, ButtonsContainer, FacebookLinkButton, MemberLinkButton } from '../styledComponent';
import Button from '../../../components/Button/Button';
import LinkAccountButtons from '../../Settings/Account/components/LinkAccount/DefaultLinkAccount';
import ThemeContext from 'shared/context/ThemeContext';

const DynamicPopupsButtons = ({ showLinkAccountButtons, buttonsRendering, modalType, onDisplayAction }) => {
  const { DynamicPopupsButtons } = useContext(ThemeContext);

  return (
    <React.Fragment>
      {showLinkAccountButtons ? (
        <LinkAccountButtons
          rowWrapperStyles={DynamicPopupsButtons.RowWrapperLink}
          facebookLinkButtonStyles={FacebookLinkButton}
          memberLinkButtonStyles={MemberLinkButton}
          isActionCommand={true}
          modalType={modalType}
          onDisplayAction={onDisplayAction}
        />
      ) : (
        <RenderingButtons buttonsRendering={buttonsRendering} />
      )}
    </React.Fragment>
  );
};

const RenderingButtons = ({ buttonsRendering }) => {
  const { DynamicPopupsButtons } = useContext(ThemeContext);

  return (
    <ButtonsContainer theme={DynamicPopupsButtons.ButtonsContainer}>
      {buttonsRendering.map(({ label, action, imageSource }, index) => (
        <ButtonWrapper key={index} imageSource={imageSource}>
          <Button imageSource={imageSource} label={label} onClick={action} textStyle={ButtonText} />
        </ButtonWrapper>
      ))}
    </ButtonsContainer>
  );
};

export default DynamicPopupsButtons;

