import * as yup from 'shared/node_modules/yup';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export const paymentFormKeys = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
  ADDRESS_LINE_1: 'address1',
  ADDRESS_LINE_2: 'address2',
  COUNTRY: 'country',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
};

export const validationSchema = kycStatus =>
  yup.object({
    [paymentFormKeys.FIRST_NAME]: kycStatus ? yup.string() : yup.string().required('Firstname is required'),
    [paymentFormKeys.LAST_NAME]: kycStatus ? yup.string() : yup.string().required('Lastname is required'),
    [paymentFormKeys.YEAR]: kycStatus
      ? yup.number()
      : yup
          .string()
          .matches(/^[0-9]{4}$/, 'Must be exactly 4 digits')
          .required('Year of birth is required'),
    [paymentFormKeys.MONTH]: kycStatus ? yup.string() : yup.string().required('Month is required'),
    [paymentFormKeys.DAY]: kycStatus ? yup.string() : yup.string().required('Day is required'),
    [paymentFormKeys.ADDRESS_LINE_1]: yup.string().required('Address - Line 1 is required'),
    [paymentFormKeys.ADDRESS_LINE_2]: yup.string(),
    [paymentFormKeys.COUNTRY]: yup.string().required('Country is required'),
    [paymentFormKeys.CITY]: yup.string().required('City is required'),
    [paymentFormKeys.STATE]: yup.string().required('State/Province is required'),
    [paymentFormKeys.ZIP]: yup.string().required('Zip Code is required'),
  });

export const paymentFormPlaceHolders = {
  [paymentFormKeys.FIRST_NAME]: getText(TEXT_KEY.PAYMENT_INFO_FIRST_NAME),
  [paymentFormKeys.LAST_NAME]: getText(TEXT_KEY.PAYMENT_INFO_LAST_NAME),
  [paymentFormKeys.YEAR]: getText(TEXT_KEY.PAYMENT_INFO_YEAR),
  [paymentFormKeys.MONTH]: getText(TEXT_KEY.PAYMENT_INFO_MONTH),
  [paymentFormKeys.DAY]: getText(TEXT_KEY.PAYMENT_INFO_DAY),
  [paymentFormKeys.ADDRESS_LINE_1]: getText(TEXT_KEY.PAYMENT_INFO_ADDRESS_LINE_1),
  [paymentFormKeys.ADDRESS_LINE_2]: getText(TEXT_KEY.PAYMENT_INFO_ADDRESS_LINE_2),
  [paymentFormKeys.COUNTRY]: getText(TEXT_KEY.PAYMENT_INFO_COUNTRY),
  [paymentFormKeys.CITY]: getText(TEXT_KEY.PAYMENT_INFO_CITY),
  [paymentFormKeys.STATE]: getText(TEXT_KEY.PAYMENT_INFO_STATE),
  [paymentFormKeys.ZIP]: getText(TEXT_KEY.PAYMENT_INFO_ZIP),
};

export const savedAddressConverter = AddressInfo => ({
  [paymentFormKeys.ADDRESS_LINE_1]: AddressInfo.address1,
  [paymentFormKeys.ADDRESS_LINE_2]: AddressInfo.address2,
  [paymentFormKeys.COUNTRY]: AddressInfo.countryCode,
  [paymentFormKeys.CITY]: AddressInfo.city,
  [paymentFormKeys.STATE]: AddressInfo.state,
  [paymentFormKeys.ZIP]: AddressInfo.postCode,
});

