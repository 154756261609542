import { DiveSDK, EventBody } from '@divegames/dive-ts-web-sdk';
import { EVENT_TYPES } from '../constants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

export default {
  handleEvent: (eventType, payload = {}) => {
    switch (eventType) {
      case EVENT_TYPES.LOGIN_COMPLETE:
        const userID = payload.userID;
        const userXP = payload.diveItems.userXP;
        const userLevel = payload.diveItems.userLevel;
        const loginMethod = payload.diveItems.loginMethod;
        const sessionId = payload.diveItems.sessionId;
        const version = `${ENVIRONMENT_VARIABLES.VERSION_MAJOR}.${ENVIRONMENT_VARIABLES.VERSION_MINOR}.${ENVIRONMENT_VARIABLES.VERSION_PATCH}-build.${ENVIRONMENT_VARIABLES.VERSION_BUILD}-${ENVIRONMENT_VARIABLES.ENVIRONMENT}`;
        //Set player settings
        DiveSDK.getInstance()
          .SetPlayerId(userID)
          .SetServerSessionId(sessionId)
          .SetPlayerLevel(userLevel)
          .SetPlayerXp(userXP)
          .SetGameVersion(version);
        //Launch the Dive SDK
        DiveSDK.getInstance().Launch(
          new EventBody().AddParam('login_type', loginMethod).AddParam('query_string_params', payload.queryParams)
        );
        break;
      case EVENT_TYPES.TRANSACTION_INITIATED:
        DiveSDK.getInstance().RecordEvent(
          'transactionInitiated',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', payload.is_test)
            .AddParam('id', payload.id)
            .AddParam('balance_before', payload.balance_before)
            .AddParam('game_provider', payload.game_provider)
            .AddParam('game_id', payload.game_id)
        );
        break;
      case EVENT_TYPES.TRANSACTION_FAILED:
        DiveSDK.getInstance().RecordEvent(
          'transactionFailed',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', ENVIRONMENT_VARIABLES.ENVIRONMENT !== 'PROD' ? 1 : 0)
            .AddParam('id', payload.id)
            .AddParam('store', payload.store)
            .AddParam('store_tx_id', payload.store_tx_id)
            .AddParam('tx_error', payload.tx_error)
            .AddParam('product_name', payload.product_name)
            .AddParam('game_id', payload.game_id)
            .AddParam('game_provider', payload.game_provider)
        );
        break;
      case EVENT_TYPES.FRONTEND_ERROR:
        DiveSDK.getInstance().RecordEvent(
          'frontendError',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('headers', payload.headers)
            .AddParam('body', payload.body)
            .AddParam('error', payload.error)
            .AddParam('code', payload.code)
            .AddParam('status', payload.status)
            .AddParam('url', payload.url)
            .AddParam('currentScreen', payload.currentScreen)
            .AddParam('openedModal', payload.openedModal)
        );
        break;
      case EVENT_TYPES.PURCHASE_COMPLETE:
        DiveSDK.getInstance().RecordEvent(
          'transactionCompleted',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', ENVIRONMENT_VARIABLES.ENVIRONMENT !== 'PROD' ? 1 : 0)
            .AddParam('id', payload.id)
            .AddParam('store', payload.store)
            .AddParam('store_tx_id', payload.store_tx_id)
            .AddParam('promo_code', payload.promo_code)
            .AddParam('product_name', payload.product_name)
            .AddParam('loyalty_points', payload.loyalty_points)
            .AddParam('loyalty_points_before', payload.loyalty_points_before)
            .AddParam('level_after', payload.level_after)
            .AddParam('coins', payload.coins)
            .AddParam('promo_coins', payload.promo_coins)
            .AddParam('game_id', payload.game_id)
            .AddParam('game_provider', payload.game_provider)
            .AddParam('balance_before', payload.balance_before)
        );
        break;
      case EVENT_TYPES.REQUEST_SENT:
        DiveSDK.getInstance().RecordEvent(
          'requestSent',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('headers', payload.headers)
            .AddParam('body', payload.body)
            .AddParam('uri', payload.uri)
        );
        break;
      case EVENT_TYPES.RESPONSE_RECEIVED:
        DiveSDK.getInstance().RecordEvent(
          'responseReceived',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('status', payload.status)
            .AddParam('body', payload.body)
        );
        break;
      case EVENT_TYPES.TUTORIAL_SKIPPED:
        DiveSDK.getInstance().RecordEvent(
          'tutorialSkipeed',
          new EventBody().AddParam('id', payload.id).AddParam('skipped', payload.skipped)
        );
        break;
      case EVENT_TYPES.PROMOLINK_CLICKED:
        DiveSDK.getInstance().RecordEvent('promolinkClicked', new EventBody().AddParam('promo_code', payload.code));
        break;
      case EVENT_TYPES.UI_EVENT:
        DiveSDK.getInstance().RecordEvent('uiEvent', new EventBody(payload));
        break;
      default:
        break;
    }
  },
};

