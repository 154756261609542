export default {
  type: 'object',
  properties: {
    friendsInviteFeatureState: {
      type: 'object',
      properties: {
        inviteLink: {
          type: 'object',
          properties: {
            createdTimestamp: {
              type: 'integer'
            },
            expiresTimestamp: {
              type: 'integer'
            },
            friendsInviteLinkId: {
              type: 'string'
            },
            inviteLinkUrl: {
              type: 'string'
            },
            maxRedeems: {
              type: 'integer'
            },
            playerId: {
              type: 'integer'
            },
            redeemsCount: {
              type: 'integer'
            },
            updatedTimestamp: {
              type: 'integer'
            }
          }
        },
        inviteSetup: {
          type: 'object',
          properties: {
            assetsPackageId: {
              type: 'string'
            },
            endTimestamp: {
              type: 'integer'
            },
            friendsInviteGameplayStatus: {
              type: 'string'
            },
            friendsInviteSetupId: {
              type: 'integer'
            },
            name: {
              type: 'string'
            },
            startTimestamp: {
              type: 'integer'
            }
          },
          required: ['friendsInviteGameplayStatus', 'friendsInviteSetupId']
        }
      },
      required: ['inviteLink', 'inviteSetup']
    }
  },
  required: ['friendsInviteFeatureState']
};