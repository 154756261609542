import { connect } from 'shared/node_modules/react-redux';
import GamePage from './GamePage';
import { clearOnboarding } from 'shared/state/actions/currentOnboarding';
import { markOnboardingSeen } from 'shared/state/actions/ftue';

const mapStateToProps = ({ currentOnboarding: { currentStep, ftueStep } }) => {
  return {
    onboardingStep: currentStep,
    ftueStep,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearOnboarding: () => {
      dispatch(clearOnboarding());
    },
    markOnboardingSeen: () => {
      dispatch(markOnboardingSeen());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GamePage);

