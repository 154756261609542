import { PRELOAD_MUSIC, CURRENTLY_PLAYING_MUSIC, CURRENTLY_PLAYING_SFX } from './types';
export var preloadMusic = function preloadMusic(musicPlayers) {
  return {
    type: PRELOAD_MUSIC,
    payload: musicPlayers
  };
};
export var currentlyPlayingMusic = function currentlyPlayingMusic(value) {
  return {
    type: CURRENTLY_PLAYING_MUSIC,
    payload: value
  };
};
export var currentlyPlayingSfx = function currentlyPlayingSfx(value) {
  return {
    type: CURRENTLY_PLAYING_SFX,
    payload: value
  };
};