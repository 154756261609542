import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'shared/node_modules/react-redux';
import { Spinner } from 'react-bootstrap';

const Loading = ({ loading }) => {
  const { loadingLayer } = useSelector(state => state.layout);
  return (
    <Container activeLoading={loadingLayer || loading}>
      <Spinner animation="border" />
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  position: absolute;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ activeLoading }) => (activeLoading ? 'flex' : 'none')};
  pointer-events: ${({ activeLoading }) => (activeLoading ? 'auto' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

