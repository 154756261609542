import styled from 'styled-components';
import Button from '../../../../../../components/Button/Button';
import theme from 'shared/assets/style/theme';

const HistoryDetailContainer = styled.div`
  width: 100%;
  height: 98%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BarCodeContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const RemainingBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  left 5rem;
`;

const DividerImage = styled.img`
  position: absolute;
  top: ${({ hasSubtitle }) => (hasSubtitle ? '72px' : '45px')};
`;

const EmailSentMessage = styled.div`
  color: ${({ isError }) => (isError ? theme.palette.common[41] : theme.palette.common[1])};
`;

const SendEmailButton = styled(Button).attrs(() => ({
  containerStyle: {
    alignSelf: 'left',
    flex: 1,
    marginTop: '1.8em',
    marginBottom: '1.5em',
  },
  textStyle: {
    textTransform: 'uppercase',
    paddingBottom: 0,
    fontSize: 'calc(14px + (22 - 14) * ((100vw - 800px) / (1600 - 800)))',
  },
}))``;

export {
  HistoryDetailContainer,
  ContentContainer,
  BarCodeContainer,
  RemainingBalanceContainer,
  DividerImage,
  EmailSentMessage,
  SendEmailButton,
};

