import { SET_SHOP_LIST_COINS, SET_SHOP_LIST_VIP, SET_SALE_AVAILABLE, SET_USED_PROMO_CODE, SET_PAYMENT_PROVIDER } from './types';
export var setShopListCoins = function setShopListCoins(coins) {
  return {
    type: SET_SHOP_LIST_COINS,
    payload: coins
  };
};
export var setShopListVip = function setShopListVip(vip) {
  return {
    type: SET_SHOP_LIST_VIP,
    payload: vip
  };
};
export var setSaleAvailable = function setSaleAvailable(sale) {
  return {
    type: SET_SALE_AVAILABLE,
    payload: sale
  };
};
export var setLastPromoCode = function setLastPromoCode(code) {
  return {
    type: SET_USED_PROMO_CODE,
    payload: code
  };
};
export var setPaymentProvider = function setPaymentProvider(provider) {
  return {
    type: SET_PAYMENT_PROVIDER,
    payload: provider
  };
};