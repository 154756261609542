import { SET_NAV_STATE, HIDE_TIMER_ON_FINISH, HIDE_ACTIVE_TRACKER_ON_FINISH } from './types';
export var setNavState = function setNavState(newState) {
  return {
    type: SET_NAV_STATE,
    payload: newState
  };
};
export var hideTimerOnFinish = function hideTimerOnFinish(value) {
  return {
    type: HIDE_TIMER_ON_FINISH,
    payload: value
  };
};
export var hideActiveTrackerOnFinish = function hideActiveTrackerOnFinish(value) {
  return {
    type: HIDE_ACTIVE_TRACKER_ON_FINISH,
    payload: value
  };
};