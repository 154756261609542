import styled from 'styled-components';

export const NotificationsPermissionWrapper = styled.div`
  display: block;
  height: 100%;
`;

export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => theme}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: ${({ imageSource }) => (imageSource ? 'flex' : 'none')};
`;

export const ButtonText = {
  fontWeight: '900',
};

export const FacebookLinkButton = {
  width: 200,
  height: 35,
};

export const MemberLinkButton = {
  width: 200,
  height: 37,
  margin: 0,
};

