import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { HeaderContainer, HeaderWrapper, HeaderHeight } from './styleComponents';

const MillelacsLevelUpText = () => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <div style={{ marginBottom: -5 }} className="levelUpTitle">
            {getText(TEXT_KEY.LEVEL_UP_TEXT)}
          </div>
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderHeight />
    </>
  );
};

export default MillelacsLevelUpText;

