export var ZendeskCategories = [{
  label: 'Account',
  value: 'account'
}, {
  label: 'Complaint',
  value: 'complaint'
}, {
  label: 'Feedback',
  value: 'feedback'
}, {
  label: 'Other',
  value: 'other'
}, {
  label: 'Payment',
  value: 'payment'
}, {
  label: 'Question',
  value: 'question'
}, {
  label: 'Social',
  value: 'social'
}, {
  label: 'Vouchers',
  value: 'vouchers'
}];
export var ZendeskSubCategories = {
  account: [{
    id: 360017613954,
    label: 'Account - Account Upgrade',
    value: 'starplay_account__account_upgrade'
  }, {
    id: 360007635593,
    label: 'Account - Closure',
    value: 'starplay_account__closure'
  }, {
    id: 360017613974,
    label: 'Account - Facebook Sign Up',
    value: 'starplay_account__facebook_sign_up'
  }, {
    id: 360007635633,
    label: 'Account - Forgot Member ID',
    value: 'starplay_account__forgot_member_id'
  }, {
    id: 360017613994,
    label: 'Account - Guest Sign Up',
    value: 'starplay_account__guest_sign_up'
  }, {
    id: 360007635653,
    label: 'Account - Locked Out',
    value: 'starplay_account__locked_out'
  }, {
    id: 360007635673,
    label: 'Account - Login',
    value: 'starplay_account__login'
  }, {
    id: 360007635793,
    label: 'Account - Other',
    value: 'starplay_account__other'
  }, {
    id: 360007635693,
    label: 'Account - Password Reset',
    value: 'starplay_account__password_reset'
  }, {
    id: 360017614014,
    label: 'Account - Sign Up',
    value: 'starplay_account__sign_up'
  }, {
    id: 360017614034,
    label: 'Account - TSC Member Sign Up',
    value: 'starplay_account__tsc_member_sign_up'
  }, {
    id: 360007635733,
    label: 'Account - Unsubscribe from Desktop Notifications',
    value: 'starplay_account__unsubscribe_from_desktop_notifications'
  }, {
    id: 360007635713,
    label: 'Account - Unsubscribe from Emails',
    value: 'starplay_account__unsubscribe_from_emails'
  }, {
    id: 360007635753,
    label: 'Account - Unsubscribe from Push Notifications',
    value: 'starplay_account__unsubscribe_from_push_notifications'
  }, {
    id: 360007635773,
    label: 'Account - Update Email Address',
    value: 'starplay_account__update_email_address'
  }, {
    id: 360017614054,
    label: 'Account - Verify Your Account',
    value: 'starplay_account__verify_your_account'
  }],
  complaint: [{
    id: 360007635813,
    label: 'Complaint - Account Issues',
    value: 'starplay_complaint__account_issues'
  }, {
    id: 360007635833,
    label: 'Complaint - Android App',
    value: 'starplay_complaint__android_app'
  }, {
    id: 360007635853,
    label: 'Complaint - Currency Balance',
    value: 'starplay_complaint__currency_balance'
  }, {
    id: 360007635873,
    label: 'Complaint - Daily Bonus',
    value: 'starplay_complaint__daily_bonus'
  }, {
    id: 360007635893,
    label: 'Complaint - Get Coins',
    value: 'starplay_complaint__get_coins'
  }, {
    id: 360007635913,
    label: 'Complaint - Gifts',
    value: 'starplay_complaint__gifts'
  }, {
    id: 360007635933,
    label: 'Complaint - Help Centre',
    value: 'starplay_complaint__help_centre'
  }, {
    id: 360007635953,
    label: 'Complaint - High Limit Room',
    value: 'starplay_complaint__high_limit_room'
  }, {
    id: 360007635973,
    label: 'Complaint - Invite Friends',
    value: 'starplay_complaint__invite_friends'
  }, {
    id: 360007635993,
    label: 'Complaint - iOS App',
    value: 'starplay_complaint__ios_app'
  }, {
    id: 360007636013,
    label: 'Complaint - Leaderboards',
    value: 'starplay_complaint__leaderboards'
  }, {
    id: 360007636033,
    label: 'Complaint - Level',
    value: 'starplay_complaint__level'
  }, {
    id: 360007636053,
    label: 'Complaint - Mobile Web ',
    value: 'starplay_complaint__mobile_web_'
  }, {
    id: 360007636073,
    label: 'Complaint - My Rewards ',
    value: 'starplay_complaint__my_rewards_'
  }, {
    id: 360007636353,
    label: 'Complaint - Other',
    value: 'starplay_complaint__other'
  }, {
    id: 360007636093,
    label: 'Complaint - Payment Issues',
    value: 'starplay_complaint__payment_issues'
  }, {
    id: 360007636113,
    label: 'Complaint - Preferences',
    value: 'starplay_complaint__preferences'
  }, {
    id: 360007636133,
    label: 'Complaint - Promo Code',
    value: 'starplay_complaint__promo_code'
  }, {
    id: 360007636153,
    label: 'Complaint - Promotion/Sale',
    value: 'starplay_complaint__promotion/sale'
  }, {
    id: 360007636173,
    label: 'Complaint - Quest',
    value: 'starplay_complaint__quest'
  }, {
    id: 360007636193,
    label: 'Complaint - Slot Game ',
    value: 'starplay_complaint__slot_game_'
  }, {
    id: 360007636213,
    label: 'Complaint - Spin Wheel',
    value: 'starplay_complaint__spin_wheel'
  }, {
    id: 360017614074,
    label: 'Complaint - Stars Expiry',
    value: 'starplay_complaint__stars_expiry'
  }, {
    id: 360007636233,
    label: 'Complaint - Table Games',
    value: 'starplay_complaint__table_games'
  }, {
    id: 360007636253,
    label: 'Complaint - Timed Bonus',
    value: 'starplay_complaint__timed_bonus'
  }, {
    id: 360007636273,
    label: 'Complaint - Tourlabelnt',
    value: 'starplay_complaint__tourlabelnt'
  }, {
    id: 360007636293,
    label: 'Complaint - Voucher Issues',
    value: 'starplay_complaint__voucher_issues'
  }, {
    id: 360007636313,
    label: 'Complaint - Watch & Earn',
    value: 'starplay_complaint__watch___earn'
  }, {
    id: 360007636333,
    label: 'Complaint - Web ',
    value: 'starplay_complaint__web_'
  }],
  feedback: [{
    id: 360007636373,
    label: 'Feedback - Compliment',
    value: 'starplay_feedback__compliment'
  }, {
    id: 360007636433,
    label: 'Feedback - Other',
    value: 'starplay_feedback__other'
  }, {
    id: 360007636393,
    label: 'Feedback - Room For Improvement',
    value: 'starplay_feedback__room_for_improvement'
  }, {
    id: 360007636413,
    label: 'Feedback - Suggestion',
    value: 'starplay_feedback__suggestion'
  }],
  other: [{
    id: 360007636453,
    label: 'Other - Other',
    value: 'starplay_other__other'
  }],
  payment: [{
    id: 360007636493,
    label: 'Payment - Casino Dollars',
    value: 'starplay_payment__casino_dollars'
  }, {
    id: 360017614094,
    label: 'Payment - Account Upgrade',
    value: 'starplay_payment__account_upgrade'
  }, {
    id: 360007636473,
    label: 'Payment - Apple Pay',
    value: 'starplay_payment__apple_pay'
  }, {
    id: 360007636513,
    label: 'Payment - Casino Dollars Balance',
    value: 'starplay_payment__casino_dollars_balance'
  }, {
    id: 360007636533,
    label: 'Payment - Casino Dollars Expiry',
    value: 'starplay_payment__casino_dollars_expiry'
  }, {
    id: 360007636553,
    label: 'Payment - Credit Card',
    value: 'starplay_payment__credit_card'
  }, {
    id: 360007636573,
    label: 'Payment - Currency Balance',
    value: 'starplay_payment__currency_balance'
  }, {
    id: 360007636593,
    label: 'Payment - Google Pay',
    value: 'starplay_payment__google_pay'
  }, {
    id: 360007636653,
    label: 'Payment - Other',
    value: 'starplay_payment__other'
  }, {
    id: 360007636613,
    label: 'Payment - Refund ',
    value: 'starplay_payment__refund_'
  }, {
    id: 360007636633,
    label: 'Payment - Technical Issues',
    value: 'starplay_payment__technical_issues'
  }, {
    id: 360017614114,
    label: 'Payment - Verify Your Account',
    value: 'starplay_payment__verify_your_account'
  }],
  question: [{
    id: 360007636673,
    label: 'Question - Account',
    value: 'starplay_question__account'
  }, {
    id: 360007636693,
    label: 'Question - Android App',
    value: 'starplay_question__android_app'
  }, {
    id: 360007636713,
    label: 'Question - Coin Sale',
    value: 'starplay_question__coin_sale'
  }, {
    id: 360007636733,
    label: 'Question - Currency',
    value: 'starplay_question__currency'
  }, {
    id: 360007636753,
    label: 'Question - Daily Bonus',
    value: 'starplay_question__daily_bonus'
  }, {
    id: 360007636773,
    label: 'Question - Get Coins',
    value: 'starplay_question__get_coins'
  }, {
    id: 360007636793,
    label: 'Question - Gifts',
    value: 'starplay_question__gifts'
  }, {
    id: 360007636813,
    label: 'Question - Help Centre',
    value: 'starplay_question__help_centre'
  }, {
    id: 360007636833,
    label: 'Question - High Limit Room',
    value: 'starplay_question__high_limit_room'
  }, {
    id: 360007636853,
    label: 'Question - Invite',
    value: 'starplay_question__invite'
  }, {
    id: 360007636873,
    label: 'Question - iOS App',
    value: 'starplay_question__ios_app'
  }, {
    id: 360007636893,
    label: 'Question - Leaderboards',
    value: 'starplay_question__leaderboards'
  }, {
    id: 360007636913,
    label: 'Question - Level',
    value: 'starplay_question__level'
  }, {
    id: 360007636933,
    label: 'Question - Mobile Web Browser',
    value: 'starplay_question__mobile_web_browser'
  }, {
    id: 360007637213,
    label: 'Question - Other',
    value: 'starplay_question__other'
  }, {
    id: 360007636953,
    label: 'Question - Payment',
    value: 'starplay_question__payment'
  }, {
    id: 360007636973,
    label: 'Question - Preferences',
    value: 'starplay_question__preferences'
  }, {
    id: 360007636993,
    label: 'Question - Promo Code',
    value: 'starplay_question__promo_code'
  }, {
    id: 360007637013,
    label: 'Question - Quest',
    value: 'starplay_question__quest'
  }, {
    id: 360007637033,
    label: 'Question - Rewards Centre',
    value: 'starplay_question__rewards_centre'
  }, {
    id: 360007637053,
    label: 'Question - Slot Game ',
    value: 'starplay_question__slot_game_'
  }, {
    id: 360007637073,
    label: 'Question - Spin Wheel',
    value: 'starplay_question__spin_wheel'
  }, {
    id: 360017614134,
    label: 'Question - Stars Expiry',
    value: 'starplay_question__stars_expiry'
  }, {
    id: 360007637093,
    label: 'Question - Table Games',
    value: 'starplay_question__table_games'
  }, {
    id: 360007637113,
    label: 'Question - Timed Bonus',
    value: 'starplay_question__timed_bonus'
  }, {
    id: 360007637133,
    label: 'Question - Tourlabelnt',
    value: 'starplay_question__tourlabelnt'
  }, {
    id: 360007637153,
    label: 'Question - Voucher',
    value: 'starplay_question__voucher'
  }, {
    id: 360007637173,
    label: 'Question - Watch & Earn',
    value: 'starplay_question__watch___earn'
  }, {
    id: 360007637193,
    label: 'Question - Web Browser',
    value: 'starplay_question__web_browser'
  }],
  social: [{
    id: 360017614154,
    label: 'Social - Facebook Connect',
    value: 'starplay_social__facebook_connect'
  }, {
    id: 360017614174,
    label: 'Social - Friend Invite',
    value: 'starplay_social__friend_invite'
  }, {
    id: 360017614194,
    label: 'Social - Other',
    value: 'starplay_social__other'
  }],
  vouchers: [{
    id: 360017614214,
    label: 'Vouchers - Account Upgrade',
    value: 'starplay_vouchers__account_upgrade'
  }, {
    id: 360007637233,
    label: 'Vouchers - Exchange',
    value: 'starplay_vouchers__exchange'
  }, {
    id: 360007637253,
    label: 'Vouchers - Expired',
    value: 'starplay_vouchers__expired'
  }, {
    id: 360007637353,
    label: 'Vouchers - Other',
    value: 'starplay_vouchers__other'
  }, {
    id: 360007637273,
    label: 'Vouchers - Refund',
    value: 'starplay_vouchers__refund'
  }, {
    id: 360007637293,
    label: 'Vouchers - Technical Issues',
    value: 'starplay_vouchers__technical_issues'
  }, {
    id: 360017614234,
    label: 'Vouchers - Verify Your Account',
    value: 'starplay_vouchers__verify_your_account'
  }, {
    id: 360007637313,
    label: 'Vouchers - Wrong Outlet',
    value: 'starplay_vouchers__wrong_outlet'
  }, {
    id: 360007637333,
    label: 'Vouchers - Wrong Property',
    value: 'starplay_vouchers__wrong_property'
  }]
};