import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'TERMS_AND_CONDITIONS';
var initialState = {
  isFetchingData: false,
  data: [],
  errorMsg: ''
};
var termsAndConditionsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    termsAndConditionsRequestData: function termsAndConditionsRequestData(state, action) {},
    termsAndConditionsIsFetching: function termsAndConditionsIsFetching(state, action) {
      state.isFetchingData = action.payload;
    },
    termsAndConditionsRequestError: function termsAndConditionsRequestError(state, action) {
      state.isFetchingData = false;
      state.errorMsg = action.payload;
    },
    termsAndConditionsRequestDataSuccess: function termsAndConditionsRequestDataSuccess(state, action) {
      state.isFetchingData = false;
      state.data = action.payload;
    }
  }
});
var actions = termsAndConditionsSlice.actions,
  reducer = termsAndConditionsSlice.reducer;
var termsAndConditionsRequestData = actions.termsAndConditionsRequestData,
  termsAndConditionsIsFetching = actions.termsAndConditionsIsFetching,
  termsAndConditionsRequestError = actions.termsAndConditionsRequestError,
  termsAndConditionsRequestDataSuccess = actions.termsAndConditionsRequestDataSuccess;
export { termsAndConditionsRequestData, termsAndConditionsIsFetching, termsAndConditionsRequestError, termsAndConditionsRequestDataSuccess };
export default reducer;