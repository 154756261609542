import Logger from './logger';
import { FTUE_EVENT_TYPES, FTUE_STEPS_KEYS } from '../../constants';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_TYPES, EVENT_WIDGETS } from './constants';
export var sendFtueEvent = function sendFtueEvent(eventType, payload) {
  switch (eventType) {
    case FTUE_EVENT_TYPES.START_GAME_TUTORIAL:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.GAME_TUTORIAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY
      });
      break;
    case FTUE_EVENT_TYPES.GAME_TUTORIAL_NEXT_STEP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.GAME_TUTORIAL,
        screen: payload.step >= FTUE_STEPS_KEYS.SHOW_IN_GAME ? EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN : EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].TUTORIAL_NEXT_STEP,
        context_tutorial_step: payload.step
      });
      break;
    case FTUE_EVENT_TYPES.DISPLAY_GAME_TUTORIAL_STEP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.GAME_TUTORIAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        context_tutorial_step: payload.step
      });
      break;
    case FTUE_EVENT_TYPES.GAME_TUTORIAL_SKIP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.GAME_TUTORIAL,
        screen: payload.step >= FTUE_STEPS_KEYS.SHOW_IN_GAME ? EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN : EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].TUTORIAL_SKIP,
        context_tutorial_step: payload.step,
        isFtueFlow: payload.isFtueFlow
      });
      break;
    case FTUE_EVENT_TYPES.GAME_TUTORIAL_COMPLETE:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.GAME_TUTORIAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        context_tutorial_step: payload.step,
        isFtueFlow: payload.isFtueFlow
      });
      break;
    default:
      break;
  }
};