export var MAX_INT = 2147483647;
export var getDecimalAmount = function getDecimalAmount(number) {
  var finalAmount = (number / 100).toFixed(2);
  return "$ ".concat(finalAmount);
};
export var numberWithDots = function numberWithDots(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export var numberWithCommas = function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export var numberReduce = function numberReduce(num) {
  var bN = 0;
  var sf = ['', 'K', 'M', 'KM', 'B', 'T', 'C', 'Q', 'S'];
  while (num > 999) {
    num /= 1000;
    bN++;
  }
  return num.toFixed(2) + sf[bN];
};
export var timeFormatter = function timeFormatter(seconds) {
  var hour = Math.floor(seconds / 3600);
  var minute = Math.floor(seconds / 60 % 60);
  return hour > 0 ? "".concat(hour, "h") : "".concat(minute, "m");
};
export var toFixedWithoutRounding = function toFixedWithoutRounding(number) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var factor = Math.pow(10, precision);
  return Math.floor(number * factor) / factor;
};