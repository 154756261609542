import React from 'react';
import useDynamicPopups from 'shared/screens/DynamicPopups/hooks/useDynamicPopups';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import DynamicPopupsButtons from './components/DynamicPopupsButtons';
import { ModalImage, NotificationsPermissionWrapper } from './styledComponent';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TYPE } from 'shared/api/ActionCommandsInterface/constants';
import Emitter from 'shared/services/EmitterService';
import { EVENT_EMITTER_TYPE } from 'shared/constants';

const DynamicPopups = () => {
  const { modalImageSource, buttonsRendering, showLinkAccountButtons, modalType, onDisplayAction } = useDynamicPopups();

  DynamicPopups.DismissHandler = () => {
    QueryParamProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
    });
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.SUBMIT_EVENT_REQUEST,
      data: { event: ACTION_COMMANDS_EVENT_TYPE.DISMISS_APP_MODAL, modalType, onDisplayAction },
    });
  };

  return (
    <>
      {modalImageSource && (
        <NotificationsPermissionWrapper>
          <ModalImage src={modalImageSource} resizeMode="contain" />
          <DynamicPopupsButtons
            showLinkAccountButtons={showLinkAccountButtons}
            buttonsRendering={buttonsRendering}
            modalType={modalType}
            onDisplayAction={onDisplayAction}
          />
        </NotificationsPermissionWrapper>
      )}
    </>
  );
};

DynamicPopups.hideDefaultBackground = true;

export default DynamicPopups;

