import { store } from '../state/store';
var getFeatures = function getFeatures() {
  return store.getState().featureFlags.enabledFeatures;
};
var useFeatureFlags = function useFeatureFlags() {
  var hasFeature = function hasFeature(feature) {
    return getFeatures()[feature] && getFeatures()[feature].enabled;
  };
  var getFeatureConfig = function getFeatureConfig(feature) {
    return getFeatures()[feature];
  };
  return {
    hasFeature: hasFeature,
    getFeatureConfig: getFeatureConfig
  };
};
export default useFeatureFlags;