/**
 * Converts an API response with numeric keys into meaningful ones.
 * @template Keys
 * @param  {Object} objectResponse The response to transform.
 * @param  {Keys} objectKeys The key mapping to use. Typically from Constants.js
 * @returns {Keys} A new object that has had it's keys remapped for meaningful ones.
 */
export default function transformObjectResponseKeys(objectResponse, objectKeys) {
  var newObj = {};
  for (var key in objectKeys) {
    if (!objectKeys.hasOwnProperty(key)) {
      continue;
    }

    // Get the associated position for the key,
    // Get the value for that position
    var value = objectResponse[objectKeys[key]];

    // Check if we got a non-null/non-undefined value
    if (value || typeof value === 'number') {
      // Set it on our new object
      newObj[key] = value;
    } else {
      // Set it on the object as null instead of being missing
      newObj[key] = null;
    }
  }
  return newObj;
}