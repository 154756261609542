import React from 'react';
import styled from 'styled-components';
import Modal from '../../../components/JSModal';

function InfoPopUp({ showInfoModal, toggleInfoModal, dailyWheelAssets }) {
  return (
    <Modal style={modalStyle} isVisible={showInfoModal} hideDefaultBackground hideCloseButton onClick={toggleInfoModal}>
      <ModalContainer>
        <InfoModalBackgroundImage src={dailyWheelAssets.DAILY_SPIN_COLUMN_INFO_POPUP} />
      </ModalContainer>
    </Modal>
  );
}

export default InfoPopUp;

const modalStyle = {
  padding: '15vh',
};

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InfoModalBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 900px;
  object-fit: contain;
  z-index: 0;
  margin-top: -10vh;
`;

