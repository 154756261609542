import SPushNotification from 'shared/lib/notifications/PushNotification';

const checkPermissions = () => {
  let notificationsEnabled = false;
  const notificationDataRaw = localStorage.getItem('xtremepush.data');
  if (notificationDataRaw) {
    const notificationData = JSON.parse(notificationDataRaw);
    notificationsEnabled = notificationData.subscription;
  }

  SPushNotification.notificationsEnabled = notificationsEnabled;
};

const initialize = () => {
  checkPermissions();
};

export const PushNotification = {
  initialize,
};
