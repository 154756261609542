import { getText, TEXT_KEY } from '../../../utils/localization';
export var getFullUnitName = function getFullUnitName(shortUnitName, value) {
  switch (shortUnitName) {
    case 'Sec':
      return generatePatchedText(TEXT_KEY.SECONDS);
    case 'Min':
      return value > 1 ? generatePatchedText(TEXT_KEY.MINUTES) : generatePatchedText(TEXT_KEY.MINUTE);
    case 'Hr':
      return value > 1 ? generatePatchedText(TEXT_KEY.HOURS) : generatePatchedText(TEXT_KEY.HOUR);
    case 'Day':
      return value > 1 ? generatePatchedText(TEXT_KEY.DAYS) : generatePatchedText(TEXT_KEY.DAY);
    default:
      return shortUnitName;
  }
};
var generatePatchedText = function generatePatchedText(textKey) {
  var _getText, _getText2;
  var firstLetter;
  var patchedWord;
  firstLetter = (_getText = getText(textKey)) === null || _getText === void 0 || (_getText = _getText.charAt(0)) === null || _getText === void 0 ? void 0 : _getText.toUpperCase();
  patchedWord = firstLetter + ((_getText2 = getText(textKey)) === null || _getText2 === void 0 || (_getText2 = _getText2.slice(1)) === null || _getText2 === void 0 ? void 0 : _getText2.toLowerCase());
  return patchedWord;
};