import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { DefaultTooltipContext } from './TooltipContainer';

function TooltipArrow() {
  const { bottomArrow, arrowStyle, DefaultTooltipColorTheme, arrowOverStyle } = useContext(DefaultTooltipContext);

  const arrowBorderColor = DefaultTooltipColorTheme?.Border?.stopColor1;
  const arrowMainColor = DefaultTooltipColorTheme?.Background?.stopColor1;
  return (
    <TooltipArrowBase arrowStyle={arrowStyle} arrowBorderColor={arrowBorderColor} bottomArrow={bottomArrow}>
      <TooltipArrowOver arrowMainColor={arrowMainColor} arrowOverStyle={arrowOverStyle} />
    </TooltipArrowBase>
  );
}

export default TooltipArrow;

const TooltipArrowBase = styled.div`
  position: absolute;
  display: flex;

  justify-content: center;
  align-items: center;

  border-width: 25px; /* Make the border slightly larger */
  border-style: solid;
  border-color: ${({ arrowBorderColor }) => `transparent transparent ${arrowBorderColor} transparent`};
  z-index: 2;
  ${({ bottomArrow, left, arrowStyle }) =>
    !bottomArrow &&
    css`
      left: calc(${left || arrowStyle?.left || 50}% - 25px);
      bottom: 100%;
    `}
  ${({ bottomArrow, left, arrowStyle }) =>
    bottomArrow &&
    css`
      transform: rotate(180deg);
      top: 100%;
      left: calc(${left || arrowStyle?.left || 50}% - 25px);
      bottom: auto;
    `}
`;

const TooltipArrowOver = styled.div`
  position: absolute;
  bottom: -25px;
  border-width: 20px;
  border-style: solid;
  border-color: ${({ arrowMainColor }) => `transparent transparent ${arrowMainColor} transparent`};
  ${({ arrowOverStyle }) => arrowOverStyle}
`;

