import { Component } from 'react';
import { connect } from 'shared/node_modules/react-redux';
import { setTarget } from 'shared/state/actions/app';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

class AppInit extends Component {
  state = {};

  componentDidMount() {
    this.props.setTarget(ENVIRONMENT_VARIABLES.APPLICATION_TARGET);
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = {
  setTarget,
};

export default connect(null, mapDispatchToProps)(AppInit);
