import DefaultUpdateTerms from './components/DefaultUpdateTerms';
import StarUpdateTerms from './components/StarUpdateTerms';
import generateRender from 'shared/utils/generateRender';

const UpdateTerms = generateRender({
  default: DefaultUpdateTerms,
  star: StarUpdateTerms,
});

export default UpdateTerms;
