module.exports = {
  dimensions: {
    width: 172,
    height: 52
  },
  optical: {
    top: 0,
    left: 2,
    right: 2,
    bottom: 2
  },
  content: {
    top: 0,
    left: 16,
    right: 16,
    bottom: 7
  }
};