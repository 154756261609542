import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import QueryParamProxy from './queryParamProxy';
import RouteParamConstants from './routeParamConstants';
import Screens from '../screens';
import rewardCenterMiddleware from '../screens/RewardCenter/utils/middleware';
import eventsMiddleware from '../screens/Events/utils/middleware';
import store from '../state/store';
import { setLobbyNews } from '../state/actions/promotions';
export var PromoActionKey = {
  NO_ACTION: '',
  GO_TO_COIN_STORE: 'gotoStore',
  GO_TO_REWARDS: 'rewards',
  LAUNCH_GAME: 'game',
  REDIRECT_TO_EXTERNAL_PAGE: 'url',
  DOWNLOAD_MOBILE_APP: 'downloadMobileApp',
  LINK_LOYALTY_CARD: 'linkLoyaltyCard',
  INBOX_MESSAGE: 'inboxMessage',
  EVENT_CARD: 'eventCard'
};
export var PromotionAction = {
  getActionCallback: function getActionCallback(navigation, action, notificationContext) {
    var type = action.type,
      game = action.game,
      externalUrl = action.externalUrl,
      eventId = action.eventId;
    switch (type) {
      case PromoActionKey.NO_ACTION:
        return function () {};
      case PromoActionKey.DOWNLOAD_MOBILE_APP:
        return function () {
          alert('Please Go Download Mobile App!');
        };
      case PromoActionKey.GO_TO_COIN_STORE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.Shop));
        };
      case PromoActionKey.EVENT_CARD:
        return function () {
          return eventsMiddleware({
            navigation: navigation,
            notificationContext: notificationContext,
            eventId: eventId
          });
        };
      case PromoActionKey.GO_TO_REWARDS:
        return function () {
          return rewardCenterMiddleware({
            navigation: navigation,
            notificationContext: notificationContext
          });
        };
      case PromoActionKey.LAUNCH_GAME:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
          QueryParamProxy.navigate(navigation, Screens.Game, _defineProperty({}, RouteParamConstants.PARAM_GAME_ID, game));
        };
      case PromoActionKey.LINK_LOYALTY_CARD:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.LinkAccount));
        };
      case PromoActionKey.REDIRECT_TO_EXTERNAL_PAGE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_EXTERNAL_URL, externalUrl));
        };
      case PromoActionKey.INBOX_MESSAGE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.Social));
        };
      default:
        return function () {};
    }
  }
};
export default PromotionAction;
export var promoListHandler = function promoListHandler(_ref) {
  var first_slide = _ref.first_slide,
    second_slide = _ref.second_slide,
    third_slide = _ref.third_slide;
  var rotatingBanners = [];
  if ((first_slide === null || first_slide === void 0 ? void 0 : first_slide.length) > 0) rotatingBanners.push(first_slide);
  if ((second_slide === null || second_slide === void 0 ? void 0 : second_slide.length) > 0) rotatingBanners.push(second_slide);
  if ((third_slide === null || third_slide === void 0 ? void 0 : third_slide.length) > 0) rotatingBanners.push(third_slide);
  if (rotatingBanners.length > 0) store.dispatch(setLobbyNews(rotatingBanners));
};