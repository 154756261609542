import React, { useContext, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { getOfferDescription, hasLimitedState } from 'shared/screens/RewardCenter/utils/validations';
import ThemeContext from 'shared/context/ThemeContext';
import {
  CarouselWrapper,
  CoinsIcon,
  CoinsValue,
  CustomIndicator,
  OfferFrame,
  OfferImageBannerStyle,
  OfferImageLimitedDescription,
} from './styledComponents';

const OfferDetailsCarousel = ({ rewardCenterAssets, offerDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { images, rewardname } = offerDetails;
  const displayIndicators = images.length > 1;
  const { OfferDetail } = useContext(ThemeContext);

  const handleSelect = selectedIndex => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="RewardCenterSideBar">
      <CarouselWrapper>
        <div className="OfferDetailThumbnailContainer">
          <OfferFrame src={rewardCenterAssets?.REWARD_CENTER_OFFER_FRAME_CARD} />
          <Carousel controls={false} indicators={false} activeIndex={activeIndex} onSelect={handleSelect}>
            {images.map((imgSrc, index) => (
              <Carousel.Item
                key={index}
                className="OfferDetailThumbnail"
                style={{ position: 'absolute', borderRadius: '10%' }}
              >
                <img
                  src={imgSrc}
                  alt={`Offer Detail Thumbnail ${index + 1}`}
                  onError={e => {
                    e.target.style.display = 'none';
                  }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          {displayIndicators && (
            <CustomIndicators count={images.length} activeIndex={activeIndex} onSelect={handleSelect} />
          )}
          {hasLimitedState(offerDetails.ribbontype) && (
            <OfferImageLimitedDescription theme={OfferDetail.OfferImageLimitedDescription}>
              <OfferImageBannerStyle src={rewardCenterAssets.REWARD_CENTER_OFFER_DETAILS_PURPLE_BAR} />
              <CoinsIcon src={rewardCenterAssets.REWARD_CENTER_COINS_ICON} />
              <CoinsValue theme={OfferDetail.CoinsValue}>
                {getOfferDescription({ ...offerDetails, rewardName: rewardname })}
              </CoinsValue>
            </OfferImageLimitedDescription>
          )}
        </div>
      </CarouselWrapper>
    </div>
  );
};

const CustomIndicators = ({ count, activeIndex, onSelect }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
      {Array.from({ length: count }).map((_, index) => (
        <CustomIndicator
          key={index}
          onClick={() => onSelect(index)}
          style={
            index === activeIndex
              ? themeContext.OfferDetail.CarouselIndicatorActive
              : themeContext.OfferDetail.CarouselIndicator
          }
        />
      ))}
    </div>
  );
};

export default OfferDetailsCarousel;

