import { connect } from 'shared/node_modules/react-redux';
import SideMenu from './SideMenu';
import { setOnboardingHighlightedPosition, setOnboardingStep } from 'shared/state/actions/currentOnboarding';

const mapStateToProps = ({ currentOnboarding: { currentStep } }) => {
  return {
    onboardingStep: currentStep,
  };
};

const mapDispatchToProps = {
  setOnboardingHighlightedPosition,
  setOnboardingStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
