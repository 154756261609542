import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppLanguage } from '../../../../../state/actions/app';
import { reload } from '../utils/reload';
import { usePostSetLanguageMutation } from '../../../../../state/query/language';
import { useContext } from 'react';
import NotificationContext from '../../../../../context/NotificationContext';
import { useEffect } from 'react';
import { TEXT_KEY, getText } from '../../../../../utils/localization';
import routeParamConstants from '../../../../../utils/routeParamConstants';
import QueryParamProxy from '../../../../../utils/queryParamProxy';
var useLanguageSwitcher = function useLanguageSwitcher() {
  var dispatch = useDispatch();
  var _useContext = useContext(NotificationContext),
    addNotification = _useContext.addNotification;
  var languageApp = useSelector(function (state) {
    return state.app.language;
  });
  var _useState = useState(languageApp),
    _useState2 = _slicedToArray(_useState, 2),
    language = _useState2[0],
    setLanguage = _useState2[1];
  var _usePostSetLanguageMu = usePostSetLanguageMutation(),
    _usePostSetLanguageMu2 = _slicedToArray(_usePostSetLanguageMu, 2),
    postSetLanguage = _usePostSetLanguageMu2[0],
    _usePostSetLanguageMu3 = _usePostSetLanguageMu2[1],
    isSuccess = _usePostSetLanguageMu3.isSuccess,
    isError = _usePostSetLanguageMu3.isError;
  useEffect(function () {
    if (isSuccess) {
      dispatch(setAppLanguage(language));
      setTimeout(function () {
        reload();
      }, 500);
    }
    if (isError) addNotification({
      title: getText(TEXT_KEY.OOPS),
      message: getText(TEXT_KEY.LOCALIZATION_ERROR),
      button: getText(TEXT_KEY.OK),
      hideHelpButton: true,
      hideCloseButton: true,
      customMessageStyle: {
        width: '70%'
      },
      buttonAction: function buttonAction() {
        return QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, undefined));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);
  var handleLanguageChange = function handleLanguageChange() {
    return language !== languageApp && postSetLanguage({
      language: language
    });
  };
  return {
    language: language,
    setLanguage: setLanguage,
    handleLanguageChange: handleLanguageChange
  };
};
export default useLanguageSwitcher;