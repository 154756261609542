import generateRender from 'shared/utils/generateRender';
import AdmiralBottomBar from './AdmiralBottomBar';
import DefaultBottomBar from './DefaultBottomBar';

export const BottomBarComponent = generateRender({
  default: DefaultBottomBar,
  admiral: AdmiralBottomBar,
  admes: AdmiralBottomBar,
});

