import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const TrackingURLContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }

  background-position: initial;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  width: ${props => props.windowWidth}px;
  height: ${props => props.windowHeight}px;
  background-image: ${props => props.backgroundImage};
  background-size: cover;
  padding: 0 5vmin;

  @media only screen and (max-width: 1000px) {
    background-position: center;
  } ;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  width: ${props => (props.isSignUpEnabled ? '50%' : '60%')};
  ${props => props.isSignUpEnabled && 'padding: 0 2% 0 2%;'}
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (orientation: portrait) and (max-width: 460px) {
    width: 100%;

    div {
      margin-top: 1em;
    }
  }
`;

export const FormContainer = styled.div`
  width: 50%;
  padding: 4vmin 0 4vmin 0;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 0.5em;
`;

export const FormTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: ${theme.palette.common[1]};
  text-transform: uppercase;
`;

export const ClientLogo = styled.img`
  width: ${props => (props.isSignUpEnabled ? '20%' : '40%')};
  margin: auto;
`;

export const MainImage = styled.img`
  width: 90%;
`;

export const PageDescriptionContainer = styled.div`
  font-size: 1.5rem;
  width: 100%;
  margin: auto;
  margin-top: 1.5rem;

  @media screen and (orientation: portrait) and (max-width: 460px) {
    margin-top: 1em;
  }
`;

export const PageDescription = styled.p`
  font-size: 1.5rem;
  font-weight: 600;

  @media screen and (orientation: portrait) and (max-width: 460px) {
    font-size: 4.5rem;
  }
`;

export const PageBottomText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;

  @media screen and (orientation: portrait) and (max-width: 460px) {
    font-size: 4.5rem;
  }
`;

export const LinkIconHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  @media screen and (orientation: portrait) and (max-width: 460px) {
    flex-direction: column;
  }
`;

export const InnerDiv = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: 1.5rem;
  }

  @media screen and (orientation: portrait) and (max-width: 460px) {
    margin-top: 2rem;
    width: 45em;
  }
`;

export const Badge = styled.img`
  width: 80%;

  @media screen and (orientation: portrait) and (max-width: 460px) {
    margin-top: 2rem;
    width: 45em;
`;

export const TextContainer = styled.p`
  font-size: 20px;
  margin: 0px;
  padding-top: 5px;
  font-weight: 600;
`;

export const SignInButtonWrapper = styled.div`
  justify-content: center !important;
`;

export const SignInButtonImage = styled.img`
  cursor: pointer;
  width: 15em;
  height: 35%;
`;

export const PlayAsGuestButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 12px 0;
`;
