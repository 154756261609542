import { connect } from 'react-redux';
import Toast from './Toast';
import { removeNotif as _removeNotif } from '../../state/actions/notification';
var mapStateToProps = function mapStateToProps(state) {
  return {};
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    removeNotif: function removeNotif(id) {
      dispatch(_removeNotif(id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Toast);