import styled from 'styled-components';
import { Tooltip } from '..';

export const START_MISSION = {
  TOOLTIP: styled(Tooltip)`
    width: 48vh;
    top: 13vh;
    left: 52vh;
  `,
};

