import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Converts an API object-array to a JS array.
 * @param  {Object} objectArray An object with sequential, numeric keys.
 * @returns {Array} A JS array.
 */
export default function transformObjectArrayResponse(objectArray) {
  var list = [];
  var sortKey = '__sort_key';

  // Is this a (valid) object?
  if (typeof objectArray === 'object' && objectArray !== null) {
    for (var key in objectArray) {
      if (!objectArray.hasOwnProperty(key)) continue;
      var index = list.push(objectArray[key]) - 1;
      list[index] = _objectSpread(_objectSpread({}, list[[index]]), {}, _defineProperty({}, sortKey, parseInt(key)));
    }
    list.sort(function (a, b) {
      return a[sortKey] - b[sortKey];
    });
  }
  return list;
}