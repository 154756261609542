const viewportWidth = '100vw';
export default function responsiveFontSize(
  minimumSize,
  maximumSize,
  minimumViewportWidth = 800,
  maximumViewportWidth = 1600
) {
  //calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw -[minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])))
  return `calc(${minimumSize}px + ${maximumSize -
    minimumSize} * ((${viewportWidth} - ${minimumViewportWidth}px) / ${maximumViewportWidth - minimumViewportWidth}))`;
}
