import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SEND_PUSH_NOTIFICATION, REMOVE_PUSH_NOTIFICATION, UPDATE_MAILBOX_UNREAED_MESSAGE } from './types';
import { store } from '../store';
export var addNotif = function addNotif(notif) {
  return {
    type: ADD_NOTIFICATION,
    notif: notif
  };
};
export var removeNotif = function removeNotif(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id: id
  };
};
export var sendPushNotification = function sendPushNotification(playerId, notifiedFeature) {
  return {
    type: SEND_PUSH_NOTIFICATION,
    payload: {
      playerId: playerId,
      notifiedFeature: notifiedFeature
    }
  };
};
export var removePushNotification = function removePushNotification(notifiedFeature) {
  return {
    type: REMOVE_PUSH_NOTIFICATION,
    payload: {
      playerId: store.getState().playerInfo.playerId,
      notifiedFeature: notifiedFeature
    }
  };
};
export var updateUnreadMessageCount = function updateUnreadMessageCount(messageCount) {
  return {
    type: UPDATE_MAILBOX_UNREAED_MESSAGE,
    payload: messageCount
  };
};