import { EVENT_TYPES } from '../constants';

export default {
  handleEvent: (eventType, payload) => {
    switch (eventType) {
      case EVENT_TYPES.REGISTRATION_COMPLETE:
        window.fbq('track', 'CompleteRegistration', {
          content_name: payload.type,
          currency: 'USD',
        });
        break;
      case EVENT_TYPES.PURCHASE_COMPLETE:
        window.fbq('track', 'Purchase', { value: payload.price || payload.amount, currency: 'USD' });
        break;
      case EVENT_TYPES.TUTORIAL_COMPLETE:
        window.fbq('track', 'ViewContent', { content_name: payload.type });
        break;
      default:
        break;
    }
  },
};
