import { EVENT_TYPES as sharedEvents, TARGET_TYPES as sharedTypes } from 'shared/lib/analytics/constants';

export const TARGET_TYPES = {
  FACEBOOK_PIXEL: 'facebookPixel',
  GOOGLE_TAG_MANAGER: 'googleTagManager',
  ...sharedTypes,
};

export const EVENT_TYPES = {
  ...sharedEvents,
};
