export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        configurable_vip: {
          type: 'object',
          properties: {
            0: {
              type: 'object',
              properties: {
                backgroundImage: {
                  type: 'string'
                },
                can_enter_room: {
                  type: 'boolean'
                },
                isGameRoomUnlocked: {
                  type: 'boolean'
                },
                roomName: {
                  type: 'string'
                },
                room_id: {
                  type: 'string'
                },
                timeRemainingForUnlockedStateInSeconds: {
                  type: 'integer'
                },
                gameRoomBalanceThreshold: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer'
                    },
                    currency: {
                      type: 'string'
                    }
                  }
                },
                news: {
                  type: 'object'
                },
                games: {
                  type: 'object',
                  patternProperties: {
                    '[0-1000]+': {
                      type: 'object',
                      properties: {
                        0: {
                          type: 'string'
                        },
                        1: {
                          type: 'string'
                        },
                        2: {
                          type: 'string'
                        },
                        3: {
                          type: 'string'
                        },
                        4: {
                          type: 'string'
                        },
                        5: {
                          type: 'string'
                        },
                        6: {
                          type: 'string'
                        },
                        7: {
                          type: 'string'
                        },
                        8: {
                          type: 'boolean'
                        },
                        9: {
                          type: 'string'
                        },
                        10: {
                          type: 'boolean'
                        }
                      },
                      required: ['0', '1', '2', '3', '4', '6', '8', '10']
                    }
                  }
                }
              }
            }
          }
        },
        featured: {
          type: 'object',
          properties: {
            0: {
              type: 'object',
              properties: {
                backgroundImage: {
                  type: 'string'
                },
                can_enter_room: {
                  type: 'boolean'
                },
                isGameRoomUnlocked: {
                  type: 'boolean'
                },
                roomName: {
                  type: 'string'
                },
                room_id: {
                  type: 'string'
                },
                timeRemainingForUnlockedStateInSeconds: {
                  type: 'integer'
                },
                gameRoomBalanceThreshold: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer'
                    },
                    currency: {
                      type: 'string'
                    }
                  }
                },
                news: {
                  type: 'object'
                },
                games: {
                  type: 'object',
                  patternProperties: {
                    '[0-1000]+': {
                      type: 'object',
                      properties: {
                        0: {
                          type: 'string'
                        },
                        1: {
                          type: 'string'
                        },
                        2: {
                          type: 'string'
                        },
                        3: {
                          type: 'string'
                        },
                        4: {
                          type: 'string'
                        },
                        5: {
                          type: 'string'
                        },
                        6: {
                          type: 'string'
                        },
                        7: {
                          type: 'string'
                        },
                        8: {
                          type: 'boolean'
                        },
                        9: {
                          type: 'string'
                        },
                        10: {
                          type: 'boolean'
                        }
                      },
                      required: ['0', '1', '2', '3', '4', '6', '8', '10']
                    }
                  }
                }
              }
            }
          }
        },
        new_games: {
          type: 'object',
          properties: {
            0: {
              type: 'object',
              properties: {
                backgroundImage: {
                  type: 'string'
                },
                can_enter_room: {
                  type: 'boolean'
                },
                isGameRoomUnlocked: {
                  type: 'boolean'
                },
                roomName: {
                  type: 'string'
                },
                room_id: {
                  type: 'string'
                },
                timeRemainingForUnlockedStateInSeconds: {
                  type: 'integer'
                },
                gameRoomBalanceThreshold: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer'
                    },
                    currency: {
                      type: 'string'
                    }
                  }
                },
                news: {
                  type: 'object'
                },
                games: {
                  type: 'object',
                  patternProperties: {
                    '[0-1000]+': {
                      type: 'object',
                      properties: {
                        0: {
                          type: 'string'
                        },
                        1: {
                          type: 'string'
                        },
                        2: {
                          type: 'string'
                        },
                        3: {
                          type: 'string'
                        },
                        4: {
                          type: 'string'
                        },
                        5: {
                          type: 'string'
                        },
                        6: {
                          type: 'string'
                        },
                        7: {
                          type: 'string'
                        },
                        8: {
                          type: 'boolean'
                        },
                        9: {
                          type: 'string'
                        },
                        10: {
                          type: 'boolean'
                        }
                      },
                      required: ['0', '1', '2', '3', '4', '6', '8', '10']
                    }
                  }
                }
              }
            }
          }
        },
        slots: {
          type: 'object',
          properties: {
            0: {
              type: 'object',
              properties: {
                backgroundImage: {
                  type: 'string'
                },
                can_enter_room: {
                  type: 'boolean'
                },
                isGameRoomUnlocked: {
                  type: 'boolean'
                },
                roomName: {
                  type: 'string'
                },
                room_id: {
                  type: 'string'
                },
                timeRemainingForUnlockedStateInSeconds: {
                  type: 'integer'
                },
                gameRoomBalanceThreshold: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer'
                    },
                    currency: {
                      type: 'string'
                    }
                  }
                },
                news: {
                  type: 'object'
                },
                games: {
                  type: 'object',
                  patternProperties: {
                    '[0-1000]+': {
                      type: 'object',
                      properties: {
                        0: {
                          type: 'string'
                        },
                        1: {
                          type: 'string'
                        },
                        2: {
                          type: 'string'
                        },
                        3: {
                          type: 'string'
                        },
                        4: {
                          type: 'string'
                        },
                        5: {
                          type: 'string'
                        },
                        6: {
                          type: 'string'
                        },
                        7: {
                          type: 'string'
                        },
                        8: {
                          type: 'boolean'
                        },
                        9: {
                          type: 'string'
                        },
                        10: {
                          type: 'boolean'
                        }
                      },
                      required: ['0', '1', '2', '3', '4', '6', '8', '10']
                    }
                  }
                }
              }
            }
          }
        },
        table: {
          type: 'object',
          properties: {
            0: {
              type: 'object',
              properties: {
                backgroundImage: {
                  type: 'string'
                },
                can_enter_room: {
                  type: 'boolean'
                },
                isGameRoomUnlocked: {
                  type: 'boolean'
                },
                roomName: {
                  type: 'string'
                },
                room_id: {
                  type: 'string'
                },
                timeRemainingForUnlockedStateInSeconds: {
                  type: 'integer'
                },
                gameRoomBalanceThreshold: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer'
                    },
                    currency: {
                      type: 'string'
                    }
                  }
                },
                news: {
                  type: 'object'
                },
                games: {
                  type: 'object',
                  patternProperties: {
                    '[0-1000]+': {
                      type: 'object',
                      properties: {
                        0: {
                          type: 'string'
                        },
                        1: {
                          type: 'string'
                        },
                        2: {
                          type: 'string'
                        },
                        3: {
                          type: 'string'
                        },
                        4: {
                          type: 'string'
                        },
                        5: {
                          type: 'string'
                        },
                        6: {
                          type: 'string'
                        },
                        7: {
                          type: 'string'
                        },
                        8: {
                          type: 'boolean'
                        },
                        9: {
                          type: 'string'
                        },
                        10: {
                          type: 'boolean'
                        }
                      },
                      required: ['0', '1', '2', '3', '4', '6', '8', '10']
                    }
                  }
                }
              }
            }
          }
        }
      },
      required: ['configurable_vip', 'featured', 'slots', 'table']
    }
  }
};