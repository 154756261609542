module.exports = {
  dimensions: {
    width: 45,
    height: 37
  },
  content: {
    top: 12,
    left: 12,
    right: 21,
    bottom: 12
  },
  optical: {
    top: 9,
    left: 8,
    right: 18,
    bottom: 9
  }
};