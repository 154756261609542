export default (function () {
  var url = window.location.href;
  var allParams = url.indexOf('?') > -1 && url.split('?')[1];
  var searchEachParam = new URLSearchParams(allParams);
  var newLocalStorageData;
  searchEachParam.has('persistroot') && (newLocalStorageData = decodeURIComponent(searchEachParam.get('persistroot')));

  //set new local storage values
  newLocalStorageData && localStorage.setItem('persist:root', newLocalStorageData);
  window.location.href = url.split('?')[0];
});