module.exports = {
  dimensions: {
    width: 2031,
    height: 1149
  },
  optical: {
    top: 6,
    left: 8,
    right: 8,
    bottom: 12
  },
  nine: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    top: 9,
    left: 20,
    right: 20,
    bottom: 12
  }
};