import React from 'react';
import {
  HeaderHeight,
  HeaderWrapper,
  HeaderContainer,
  HighlightHeaderTitle,
  HighlightHeaderSubTitle,
} from './styledComponents';

function HighlightedHeader({
  title,
  subTitle,
  wrapperStyle = {},
  containerStyle = {},
  titleStyle = {},
  subTitleStyle = {},
  headerHeightStyle = {},
}) {
  return (
    <>
      <HeaderWrapper theme={wrapperStyle}>
        <HeaderContainer theme={containerStyle}>
          <HighlightHeaderTitle theme={titleStyle}>{title}</HighlightHeaderTitle>
          {subTitle && <HighlightHeaderSubTitle theme={subTitleStyle}>{subTitle}</HighlightHeaderSubTitle>}
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderHeight theme={headerHeightStyle} />
    </>
  );
}

export default HighlightedHeader;

