import React from 'react';
import useRewardCenterHistoryDetails from 'shared/screens/RewardCenter/hooks/useRewardCenterHistoryDetails';
import QrHistoryDetail from './components/QrHistoryDetail';
import ActivityIndicator from '../../../../components/ActivityIndicator/ActivityIndicator';

const RewardCenterHistoryDetail = props => {
  const { activeHistoryItem } = props;
  const { offerHistoryDetails, onSendEmail, emailSentMessage, voucherCode, isLoading } = useRewardCenterHistoryDetails(
    activeHistoryItem.rpid
  );

  return !isLoading && offerHistoryDetails ? (
    <QrHistoryDetail
      activeHistoryItem={activeHistoryItem}
      offerHistoryDetails={offerHistoryDetails}
      emailSentMessage={emailSentMessage}
      onSendEmail={onSendEmail}
      voucherCode={voucherCode}
    />
  ) : (
    <div className="RewardCenterHistoryDetail">
      <ActivityIndicator />
    </div>
  );
};

export default RewardCenterHistoryDetail;

