import React from 'react';
import styled from 'styled-components';

export default function EventsTimerText({ style, string, strokeColor }) {
  return (
    <StringText strokeColor={strokeColor} theme={style}>
      {string}
    </StringText>
  );
}

const StringText = styled.div`
  ${({ theme }) => theme};
  color: #fff;
  font-weight: 800;
  -webkit-text-stroke: 1.3px ${({ strokeColor }) => strokeColor};
  text-stroke: 1.3px ${({ strokeColor }) => strokeColor};
`;

