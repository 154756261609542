import React from 'react';
import { Spinner } from 'react-bootstrap';
import useAuthSwitchShared from 'shared/components/AuthSwitch/useAuthSwitch';
import AppBackgroundType from 'shared/utils/AppBackgroundType';
import AssetPreloader from '../AssetPreloader';
import useAuthSwitch from './useAuthSwitch';
import { getAppBackgroundImage } from './utils';

const AuthSwitch = props => {
  useAuthSwitch();

  const {
    descriptor,
    hasAssetsLoaded,
    initTextsLoaded,
    SceneView,
    appBackground,
    restartSession,
    backgroundPromo,
    isConnected,
    promotionImagesUrl,
  } = useAuthSwitchShared(props);

  return (
    <div className="App">
      <img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: appBackground === AppBackgroundType.VIP_GAMES ? 1 : -1,
          width: '100%',
          height: '100%',
        }}
        alt="background"
        src={getAppBackgroundImage(
          isConnected ? (!restartSession ? appBackground : AppBackgroundType.NORMAL) : AppBackgroundType.NORMAL,
          backgroundPromo,
          promotionImagesUrl
        )}
      />
      {!initTextsLoaded && (
        <div style={{ position: 'absolute', zIndex: 99, top: '50%', left: '50%' }}>
          <Spinner animation="border" />
        </div>
      )}
      {isConnected && !restartSession && initTextsLoaded && (
        <>
          {hasAssetsLoaded ? (
            <SceneView component={descriptor.getComponent()} navigation={descriptor?.navigation} />
          ) : (
            <AssetPreloader navigation={descriptor?.navigation} />
          )}
        </>
      )}
    </div>
  );
};

export default AuthSwitch;

