import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsWrapper = styled.div`
  width: 100%;
  align-self: center;
  justify-content: space-evenly;
  display: flex;
`;

const TabContainer = styled.div`
  width: 378px;
  z-index: 10;
`;

const TabImage = styled.img`
  width: 264px;
  height: 100%;
  ${({ theme }) => theme}
`;

const PurpleUnderline = styled.img`
  width: 100%;
  height: 0.8em;
  position: absolute;
  top: 3.6em;
  left: 0;
`;

export { Container, TabsWrapper, TabImage, TabContainer, PurpleUnderline };

