import React from 'react';
import styled from 'styled-components';

const LiveOpsCard = ({ renderElements: { TopBanner }, liveOpsTexts }) => {
  return (
    <Container>
      <ImageWrapper>
        <LiveOpsImage src={TopBanner} />
      </ImageWrapper>
      <TitleWrapper>
        <EventTitle>{liveOpsTexts.cardTitle}</EventTitle>
      </TitleWrapper>
      <DescriptionWrapper>
        <EventDescription>{liveOpsTexts.cardDescription}</EventDescription>
      </DescriptionWrapper>
    </Container>
  );
};

export default LiveOpsCard;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

const ImageWrapper = styled.div`
  height: 39%;
  width: 93%;
`;

const LiveOpsImage = styled.img`
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  position: relative;
  top: 1rem;
`;

const EventTitle = styled.p`
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px 0.5px 1px #424242;
  font-weight: bold;
  margin-top: 1.5rem;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10rem;
  position: relative;
  top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const EventDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1.5px 4px #424242;
  font-weight: normal;
  white-space: pre-wrap;
  padding-right: 1rem;
  text-align: left;
  margin: 0;
`;

