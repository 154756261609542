import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppContent from './AppContent';
import useGeoCheck from './screens/GeoBlock/hook/useGeoCheck';
import GeoBlock from './screens/GeoBlock';
import { geoCheckStatus } from './screens/GeoBlock/utils';

const ApplicationManager = () => {
  const { ipLockStatus, geoCheckMessage } = useGeoCheck();
  const SelectedComponent =
    ipLockStatus === geoCheckStatus.PASS ? (
      <AppContent />
    ) : (
      <GeoBlock message={geoCheckMessage} status={ipLockStatus} />
    );
  return SelectedComponent;
};

ReactDOM.render(<ApplicationManager />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

