import styled from 'styled-components';
import { Tooltip, ShadowLayer } from '..';

export const EXPLORE = {
  TOOLTIP: styled(Tooltip)`
    width: 490px;
    top: 130px;
    right: calc(50vw - 610px);
  `,
  TOP: styled(ShadowLayer)`
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
  `,
};

