import React from 'react';
import { isStar } from 'shared/utils/targetApplication';
import { HistoryTableHeader, HistoryTableHeaderItem } from './styledComponents';
import { TEXT_KEY, getText } from 'shared/utils/localization';

const HistoryListHeader = ({ displayDetailsColumn }) => {
  return (
    <HistoryTableHeader>
      {[
        { key: TEXT_KEY.OFFER },
        { key: TEXT_KEY.STATUS },
        { key: TEXT_KEY.REDEMPTION_DATE },
        { key: TEXT_KEY.REMAINING, condition: isStar },
        { key: '', condition: displayDetailsColumn },
      ].map(
        ({ key, condition }, index) =>
          condition !== false && (
            <HistoryTableHeaderItem customWidth={index === 0 ? (isStar ? 40 : 35) : null} key={index}>
              {getText(key)}
            </HistoryTableHeaderItem>
          )
      )}
    </HistoryTableHeader>
  );
};

export default HistoryListHeader;

