export var Name = 'KeyValuePairParser';

/** @type {import("./parseEvent").Parser} */
export default function KeyValuePairParser(data) {
  var expr = /(.*):(.*)/;
  var result = data.match(expr);

  // No match, false
  if (result === null) {
    return false;
  }

  // Quick check if it parses to a valid number
  // If so, convert it to one
  var val = result[2];
  var numVal = filterInt(result[2]);
  if (!isNaN(numVal)) {
    val = numVal;
  }

  /** @type {import("./parseEvent").ParsedEvent} */
  var event = {
    name: result[1],
    value: val,
    _from: Name,
    __source: data
  };
  return event;
}

/**
 * Stricter int parse.
 * @param {String} value Value to test to see if it's a number
 */
var filterInt = function filterInt(value) {
  if (/^(-|\+)?(\d+|Infinity)$/.test(value)) return Number(value);
  return NaN;
};