import theme from 'shared/assets/style/theme';
const _style = {
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 40vmin',
  },
  modalContainer: {
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '2vw',
  },
  header: {
    width: '100%',
    height: 70,
  },
  headerImage: {
    height: 70,
    width: '100%',
    objectFit: 'contain',
    zIndex: 11,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  headerContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 5vmin',
    top: '1em',
  },
  title: {
    fontSize: '29px',
    fontWeight: '600',
    color: theme.palette.common[1],
    textTransform: 'uppercase',
    margin: 0,
  },
  help: {
    fontSize: '15px',
    fontWeight: '600',
    color: theme.palette.common[1],
  },
  body: {
    width: '90%',
    maxWidth: '700px',
    padding: '0 1vmin',
    margin: window.innerHeight > 900 ? '2em auto 0 auto' : '1em auto 0 auto',
  },
  modalBody: {
    width: '90%',
    height: '86%',
    padding: '0 1vmin',
    margin: window.innerHeight > 900 ? '2em auto' : '1em auto',
  },
  titleHolder: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '0 5vmin',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 0,
  },
  newUserForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftInput: {
    width: '45%',
  },
  rightInput: {
    width: '45%',
  },
  inputLabel: {
    width: '100%',
    textAlign: 'left',
    marginBottom: window.innerHeight > 900 ? '1em' : '.5em',
    position: 'relative',
  },
  nameInputField: {
    width: '100%',
    height: '42px',
    textAlign: 'left',
    paddingLeft: '1em',
    boxShadow: 'none',
    borderRadius: '0.3em',
    backgroundColor: 'unset',
    color: 'white',
    border: '1px solid rgba(255,255,255,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  inputTitle: {
    fontSize: '1rem',
  },
  errorInInput: {
    width: '100%',
    height: '46px',
    textAlign: 'left',
    boxShadow: 'none',
    borderRadius: '0.3em',
    backgroundColor: 'unset',
    color: 'white',
    border: '1px solid red',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  checkBoxContainer: {
    width: '100%',
    textAlign: 'left',
    margin: '2em auto',
  },
  checkBox: {
    display: 'flex',
    textAlign: 'left',
  },
  checkBoxText: {
    fontSize: '0.9em',
    marginLeft: '0.5em',
  },
  linkText: {
    textDecoration: 'underline',
    color: '#a7550e',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  registerButton: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '23em',
    margin: 'auto',
  },
  registerButtonImage: {
    width: '50%',
  },
  errorMessage: {
    color: 'red',
  },
  successMessage: {
    color: 'green',
  },
  peeker: {
    position: 'absolute',
    top: '20%',
    right: 16,
    zIndex: 10,
    cursor: 'pointer',
  },
};

export default _style;
