import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import LimitedTimeOffer from './LimitedTimeOffer';
import { connect } from '../../node_modules/react-redux';
import { setPurchaseType as _setPurchaseType, isLTOPurchase as _isLTOPurchase, setPurchaseProfile as _setPurchaseProfile } from '../../state/actions/prePurchase';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import { setAuthCallbackPurchaseData as _setAuthCallbackPurchaseData } from '../../state/actions/callbackIntent';
var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread({
    limitedTimeOffer: state.consumables.ltoOffer
  }, state.playerInfo);
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setPurchaseType: function setPurchaseType(value) {
      dispatch(_setPurchaseType(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setAuthCallbackPurchaseData: function setAuthCallbackPurchaseData(purchaseData) {
      dispatch(_setAuthCallbackPurchaseData(purchaseData));
    },
    setPurchaseProfile: function setPurchaseProfile(purchaseProfile) {
      dispatch(_setPurchaseProfile(purchaseProfile));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LimitedTimeOffer);