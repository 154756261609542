import { SET_API_ROOT_INDEX, SET_USE_PROD, SET_AD_BLOCKER } from './types';
export var setApiRootIndex = function setApiRootIndex(index) {
  return {
    type: SET_API_ROOT_INDEX,
    payload: index
  };
};
export var setUseProd = function setUseProd(target) {
  return {
    type: SET_USE_PROD,
    payload: target
  };
};
export var setAdBlocker = function setAdBlocker(value) {
  return {
    type: SET_AD_BLOCKER,
    payload: value
  };
};