import DefaultStoreDownload from './components/DefaultStoreDownload';
import HriStoreDownload from './components/HriStoreDownload';
import AdmiralStoreDownload from './components/AdmiralStoreDownload';
import AdmiralEsStoreDownload from './components/AdmiralEsStoreDownload';
import generateRender from 'shared/utils/generateRender';

const StoreDownload = generateRender({
  default: DefaultStoreDownload,
  hri: HriStoreDownload,
  admiral: AdmiralStoreDownload,
  admes: AdmiralEsStoreDownload,
});

export default StoreDownload;

