import generateRender from './generateRender';
import theme from '../assets/style/theme';
var scrollBarTrackColor = generateRender({
  default: theme.palette.secondary[27],
  sga: theme.palette.secondary[41],
  ti: 'rgba(11, 39, 53, 1)',
  admiral: 'rgba(132, 129, 165, 1)',
  admes: 'rgba(132, 129, 165, 1)',
  star: theme.palette.primary[0]
});
var scrollBarThumbColor = generateRender({
  default: theme.palette.primary[34],
  sga: 'rgba(241, 133, 76, 1)',
  ti: theme.palette.primary[31],
  star: theme.palette.primary[25]
});
export { scrollBarTrackColor, scrollBarThumbColor };