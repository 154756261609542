import { SET_AUTH_CALLBACK_INTENT, SET_AUTH_CALLBACK_PURCHASE_DATA } from './types';
export var setAuthCallbackIntent = function setAuthCallbackIntent(intent) {
  return {
    type: SET_AUTH_CALLBACK_INTENT,
    payload: intent
  };
};
export var setAuthCallbackPurchaseData = function setAuthCallbackPurchaseData(purchaseData) {
  return {
    type: SET_AUTH_CALLBACK_PURCHASE_DATA,
    payload: purchaseData
  };
};