module.exports = {
  dimensions: {
    width: 120,
    height: 80
  },
  optical: {
    top: 0,
    left: 0,
    right: 1,
    bottom: 1
  }
};