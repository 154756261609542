import { connect } from 'shared/node_modules/react-redux';
import MissionsProgress from './MissionsProgress';

const mapStateToProps = state => {
  return {
    lastSpinEvents: state.lastSpinEvents,
    currentMission: state.missions.currentMission,
    playerId: state.playerInfo.playerId,
    missionsAssets: state.assetsPackage.assets.MISSIONS_FEATURE,
    timedBonus: state.consumables.timedBonus,
    missionsGameplayStatus: state.missions.missionsFeature.missionsGameplayStatus,
    modal: state.modal.modal,
    showMissionsProgressBar: state.missions.showMissionsProgressBar,
  };
};

export default connect(mapStateToProps)(MissionsProgress);

