import React from 'react'
import CreditCardDetails from '../../CreditCardDetails'

const StarPurchaseFlow = ({hasOffer, getPurchaseStrings, renderTotal, onSubmitStar, paymentProvider, props}) => {
  return (
      <CreditCardDetails
        hasOffer={hasOffer}
        getPurchaseStrings={getPurchaseStrings}
        renderTotal={renderTotal}
        onSubmit={onSubmitStar}
        paymentProvider={paymentProvider}
        {...props}
      />
  )
}

export default StarPurchaseFlow
