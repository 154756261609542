import { SET_IS_CONNECTED, SET_CONNECTION_TYPE, SAVE_DEVICE_INFO } from './types';
export var setIsConnected = function setIsConnected(isConnected) {
  return {
    type: SET_IS_CONNECTED,
    payload: isConnected
  };
};
export var setConnectType = function setConnectType(value) {
  return {
    type: SET_CONNECTION_TYPE,
    payload: value
  };
};
export var saveDeviceInfo = function saveDeviceInfo(value) {
  return {
    type: SAVE_DEVICE_INFO,
    payload: value
  };
};