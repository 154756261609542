var statuses = {
  GUEST: 'Guest',
  FACEBOOK: 'Facebook',
  APPLE: 'Apple',
  GOOGLE: 'google',
  UNVERIFIED: 'Unverified',
  DIGITALLY_VERIFIED: 'Digitally Verified',
  PHYSICALLY_VERIFIED: 'Physically Verified'
};
export var casinoIdStatus = statuses;
export var casinoIdTypes = {
  VERIFIED: [statuses.DIGITALLY_VERIFIED, statuses.PHYSICALLY_VERIFIED],
  UNVERIFIED: [statuses.GUEST, statuses.FACEBOOK, statuses.APPLE, statuses.GOOGLE, statuses.UNVERIFIED]
};