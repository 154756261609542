import { SET_COIN, ADD_COINS, SUBTRACT_COINS, SET_LOYALTY, SET_PREMIUM, SET_LAST_WIN, SET_VIDEO_ADS_INFO, SET_TIME_REMAINING, SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS, ADD_LOYALTY } from './types';
export var setCoin = function setCoin(balance) {
  return {
    type: SET_COIN,
    payload: balance
  };
};
export var addCoins = function addCoins(amount) {
  return {
    type: ADD_COINS,
    payload: amount
  };
};
export var subtractCoins = function subtractCoins(amount) {
  return {
    type: SUBTRACT_COINS,
    payload: amount
  };
};
export var setLoyalty = function setLoyalty(balance) {
  return {
    type: SET_LOYALTY,
    payload: balance
  };
};
export var setPremium = function setPremium(balance) {
  return {
    type: SET_PREMIUM,
    payload: balance
  };
};
export var setLastWin = function setLastWin(amount) {
  return {
    type: SET_LAST_WIN,
    payload: amount
  };
};
export var setVideosAdsInfo = function setVideosAdsInfo(videoAdsInfo) {
  return {
    type: SET_VIDEO_ADS_INFO,
    payload: videoAdsInfo
  };
};
export var setTimeRemaining = function setTimeRemaining(time, feature) {
  return {
    type: SET_TIME_REMAINING,
    payload: {
      time: time,
      feature: feature
    }
  };
};
export var setDefaultValuesForAppsflyerLinks = function setDefaultValuesForAppsflyerLinks(validLink, iterationCount) {
  return {
    type: SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS,
    payload: {
      validLink: validLink,
      iterationCount: iterationCount
    }
  };
};
export var addLoyalty = function addLoyalty(value) {
  return {
    type: ADD_LOYALTY,
    payload: value
  };
};