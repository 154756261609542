import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from '@reduxjs/toolkit';
import { currentRunningMission } from '../../../screens/Missions/utils/missionsPerformance';
var sliceName = 'MISSIONS';
var initialState = {
  missionsFeature: {
    missionsGameplayStatus: null,
    isTutorialComplete: null
  },
  data: [],
  currentMission: {
    missionId: null,
    missionGameId: null,
    counterType: null,
    counterGoal: null,
    serverProgress: 0,
    clientProgress: 0,
    endTimestamp: null,
    goalMessage: null,
    missionStatus: null
  },
  showMissionsProgressBar: false,
  errorMsg: ''
};
var missionsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    missionsRequestError: function missionsRequestError(state, action) {
      state.errorMsg = action.payload;
    },
    missionsRequestDataSuccess: function missionsRequestDataSuccess(state, action) {
      state.missionsFeature = action.payload.missionsFeature;
      var missionsData = action.payload.missions;
      state.data = missionsData;
      var currentMission = currentRunningMission(missionsData);
      if (currentMission) {
        var _currentMission$count;
        state.currentMission = _objectSpread(_objectSpread({}, state.currentMission), {}, {
          missionStatus: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionStatus,
          missionId: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionId,
          missionGameId: currentMission !== null && currentMission !== void 0 && (_currentMission$count = currentMission.counter) !== null && _currentMission$count !== void 0 && (_currentMission$count = _currentMission$count.params) !== null && _currentMission$count !== void 0 && _currentMission$count.gameIds ? currentMission.counter.params.gameIds[0] : null,
          counterType: currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.counterType,
          counterGoal: currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.goal,
          serverProgress: currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.percentage,
          clientProgress: (currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.percentage) === 100 ? currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.percentage : state.currentMission.clientProgress,
          endTimestamp: currentMission === null || currentMission === void 0 ? void 0 : currentMission.endTimestamp,
          goalMessage: currentMission.counter.goalMessage
        });
      } else {
        state.currentMission = {
          missionId: null,
          missionGameId: null,
          counterType: null,
          counterGoal: null,
          serverProgress: 0,
          clientProgress: 0,
          endTimestamp: null,
          goalMessage: null,
          missionStatus: null
        };
      }
      state.showMissionsProgressBar = !!currentMission;
    },
    missionRequestActionStartMission: function missionRequestActionStartMission(state, action) {
      var _currentMission$count2;
      state.missionsFeature = action.payload.missionsFeature;
      var missionsData = action.payload.missions;
      state.data = missionsData;
      var currentMission = currentRunningMission(missionsData);
      if (currentMission) state.currentMission = _objectSpread(_objectSpread({}, state.currentMission), {}, {
        counterType: currentMission.counter.counterType,
        counterGoal: currentMission.counter.goal,
        endTimestamp: currentMission.endTimestamp,
        missionId: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionId,
        missionGameId: (_currentMission$count2 = currentMission.counter.params) !== null && _currentMission$count2 !== void 0 && _currentMission$count2.gameIds ? currentMission.counter.params.gameIds[0] : null,
        clientProgress: 0,
        serverProgress: 0,
        goalMessage: currentMission.counter.goalMessage,
        missionStatus: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionStatus
      });
      state.showMissionsProgressBar = !!currentMission;
    },
    missionRequestActionClaimReward: function missionRequestActionClaimReward(state, action) {
      var _currentMission$count3;
      state.missionsFeature = action.payload.missionsFeature;
      var missionsData = action.payload.missions;
      state.data = missionsData;
      var currentMission = currentRunningMission(missionsData);
      if (currentMission) state.currentMission = _objectSpread(_objectSpread({}, state.currentMission), {}, {
        counterType: currentMission.counter.counterType,
        counterGoal: currentMission.counter.goal,
        endTimestamp: currentMission.endTimestamp,
        missionId: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionId,
        missionGameId: (_currentMission$count3 = currentMission.counter.params) !== null && _currentMission$count3 !== void 0 && _currentMission$count3.gameIds ? currentMission.counter.params.gameIds[0] : null,
        clientProgress: (currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.percentage) > state.currentMission.clientProgress ? currentMission === null || currentMission === void 0 ? void 0 : currentMission.counter.percentage : state.currentMission.clientProgress,
        serverProgress: currentMission.counter.percentage,
        goalMessage: currentMission.counter.goalMessage,
        missionStatus: currentMission === null || currentMission === void 0 ? void 0 : currentMission.missionStatus
      });
      state.showMissionsProgressBar = !!currentMission;
    },
    missionsRequestActionsError: function missionsRequestActionsError(state, action) {
      state.errorMsg = action.payload;
    },
    setCurrentProgress: function setCurrentProgress(state, action) {
      state.currentMission = _objectSpread(_objectSpread({}, state.currentMission), {}, {
        clientProgress: action.payload
      });
    },
    setCleanCurrentMission: function setCleanCurrentMission(state) {
      state.currentMission = {
        missionId: null,
        missionGameId: null,
        counterType: null,
        counterGoal: null,
        serverProgress: 0,
        clientProgress: 0,
        endTimestamp: null,
        goalMessage: null,
        missionStatus: null
      };
    },
    setShowMissionsProgressBar: function setShowMissionsProgressBar(state, action) {
      state.showMissionsProgressBar = action.payload;
    }
  }
});
var actions = missionsSlice.actions,
  reducer = missionsSlice.reducer;
var missionsRequestError = actions.missionsRequestError,
  missionsRequestDataSuccess = actions.missionsRequestDataSuccess,
  missionsRequestActionsError = actions.missionsRequestActionsError,
  setCurrentProgress = actions.setCurrentProgress,
  setCleanCurrentMission = actions.setCleanCurrentMission,
  missionRequestActionStartMission = actions.missionRequestActionStartMission,
  missionRequestActionClaimReward = actions.missionRequestActionClaimReward,
  setShowMissionsProgressBar = actions.setShowMissionsProgressBar;
export { missionsRequestError, missionsRequestDataSuccess, missionsRequestActionsError, setCurrentProgress, setCleanCurrentMission, missionRequestActionStartMission, missionRequestActionClaimReward, setShowMissionsProgressBar };
export default reducer;