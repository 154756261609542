import React from 'react';
import styled from 'styled-components';

export default function SliderIndicator({ activeIndex, handleSelect, slides, eventsAssets }) {
  return (
    <IndicatorWrapper>
      {slides?.map((_, index) => (
        <IndicatorItem
          src={
            activeIndex === index
              ? eventsAssets.EVENTS_TUTORIAL_SLIDE_INDICATOR_SELECTED
              : eventsAssets.EVENTS_TUTORIAL_SLIDE_INDICATOR
          }
          key={index}
          onClick={() => handleSelect(index)}
        />
      ))}
    </IndicatorWrapper>
  );
}

const IndicatorWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const IndicatorItem = styled.img`
  margin: 0px;
  cursor: pointer;
  height: 40px;
`;

