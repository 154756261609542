import React from 'react';
import AutoForm from '../../../components/Form';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';

const ConfirmEmailStep = ({ submitHandler, serverResponse }) => {
  const cancelHandler = () => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
      [RouteParamConstants.PARAM_NAVIGATE_TO]: undefined,
      [RouteParamConstants.PARAM_UPGRADE_TYPE]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_SKU]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_TYPE]: undefined,
    });
  };

  return (
    <AutoForm
      layout={require(asset`linkCard.form`)}
      prev={cancelHandler}
      next={submitHandler}
      serverResponse={serverResponse}
      hideTopBar={true}
    />
  );
};

export default ConfirmEmailStep;
