import findRewardContentStyleSga from './findRewardContentStyleSga';
import findRewardContentStyleDefault from './findRewardContentStyleDefault';
import generateRender from 'shared/utils/generateRender';

const findRewardContentStyle = generateRender({
  default: findRewardContentStyleDefault,
  sga: findRewardContentStyleSga,
});

export default findRewardContentStyle;

