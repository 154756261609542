import CurrencyDisplay from './CurrencyDisplay';
import { connect } from '../../node_modules/react-redux';
import { shouldShowTooltip as _shouldShowTooltip, tooltipDetails as _tooltipDetails } from '../../state/actions/tooltip';
var mapStateToProps = function mapStateToProps(state) {
  return {
    showTooltip: state.tooltip.shouldShowTooltip
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    shouldShowTooltip: function shouldShowTooltip(value) {
      dispatch(_shouldShowTooltip(value));
    },
    tooltipDetails: function tooltipDetails(value) {
      dispatch(_tooltipDetails(value));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDisplay);