export default {
  COPY_TYPES: {
    AVAILABLE_BALANCE: 'AVAILABLE_BALANCE',
    MY_USER_ID: 'MY_USER_ID',
    MEMBER_ID: 'MEMBER_ID',
    SETTINGS_COPYRIGHT: 'SETTINGS_COPYRIGHT',
    VIP: 'VIP',
    PROMOTIONAL_EMAILS: 'PROMOTIONAL_EMAILS',
    WELCOME_EMAIL: 'WELCOME_EMAIL',
    COINS_NAME: 'COINS_NAME',
    COMPANY: 'COMPANY',
    WILDCARD: 'WILDCARD',
    REGISTER_CARD_OTP_CONSENTS: 'REGISTER_CARD_OTP_CONSENTS',
    LINK_CARD_OTP_CONSENTS: 'LINK_CARD_OTP_CONSENTS',
    LINK_CARD_TITLE: 'LINK_CARD_TITLE',
    LINK_CARD_SUBTITLE: 'LINK_CARD_SUBTITLE',
    LINK_CARD_SIGNUP: 'LINK_CARD_SIGNUP',
    LINK_CARD_SIGNUP_CTA: 'LINK_CARD_SIGNUP_CTA',
    LINK_ACCOUNT_SUBTITILE: 'LINK_ACCOUNT_SUBTITILE',
    LINK_ACCOUNT_DESCRIPTION: 'LINK_ACCOUNT_DESCRIPTION',
    TIER_TITLES: 'TIER_TITLES',
    CHALLENGE_PICKER_TEXT: 'CHALLENGE_PICKER_TEXT',
    LINK_ACCOUNT_ONBOARDING_TITLE: 'LINK_ACCOUNT_ONBOARDING_TITLE',
    LINK_ACCOUNT_ONBOARDING_SUBTITLE: 'LINK_ACCOUNT_ONBOARDING_SUBTITLE',
    ADBLOCK_TITLE: 'ADBLOCK_TITLE',
    ADBLOCK_DESCRIPTION: 'ADBLOCK_DESCRIPTION',
    GENERIC_LOGIN_ERROR_TITLE: 'GENERIC_LOGIN_ERROR_TITLE',
    GENERIC_LOGIN_ERROR_MESSAGE: 'GENERIC_LOGIN_ERROR_MESSAGE',
    FACEBOOK_LOGIN_ERROR: 'FACEBOOK_LOGIN_ERROR',
    FACEBOOK_ACCEPT_PERMISSION: 'FACEBOOK_ACCEPT_PERMISSION',
    GOOGLE_LOGIN_ERROR: 'GOOGLE_LOGIN_ERROR',
    GOOGLE_PLAY_SERVICE_ERROR: 'GOOGLE_PLAY_SERVICE_ERROR',
    APPLE_LOGIN_ERROR: 'APPLE_LOGIN_ERROR',
    APPLE_LOGIN_UPDATE_ERROR: 'APPLE_LOGIN_UPDATE_ERROR',
    REWARD_CENTER_PROFILE: 'REWARD_CENTER_PROFILE',
    PROPERTY_SELECTOR: 'PROPERTY_SELECTOR',
    TERMS_UPDATE_SCREEN_TEXT: 'TERMS_UPDATE_SCREEN_TEXT',
    PUSH_NOTIFICATION_TIME_BONUS_READY: 'PUSH_NOTIFICATION_TIME_BONUS_READY',
    PUSH_NOTIFICATION_TIME_BONUS_LOGIN: 'PUSH_NOTIFICATION_TIME_BONUS_LOGIN',
    PUSH_NOTIFICATION_CHALLENGE_ENDS: 'PUSH_NOTIFICATION_CHALLENGE_ENDS',
    PUSH_NOTIFICATION_CHALLENGE_FILL: 'PUSH_NOTIFICATION_CHALLENGE_FILL',
    PUSH_NOTIFICATION_TIME_RUNNING_OUT: 'PUSH_NOTIFICATION_TIME_RUNNING_OUT',
    PUSH_NOTIFICATION_SPIN_AND_WIN: 'PUSH_NOTIFICATION_SPIN_AND_WIN',
    FAVORITES_REQUIRED_LEVEL: 'FAVORITES_REQUIRED_LEVEL'
  }
};