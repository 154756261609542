import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { EVENT_DOMAINS } from './domains';

/**
 * EVENT_WIDGETS defines a specific interactive element of the current domain
 */
export var EVENT_WIDGETS = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, EVENT_DOMAINS.GENERAL, {
  MISSIONS_WIDGET: 'MISSIONS_ICON',
  TUTORIAL_SKIP: 'TUTORIAL_SKIP',
  TUTORIAL_NEXT_STEP: 'TUTORIAL_NEXT_STEP',
  EVENTS_WIDGET: 'EVENTS_ICON',
  DISMISS_BUTTON: 'DISMISS_BUTTON',
  ACCEPT_BUTTON: 'ACCEPT_BUTTON'
}), EVENT_DOMAINS.MISSIONS, {
  TUTORIAL_SKIP: 'TUTORIAL_SKIP',
  TUTORIAL_NEXT_STEP: 'TUTORIAL_NEXT_STEP',
  MISSION_CARD_REWARD_CLAIM_BUTTON: 'MISSION_CARD_REWARD_CLAIM_BUTTON',
  MISSIONS_INFO_BUTTON: 'MISSIONS_INFO_BUTTON'
}), EVENT_DOMAINS.ACTIONS_COMMANDS, {
  SIGN_IN_EMAIL_BUTTON: 'SIGN_IN_EMAIL_BUTTON',
  SIGN_IN_FACEBOOK_BUTTON: 'SIGN_IN_FACEBOOK_BUTTON',
  SIGN_IN_GOOGLE_BUTTON: 'SIGN_IN_GOOGLE_BUTTON',
  SIGN_IN_APPLE_BUTTON: 'SIGN_IN_APPLE_BUTTON',
  ACTIONS_COMMANDS_TOOLTIP: 'ACTIONS_COMMANDS_TOOLTIP'
}), EVENT_DOMAINS.EVENTS, {
  EVENTS_CARD: 'EVENTS_CARD',
  EVENTS_INFO_BUTTON: 'EVENTS_INFO_BUTTON',
  EVENTS_TOOLTIP: 'EVENTS_TOOLTIP',
  EVENTS_SKIP_BUTTON: 'EVENTS_SKIP_BUTTON'
});