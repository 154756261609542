module.exports = {
  dimensions: {
    width: 43,
    height: 33
  },
  optical: {
    top: 0,
    left: 2,
    right: 2,
    bottom: 1
  }
};