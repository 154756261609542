import AdmiralLinkToStore from './AdmiralLinkToStore';
import AdmiralEsLinkToStore from './AdmiralEsLinkToStore';
import DefaultLinkToStore from './DefaultLinkToStore';
import generateRender from 'shared/utils/generateRender';

const LinkToStore = generateRender({
  default: DefaultLinkToStore,
  admiral: AdmiralLinkToStore,
  admes: AdmiralEsLinkToStore,
});

export default LinkToStore;

