export default {
  type: 'object',
  properties: {
    missionsFeatureState: {
      type: 'object',
      properties: {
        missionsFeature: {
          type: 'object',
          properties: {
            assetsPackageId: {
              type: 'string'
            },
            endTimestamp: {
              type: 'number',
              nullable: true
            },
            isTutorialComplete: {
              type: 'boolean'
            },
            missionsFeatureId: {
              type: 'number'
            },
            missionsGameplayStatus: {
              type: 'string'
            },
            startTimestamp: {
              type: 'number',
              nullable: true
            }
          }
        },
        missions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              counter: {
                type: 'object',
                properties: {
                  counterType: {
                    type: 'string'
                  },
                  goal: {
                    type: 'number'
                  },
                  params: {
                    type: 'object',
                    nullable: true,
                    properties: {
                      gameIds: {
                        type: 'array',
                        items: {
                          type: 'number'
                        }
                      }
                    }
                  },
                  percentage: {
                    type: 'number'
                  },
                  value: {
                    type: 'number'
                  }
                }
              },
              endTimestamp: {
                type: 'number'
              },
              missionId: {
                type: 'number'
              },
              missionStatus: {
                type: 'string'
              },
              missionType: {
                type: 'string'
              },
              reward: {
                type: 'object',
                properties: {
                  rewardItems: {
                    type: 'array',
                    items: {
                      type: 'object',
                      required: ['rewardType'],
                      properties: {
                        isRevealed: {
                          type: 'boolean'
                        },
                        rewardDeliveryType: {
                          type: 'string'
                        },
                        rewardType: {
                          type: 'string'
                        },
                        rewardValue: {
                          anyOf: [{
                            type: 'number'
                          }, {
                            type: 'string'
                          }, {
                            type: 'object',
                            properties: {
                              rewardItems: {
                                type: 'array',
                                items: {
                                  type: 'object',
                                  properties: {
                                    rewardType: {
                                      type: 'string'
                                    },
                                    rewardValue: {
                                      type: 'number'
                                    }
                                  }
                                }
                              }
                            }
                          }]
                        }
                      }
                    }
                  }
                }
              },
              startTimestamp: {
                type: 'number'
              }
            }
          }
        }
      }
    }
  }
};