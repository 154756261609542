var FormId = require("../../../components/Form/FormId").default;
var FormType = require("../../../components/Form/FormType").default;
var KeyboardHint = require("../../../components/Form/KeyboardHint").default;
var InputValidator = require("../../../components/Form/InputValidator").default;
module.exports = {
  header: {
    title: "Confirm Email",
    backButtonText: ""
  },
  body: {
    subheading1: "",
    subheading2: ""
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.EMAIL,
      label: "Email",
      inlineText: "Must be between 8 - 60 characters and only these special characters @ - _ .+",
      type: FormType.TEXT,
      keyboardHint: KeyboardHint.EMAIL,
      validator: InputValidator.EMAIL,
      descriptions: "",
      inputStyle: true
    }],
    submitButtonText: "Submit"
  }]
};