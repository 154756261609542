import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SImageBoundsWrapper from 'shared/components/ImageBoundsWrapper';
import Measure from 'react-measure';

export default class ImageBoundsWrapper extends Component {
  static propTypes = {
    source: PropTypes.node.isRequired,
    ibw: PropTypes.object.isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    parent: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  };

  state = {
    width: -1,
    height: -1,

    innerWidth: 0,
    innerHeight: 0,
  };

  preloadImage(source, loaded = () => {}) {
    const img = new Image();
    img.onload = loaded;
    img.src = source;
  }

  // componentDidCatch() {}
  // componentDidUpdate(props) {}
  componentDidMount() {
    const { source, innerProps } = this.props;
    if (innerProps && innerProps.onLoadEnd) {
      this.preloadImage(source, innerProps.onLoadEnd);
    }
  }
  // componentWillMount() {}
  // componentWillReceiveProps() {}
  // componentWillUnmount() {}
  // componentWillUpdate() {}

  render() {
    const {
      style,
      innerStyle,
      innerProps = {},
      manualContentAdjustment = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      className,
      innerClassName,
      onClick,
      drawOverChild,
      hidden,
      customRef,
      widthScale = 1,
      imageStyle,
      ...rest
    } = this.props;

    const { onLoadEnd, ...restInnerProps } = innerProps;

    const om = 1;

    return (
      <SImageBoundsWrapper
        navigation={this.props.navigation}
        height={this.state.height}
        width={this.state.width}
        opticalMultiplier={om}
        {...rest}
        render={
          /**
           * @param {Object} args
           * @param {Object} args.optical
           * @param {Number} args.optical.offsetTop
           * @param {Number} args.optical.offsetLeft
           * @param {Object} args.optical.dimensions
           * @param {Number} args.optical.dimensions.top
           * @param {Number} args.optical.dimensions.left
           * @param {Number} args.optical.dimensions.right
           * @param {Number} args.optical.dimensions.bottom
           * @param {Object} args.nine
           * @param {Object} args.nine
           * @param {Number} args.nine.top
           * @param {Number} args.nine.left
           * @param {Number} args.nine.right
           * @param {Number} args.nine.bottom
           * @param {Number} args.content.top
           * @param {Number} args.content.left
           * @param {Number} args.content.right
           * @param {Number} args.content.bottom
           * @param {Object} args.layout
           * @param {Number} args.layout.width
           * @param {Number} args.layout.height
           * @param {Node} args.source
           */
          args => (
            <>
              <Measure
                bounds
                onResize={contentRect => {
                  if (this.props.ignoreSizeTransforms && contentRect.entry) {
                    this.setState({
                      height: contentRect.entry.height,
                      width: contentRect.entry.width,
                    });
                    return;
                  }

                  this.setState({
                    height: contentRect.bounds.height,
                    width: contentRect.bounds.width,
                  });
                }}
              >
                {({ measureRef }) => (
                  <div
                    ref={measureRef}
                    style={{ position: this.props.position || 'relative', ...style }}
                    className={className}
                    onClick={onClick}
                  >
                    {this.state.width !== -1 && this.state.height !== -1 && (
                      <>
                        {drawOverChild &&
                          this.renderChild(args, innerStyle, innerClassName, manualContentAdjustment, onClick)}
                        {args.nine && (
                          <div
                            style={{
                              position: 'absolute',
                              top: args.optical.offsetTop,
                              left: args.optical.offsetLeft,
                              height: args.layout.height,
                              width: args.layout.width,

                              borderImageSource: !hidden ? `url(${args.source})` : '',
                              borderImageSlice: `${args.nine.top} ${args.nine.right} ${args.nine.bottom} ${args.nine.left} fill`,
                              borderImageWidth: `${args.nine.top * widthScale}px ${args.nine.right * widthScale}px ${
                                args.nine.bottom * widthScale
                              }px ${args.nine.left * widthScale}px`,
                              borderImageOutset: '0px',
                              borderImageRepeat: 'stretch',
                              borderStyle: 'solid',

                              pointerEvents: 'none',
                            }}
                          />
                        )}
                        {!args.nine && (
                          <img
                            ref={customRef}
                            src={args.source}
                            alt="Bounds"
                            style={{
                              position: 'absolute',
                              top: args.optical.offsetTop,
                              left: args.optical.offsetLeft,
                              height: args.layout.height,
                              width: args.layout.width,
                              pointerEvents: 'none',
                              objectFit: style.objectFit || 'contain',
                              ...imageStyle,
                            }}
                            {...restInnerProps}
                          />
                        )}
                        {!drawOverChild &&
                          this.renderChild(args, innerStyle, innerClassName, manualContentAdjustment, onClick)}
                      </>
                    )}
                  </div>
                )}
              </Measure>
            </>
          )
        }
      />
    );
  }

  renderChild = (args, innerStyle, innerClassName, manualAdjustment, onClick) => {
    if (!this.props.children) return null;

    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({
            innerHeight: contentRect.bounds.height,
            innerWidth: contentRect.bounds.width,
          });
        }}
      >
        {({ measureRef }) => {
          return (
            <div
              ref={measureRef}
              style={{
                position: 'absolute',
                top: args.content.top - args.optical.dimensions.top + manualAdjustment.top,
                left: args.content.left - args.optical.dimensions.left + manualAdjustment.left,
                right: args.content.right - args.optical.dimensions.right + manualAdjustment.right,
                bottom: args.content.bottom - args.optical.dimensions.bottom + manualAdjustment.bottom,
                ...innerStyle,
              }}
              className={innerClassName}
            >
              {typeof this.props.children === 'function' &&
                this.props.children({
                  width: this.state.innerWidth,
                  height: this.state.innerHeight,
                })}
              {typeof this.props.children !== 'function' && this.props.children}
            </div>
          );
        }}
      </Measure>
    );
  };
}
