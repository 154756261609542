import { SET_FORM_INFO, CLEAR_FORM_INFO } from './types';
export var setFormInfo = function setFormInfo(info) {
  return {
    type: SET_FORM_INFO,
    key: info.key,
    value: info.value,
    validated: info.validated
  };
};
export var clearFormInfo = function clearFormInfo() {
  return {
    type: CLEAR_FORM_INFO
  };
};