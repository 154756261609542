module.exports = {
  dimensions: {
    width: 70,
    height: 70
  },
  optical: {
    top: 15,
    left: 15,
    right: 15,
    bottom: 15
  }
};