import { SET_FIRST_TIME_VISIT_VIP_ROOM, SET_FIRST_TIME_FAVOURITE_HELPER, MARK_ONBOARDING_SEEN, SET_ONBOARDING_UNLOCK_LEVEL } from './types';
import { store } from '../store';
export var setFirstTimeVisitVipRoom = function setFirstTimeVisitVipRoom(value) {
  return {
    type: SET_FIRST_TIME_VISIT_VIP_ROOM,
    payload: value
  };
};
export var setFirstTimeShowFavouriteHelper = function setFirstTimeShowFavouriteHelper(value) {
  return {
    type: SET_FIRST_TIME_FAVOURITE_HELPER,
    payload: value
  };
};
export var markOnboardingSeen = function markOnboardingSeen(onboarding) {
  return {
    type: MARK_ONBOARDING_SEEN,
    payload: {
      playerId: store.getState().playerInfo.playerId,
      onboarding: onboarding
    }
  };
};
export var setOnboardingUnlockLevel = function setOnboardingUnlockLevel(onboarding, level) {
  return {
    type: SET_ONBOARDING_UNLOCK_LEVEL,
    payload: {
      onboarding: onboarding,
      level: level
    }
  };
};