module.exports = {
  dimensions: {
    width: 40,
    height: 96
  },
  nine: {
    top: 40,
    left: 40,
    right: 40,
    bottom: 40
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  }
};