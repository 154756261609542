import React from 'react';
import AutoFormInput from '../../../../components/AutoFormInput';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SFormInputs from 'shared/screens/LinkAccountForm/components/FormInputs';
import { styles } from '../..//styles';

const TiFormInputs = ({ handleInputChange, formValues }) => {
  return (
    <SFormInputs
      render={_ => {
        return (
          <>
            <div style={styles.inputRow}>
              <div style={{ flex: 1 }}>
                <AutoFormInput
                  emitInput={handleInputChange('accountNumber')}
                  value={formValues.accountNumber}
                  label=" "
                  placeholder={`${getText(TEXT_KEY.LOYALTY_CARD_TITLE)} ${getText(TEXT_KEY.NUMBER)}`}
                  type="text"
                  customClassName="transparentInput"
                  customLabelStyle={styles.customInputLabel}
                  trimInput={true}
                />
              </div>
            </div>
            <div style={styles.inputRow}>
              <div style={{ flex: 1 }}>
                <AutoFormInput
                  emitInput={handleInputChange('pin')}
                  value={formValues.pin}
                  label=" "
                  placeholder={getText(TEXT_KEY.PIN)}
                  type="text"
                  customClassName="transparentInput"
                  customLabelStyle={styles.customInputLabel}
                />
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default TiFormInputs;

