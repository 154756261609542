export default {
  type: 'object',
  properties: {
    result: {
      type: 'object',
      properties: {
        progressiveJackpots: {
          type: 'array',
          items: [{
            type: 'object',
            properties: {
              gameId: {
                type: 'integer'
              },
              displayValue: {
                type: 'string'
              }
            }
          }]
        }
      },
      required: ['progressiveJackpots']
    }
  },
  required: ['result']
};