import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { StoreLink } from '../../styledComponents';

export default function AdmiralLinkToStore({ storeLink }) {
  return (
    <StoreLink href={storeLink}>
      {'Verfügbar in '}
      {isIOS ? 'App Store' : isAndroid ? 'Google Play' : 'Nicht verfügbar'}
    </StoreLink>
  );
}

