import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import 'react-day-picker/lib/style.css';
import SAutoFormInput from 'shared/components/AutoFormInput';
import AutoComplete from 'react-autocomplete';
import DatePicker from '../DatePicker';
import PasswordInput from '../PasswordInput';
import KeyboardHint from 'shared/components/Form/KeyboardHint';
import FormType from 'shared/components/Form/FormType';
import { trimInputString } from 'shared/utils/string';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class AutoFormInput extends Component {
  state = {};

  generateForm = (
    type,
    formChangeHandler,
    label,
    value,
    keyboardHint,
    options,
    formFocusHandle,
    formBlurHandle,
    autoCompleteStates,
    maxChar,
    multiline,
    rows,
    customClassName,
    customStyle = {},
    placeholder,
    disabled,
    customPeeker,
    id,
    name = '',
    trimInput
  ) => {
    if (!keyboardHint) {
      keyboardHint = KeyboardHint.DEFAULT;
    }
    switch (type) {
      case FormType.TEXT:
        return (
          <Form.Control
            className={customClassName ? customClassName : 'form-input-text'}
            style={customStyle}
            type={keyboardHint}
            onFocus={formFocusHandle}
            onBlur={formBlurHandle}
            onChange={e => formChangeHandler(trimInput ? trimInputString(e.target?.value) : e.target?.value)}
            value={value}
            maxLength={maxChar}
            id={id && id}
            placeholder={placeholder}
            as={multiline ? 'textarea' : 'input'}
            rows={multiline ? rows : 0}
            disabled={disabled}
            name={name}
          />
        );
      case FormType.PASSWORD:
        return (
          <PasswordInput
            className={customClassName ? customClassName : 'form-input-text'}
            style={customStyle}
            customPeekerStyle={customPeeker}
            onFocus={formFocusHandle}
            onBlur={formBlurHandle}
            onChange={formChangeHandler}
            value={value}
            placeholder={placeholder}
          />
        );
      case FormType.TOGGLE:
        return (
          <div
            className="form-input-toggle"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <Form.Check className="switch">
              <input type="checkbox" checked={value} readOnly />
              <span className="slider" onClick={formChangeHandler} />
            </Form.Check>
            <Form.Label className="form-input-label-toggle">{this.parseLinkInLabelCustom(label)}</Form.Label>
          </div>
        );
      case FormType.CHECKBOX:
        return (
          <div className="form-input-checkbox">
            <Form.Check>
              <input type="checkbox" checked={value} onChange={formChangeHandler} />
              <span className="slider" onClick={formChangeHandler} />
            </Form.Check>
            <Form.Label className="form-input-label-toggle">{this.parseLinkInLabelCustom(label)}</Form.Label>
          </div>
        );
      case FormType.DROPDOWN:
        let optionElements;
        optionElements = options.map(option => {
          return (
            <option key={option.value} className="form-input-options" value={option.value}>
              {option.label}
            </option>
          );
        });

        return (
          <Form.Control
            className={customClassName ? customClassName : 'form-input-text'}
            style={customStyle}
            as="select"
            onChange={e => {
              if (this.props.getItemValue) {
                this.props.getItemValue(e);
              }
              formChangeHandler(e);
            }}
            value={value}
            disabled={disabled}
            id={id && id}
          >
            {placeholder && (
              <option disable hidden value="">
                {placeholder}
              </option>
            )}

            {optionElements}
          </Form.Control>
        );
      case FormType.DATE_PICKER:
        return (
          <DatePicker date={value} onDateChange={formChangeHandler} onFocus={formFocusHandle} onBlur={formBlurHandle} customStyle={customStyle}/>
        );
      case FormType.AUTOCOMPLETE:
        return (
          <AutoComplete
            wrapperStyle={{ display: 'block' }}
            items={autoCompleteStates.autoCompleteResults}
            value={value}
            getItemValue={item => item.suggestion}
            inputProps={{
              className: 'form-control form-input-text' + (autoCompleteStates.addressError ? ' formInputError' : ''),
              style: customStyle,
              disabled: autoCompleteStates.submitPending,
              onFocus: formFocusHandle,
              onBlur: formBlurHandle,
              placeholder: placeholder,
            }}
            onSelect={formChangeHandler}
            onChange={formChangeHandler}
            renderMenu={children => <div className="AutoCompleteMenu">{children}</div>}
            renderItem={(item, isHighlighted) => (
              <div
                className={`AutoCompleteMenuItem ${isHighlighted ? 'AutoCompleteMenuItemHighlighted' : ''}`}
                key={item.suggestion}
              >
                {item.suggestion}
              </div>
            )}
          />
        );
      default:
        console.error('Unsupported Input Type');
        break;
    }
  };

  parseLinkInLabelCustom = label => {
    const regex = new RegExp(/(?:__|[*#])|(\[.+?\]\(.+?\))/g);
    const textArray = label.split(regex);
    return textArray.map(str => {
      if (regex.test(str)) {
        const linkArray = str.split(/(?:__|[*#])|\[(.+?)\]\((.+?)\)/).filter(x => x);
        return (
          <a
            className="form-hyperlink"
            key={linkArray[0]}
            href={linkArray[1]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkArray[0]}
          </a>
        );
      }
      return str;
    });
  };

  render() {
    return (
      <SAutoFormInput
        {...this.props}
        render={args => {
          const {
            type,
            label,
            keyboardHint,
            status,
            value,
            focused,
            descriptions,
            formChangeHandler,
            formFocusHandle,
            formBlurHandle,
            autoCompleteStates,
            toggleAutocompleteMode,
            options,
            maxChar,
            multiline,
            rows,
            containerStyle,
            customClassName,
            customStyle,
            customLabel,
            placeholder,
            disabled,
            customPeeker,
            id,
            name,
            trimInput,
          } = args;

          return (
            <Form.Group
              className={`form-input-group ${type === FormType.AUTOCOMPLETE ? 'full-width' : ''}`}
              style={(!!containerStyle && containerStyle) || {}}
            >
              {(type === FormType.TEXT ||
                type === FormType.PASSWORD ||
                type === FormType.DATE_PICKER ||
                type === FormType.DROPDOWN ||
                type === FormType.AUTOCOMPLETE) && (
                <Form.Label
                  className={`${customLabel ? customLabel : 'form-input-label'} ${
                    focused || type === FormType.DATE_PICKER || type === FormType.DROPDOWN ? 'focused' : ''
                  }`}
                >
                  {label && this.parseLinkInLabelCustom(label)}
                </Form.Label>
              )}

              {this.props.hasAutocompleteToggle &&
                type === FormType.AUTOCOMPLETE &&
                autoCompleteStates.manualAddressThresholdReached && (
                  <span className="form-text-toggle" onClick={toggleAutocompleteMode}>
                    {getText(TEXT_KEY.ADDRESS_MANUALLY)}
                  </span>
                )}
              {this.props.hasAutocompleteToggle && type !== FormType.AUTOCOMPLETE && (
                <span className="form-text-toggle" onClick={toggleAutocompleteMode}>
                  {getText(TEXT_KEY.SEARCH_ADDRESSES)}
                </span>
              )}

              {this.generateForm(
                type,
                formChangeHandler,
                label,
                value,
                keyboardHint,
                options,
                formFocusHandle,
                formBlurHandle,
                autoCompleteStates,
                maxChar,
                multiline,
                rows,
                customClassName,
                customStyle,
                placeholder,
                disabled,
                customPeeker,
                id,
                name,
                trimInput
              )}
              {status != '' && <Form.Text className="form-input-status">{status}</Form.Text>}
              <Form.Text className="form-input-descriptions">{descriptions}</Form.Text>
            </Form.Group>
          );
        }}
      />
    );
  }
}
