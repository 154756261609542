import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'SPIN_EVENT';
var initialState = {
  playerId: null,
  createdTimestamp: null,
  spinPhase: null,
  gameId: null,
  amount: null,
  balanceBefore: null,
  balanceAfter: null
};
var spinEventSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setSpinEvent: function setSpinEvent(state, action) {
      state.playerId = action.payload.playerId || state.playerId;
      state.createdTimestamp = action.payload.createdTimestamp || state.createdTimestamp;
      state.spinPhase = action.payload.spinPhase || state.spinPhase;
      state.gameId = action.payload.gameId || state.gameId;
      state.amount = action.payload.amount || state.amount;
      state.balanceBefore = action.payload.balanceBefore || state.balanceBefore;
      state.balanceAfter = action.payload.balanceAfter || state.balanceAfter;
    },
    clearSpinEvent: function clearSpinEvent(state) {
      state.playerId = null;
      state.createdTimestamp = null;
      state.spinPhase = null;
      state.gameId = null;
      state.amount = null;
      state.balanceBefore = null;
      state.balanceAfter = null;
    }
  }
});
var actions = spinEventSlice.actions,
  reducer = spinEventSlice.reducer;
var setSpinEvent = actions.setSpinEvent,
  clearSpinEvent = actions.clearSpinEvent;
export { setSpinEvent, clearSpinEvent };
export default reducer;