import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_FIRST_TIME_VISIT_VIP_ROOM, SET_FIRST_TIME_FAVOURITE_HELPER, MARK_ONBOARDING_SEEN, SET_ONBOARDING_UNLOCK_LEVEL } from '../actions/types';
var initialState = {
  vipRoom: true,
  hasSeenChallengeOnboard: {},
  onboardingsSeen: [],
  onboardingsUnlockLevels: {}
};
var ftueReducer = function ftueReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_FIRST_TIME_VISIT_VIP_ROOM:
      return _objectSpread(_objectSpread({}, state), {}, {
        vipRoom: action.payload
      });
    case SET_FIRST_TIME_FAVOURITE_HELPER:
      return _objectSpread(_objectSpread({}, state), {}, {
        showFavouriteHelper: action.payload
      });
    case MARK_ONBOARDING_SEEN:
      /**
       * In order to ensure multiple users on the same device/PC see onboardings, we
       * store the onboardings the player has seen by player ID (persistent in local storage).
       * Up to 10 new players on the same devise will see onboardings (once the max is
       * exceeded, the lowest playerID is removed from the list to make room for the new one).
       */

      // Get the array of onboardings seen for this current player or create an empty array if not found.
      var playerOnboardingsArray = state.onboardingsSeen[action.payload.playerId] || [];
      // push the latest onboarding seen into the array
      playerOnboardingsArray.push(action.payload.onboarding);

      // Get the length of how many players are stored, if at 10 then delete lowest player ID
      var playerIdsArray = Object.keys(state.onboardingsSeen);
      if (playerIdsArray.length === 10) {
        delete state.onboardingsSeen[Math.min.apply(Math, playerIdsArray)];
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        onboardingsSeen: _objectSpread(_objectSpread({}, state.onboardingsSeen), {}, _defineProperty({}, action.payload.playerId, playerOnboardingsArray))
      });
    case SET_ONBOARDING_UNLOCK_LEVEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        onboardingsUnlockLevels: _objectSpread(_objectSpread({}, state.onboardingsUnlockLevels), {}, _defineProperty({}, action.payload.onboarding, action.payload.level))
      });
    default:
      return state;
  }
};
export default ftueReducer;