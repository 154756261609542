import React from 'react';
import { styles } from '../../../../styles';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import AutoFormInput from '../../../../../../components/AutoFormInput';
import getValuesArray from 'shared/utils/getValuesArray';

const DefaultDOBInputs = ({ handleInputChange, formValues }) => {
  return (
    <div style={styles.inputRow}>
      <AutoFormInput
        customClassName="transparentInput"
        emitInput={handleInputChange('year')}
        value={formValues.year}
        label=""
        type="text"
        placeholder={getText(TEXT_KEY.YEAR)}
        containerStyle={styles.inputContainer}
      />
      <AutoFormInput
        customClassName="transparentInput SelectBoxInput"
        emitInput={handleInputChange('month')}
        value={formValues.month}
        label=""
        type="dropdown"
        placeholder={getText(TEXT_KEY.MONTH)}
        options={getValuesArray(12)}
        containerStyle={styles.inputContainer}
      />
      <AutoFormInput
        customClassName="transparentInput SelectBoxInput"
        emitInput={handleInputChange('day')}
        value={formValues.day}
        label=""
        type="dropdown"
        options={getValuesArray(31)}
        placeholder={getText(TEXT_KEY.DAY)}
        containerStyle={styles.inputContainer}
      />
    </div>
  );
};

export default DefaultDOBInputs;

