module.exports = {
  dimensions: {
    width: 39,
    height: 22
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};