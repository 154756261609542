import React from 'react';
import styled from 'styled-components';

export default function MissionCardsTimerDangerText({ style, string, className }) {
  return (
    <StringText theme={style} className={className}>
      {string}
    </StringText>
  );
}

const StringText = styled.div`
  ${({ theme }) => theme};
  color: #fae465;
  font-weight: 800;
  -webkit-text-stroke: 1.3px #9d2315;
  text-stroke: 1.3px #9d2315;
`;

