import React, { Component } from 'react';
import TextComponent from './TextComponents';
import STimeRemainingText from 'shared/components/TimeRemainingText';
import { counterTypes } from 'shared/utils/TimestampManager';
import { missionInDangerZone } from 'shared/utils/missionProgress/tooltipsDetail';

export default class TimeRemainingText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isDangerTooltipSeen = false;
  }

  showMissionDangerTooltip = (shouldShowTooltip, setTolltipDetail) => {
    if (!this.isDangerTooltipSeen) {
      const detail = missionInDangerZone();
      setTolltipDetail(detail);
      shouldShowTooltip(true);
      setTimeout(() => {
        let out = { ...detail, animationState: 'AnimationSlideToTop' };
        setTolltipDetail(out);
      }, 3000);
      this.isDangerTooltipSeen = true;
    }
  };

  render() {
    return (
      <STimeRemainingText
        navigation={this.props.navigation}
        inNavBar={this.props.inNavBar}
        {...this.props}
        render={args => {
          const {
            string,
            isTimeInDanger,
            shouldShowTooltip,
            tooltipDetails,
            modal,
            showMissionsProgressBar,
            activeTutorial,
          } = args;
          const { style, counterType, inNavBar, className, strokeColor } = this.props;

          const textProps = { string, style, isTimeInDanger, inNavBar, counterType, className, strokeColor };

          if (!activeTutorial && isTimeInDanger && inNavBar && !modal && showMissionsProgressBar) {
            counterType === counterTypes.MISSION_CARD &&
              this.showMissionDangerTooltip(shouldShowTooltip, tooltipDetails);
          }
          return <TextComponent {...textProps} />;
        }}
      />
    );
  }
}

