import DefaultPurchaseFlow from './DefaultPurchaseFlow';
import TiPurchaseFlow from './TiPurchaseFlow';
import StarPurchaseFlow from './StarPurchaseFlow';
import generateRender from 'shared/utils/generateRender';

const PurchaseFlow = generateRender({
  default: DefaultPurchaseFlow,
  ti: TiPurchaseFlow,
  star: StarPurchaseFlow,
});

export default PurchaseFlow;

