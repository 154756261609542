import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const AccountCintainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const PlayerInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 3rem 1.3rem 3rem;
  align-items: center;
  width: 90%;
  height: 30%;
  font-size: 1.45rem;
  font-weight: 600;
`;

export const PlayerInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EditInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 6rem;
  justify-content: center;
  align-items: center;
`;

export const EditInfoWrapper = styled(PlayerInfoWrapper)`
  flex: 1;
  width: auto;
  padding: 0.7rem 0px 1.3rem 0px;
  margin: 0px 7px;
`;

export const PromotionWrapper = styled(PlayerInfoWrapper)`
  padding-right: 5%;
  width: 100%;
  justify-content: flex-end;
`;

export const EditTitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 12rem;
  flex-grow: 1;
  overflow-wrap: break-word;
  margin-right: 5px;
`;

export const LabelHolder = styled.span`
  ${({ theme }) => theme}
  font-size: ${({ isPassword }) => (isPassword ? '1.2rem' : '1.3rem')};
  color: ${({ isPassword }) => (isPassword ? theme.palette.common[1] : theme.palette.common[37])};
  text-align: center;
`;

export const PasswordSubtitle = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${theme.palette.primary[20]};
`;

export const LabelValue = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${theme.palette.common[1]};
`;

export const DividerWrapper = styled.div`
  width: 100%;
  height: 2rem;
  z-index: 500;
`;

export const PromotionSwitchHolder = styled.div`
  margin-left: 15px;
`;

export const CustomSwitch = styled.label`
  margin-bottom: 0px;
`;

export const LinkAccountDividerWrapper = styled(DividerWrapper)`
  margin-top: 1rem;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
`;

export const LinkAccountRowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 90px;
  margin-bottom: 0.2rem;
  ${({ styled }) => styled}
`;

export const LinkImageButton = styled.img`
  width: 250px;
  height: 42px;
  cursor: pointer;
  ${({ styled }) => styled}
`;

export const PromotioSwitchText = styled.span`
  ${({ theme }) => theme}
  font-size: 1.2rem;
  color: ${({ enabled }) => (enabled ? theme.palette.common[37] : theme.palette.common[34])};
`;

export const ImageButtonWrapper = styled.div`
  opacity: ${({ lock }) => (lock ? 0.5 : 1)};
`;

