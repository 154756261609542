import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useRewardCenterHistory from 'shared/screens/RewardCenter/hooks/useRewardCenterHistory';
import HistoryList from './components/HistoryList';
import ActivityIndicator from '../../../../components/ActivityIndicator/ActivityIndicator';

const RewardCenterHistory = ({ setActiveHistoryItem, rewardCenterAssets }) => {
  const { history, isLoading } = useRewardCenterHistory();

  return (
    <div className="RewardCenterHistory">
      {isLoading && <ActivityIndicator />}
      {!isLoading &&
        (history && history.length > 0 ? (
          <HistoryList
            history={history}
            setActiveHistoryItem={setActiveHistoryItem}
            rewardCenterAssets={rewardCenterAssets}
          />
        ) : (
          <div className="RewardCenterHistoryEmpty">{getText(TEXT_KEY.NO_OFFERS_REDEEMED_YET)}</div>
        ))}
    </div>
  );
};

export default RewardCenterHistory;

