export var MISSIONS_EVENT_TYPES = {
  ENTER_MISSIONS_SCREEN: 'ENTER_MISSIONS_SCREEN',
  START_MISSIONS_TUTORIAL: 'START_MISSIONS_TUTORIAL',
  MISSIONS_TUTORIAL_NEXT_STEP: 'MISSIONS_TUTORIAL_NEXT_STEP',
  DISPLAY_MISSIONS_TUTORIAL_STEP: 'DISPLAY_MISSIONS_TUTORIAL_STEP',
  DISPLAY_MISSIONS_ICON: 'DISPLAY_MISSIONS_ICON',
  MISSIONS_TUTORIAL_SKIP: 'MISSIONS_TUTORIAL_SKIP',
  MISSIONS_TUTORIAL_COMPLETE: 'MISSIONS_TUTORIAL_COMPLETE',
  MISSIONS_CARD_REWARD_CLAIM: 'MISSIONS_CARD_REWARD_CLAIM',
  MISSIONS_OPEN_INFO_SCREEN: 'MISSIONS_OPEN_INFO_SCREEN'
};