import Base from './index';
import Greentube from './Greentube';
import Abzorba from './Abzorba';
import Ainsworth from './Ainsworth';
import Spingames from './Spingames';
import Konami from './Konami';
import Everi from './Everi.js';
import NetEnt from './NetEnt.js';
import Agsi from './Agsi.js';
export default {
  greentube: Greentube,
  spingames: Spingames,
  abzorba: Abzorba,
  ainsworth: Ainsworth,
  konami_rgs: Konami,
  everi_rgs: Everi,
  netent: NetEnt,
  agsi: Agsi
};
export var providersList = {
  GREENTUBE: 'greentube',
  SPINGAMES: 'spingames',
  ABZORBA: 'abzorba',
  AINSWORTH: 'ainsworth',
  KONAMI: 'konami_rgs',
  EVERI: 'everi_rgs',
  NETENT: 'netent',
  AGS: 'agsi'
};