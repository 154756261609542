import { EVENT_TYPES } from '../constants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

export default {
  handleEvent: (eventType, payload) => {
    switch (eventType) {
      case EVENT_TYPES.LOGIN_COMPLETE:
        const xtremepushId = ENVIRONMENT_VARIABLES.XTREMEPUSH_ID;
        if (xtremepushId != '') {
          (function (p, u, s, h, e, r, l, i, b) {
            p['XtremePushObject'] = s;
            p[s] = function () {
              (p[s].q = p[s].q || []).push(arguments);
            };
            i = u.createElement('script');
            i.async = 1;
            i.src = h;
            b = u.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(i, b);
          })(window, document, 'xtremepush', `https://prod.webpu.sh/${xtremepushId}/sdk.js`);

          window.xtremepush('ready', function () {
            window.xtremepush('push', 'auto_prompt', false);
            window.xtremepush('set', 'external_id', payload.userID);
            const permission = window.xtremepush('push', 'permission');
            if (permission == 'default') {
              window.addEventListener('click', function handler() {
                window.xtremepush('push', 'prompt');
                window.removeEventListener('click', handler);
              });
            }
          });
        }
        break;
      default:
        break;
    }
  },
};
