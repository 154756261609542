import generateRender from 'shared/utils/generateRender';
import DefaultDOBInputs from './DateOfBirth/DefaultDOBInputs';
import AdmiralDOBInputs from './DateOfBirth/AdmiralDOBInputs';

const DOBInputs = generateRender({
  default: DefaultDOBInputs,
  admiral: AdmiralDOBInputs,
  admes: AdmiralDOBInputs,
});

export default DOBInputs;

