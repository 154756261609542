import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { StoreLink } from '../../styledComponents';

export default function AdmiralEsLinkToStore({ storeLink }) {
  return (
    <StoreLink href={storeLink}>
      {'Disponible en '}
      {isIOS ? 'App Store' : isAndroid ? 'Google Play' : 'No disponible'}
    </StoreLink>
  );
}

