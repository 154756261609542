import styled from 'styled-components';

export const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  rewardsTitle: {
    fontSize: '1.4rem',
    fontWeight: '500',
  },
  divider: {
    width: '100%',
    height: '0.2rem',
    zIndex: 5000,
  },
  row: {
    justifyContent: 'space-around',
    marginTop: '0.03rem',
    width: '100%',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    objectFit: 'contain',
    marginRight: '0.25rem',
  },
  left: {
    flexDirection: 'column',
    width: '15%',
    justifyContent: 'center',
  },
  right: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '85%',
    paddingLeft: '0.5rem',
  },
};

export const MissonsTextWrapper = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  padding: 30px 5px 10px 5px;
  min-height: 6rem;
  font-style: italic;
  text-shadow: 0 0 2px #9c1f1f, 0 0 2px #9c1f1f, 0 0 2px #9c1f1f, 0 0 2px #9c1f1f;
`;

export const TooltipBackground = styled.img`
  position: absolute;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const EventsEventTextWrapper = styled(MissonsTextWrapper)`
  padding-bottom: 15%;
  font-size: 22px;
  font-weight: 900;
`;

