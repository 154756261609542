import React from 'react';
import { styles } from './components/styledComponents';
import { GENERATE_TEXTS } from './utils';
import { Container } from './components/styledComponents';
import FormContainer from './components/FormContainer';
import MessageContainer from './components/MessageContainer';
import useResetPasswordHandler from './hooks/useResetPasswordHandler';

export default function ResetPassword(props) {
  const {
    isVisible,
    closeModal,
    zIndex,
    modalStyle,
    modalContainerStyle,
    titleStyle,
    invalid,
    errorMessage,
    recoverId,
    recoverToken,
    localText,
    getTextFunc,
    textKeys: TEXT_KEY,
    TermsModalWrapper,
  } = props;

  const getText = GENERATE_TEXTS(localText, getTextFunc);
  const { passwordData, dispatch, onSumbitButtonClick } = useResetPasswordHandler({ getText, TEXT_KEY });

  return (
    <TermsModalWrapper
      isVisible={isVisible}
      zIndex={zIndex}
      title={getText(TEXT_KEY.CHOOSE_PASSWORD)}
      modalStyle={modalStyle ? modalStyle : styles.modalStyle}
      modalContainerStyle={modalContainerStyle ? modalContainerStyle : styles.modalContainerStyle}
      titleStyle={titleStyle ? titleStyle : styles.titleStyle}
      onBackButtonPress={closeModal}
      closeModal={closeModal}
      removeRightPart={true}
      removeLeftPart={true}
    >
      <Container>
        {!invalid && !passwordData.response ? (
          <FormContainer
            getText={getText}
            closeModal={closeModal}
            onSumbitButtonClick={onSumbitButtonClick}
            recoverId={recoverId}
            recoverToken={recoverToken}
            dispatch={dispatch}
            textKeys={TEXT_KEY}
            {...passwordData}
          />
        ) : (
          <MessageContainer
            getText={getText}
            textKeys={TEXT_KEY}
            {...passwordData}
            closeModal={closeModal}
            errorMessage={errorMessage}
          />
        )}
      </Container>
    </TermsModalWrapper>
  );
}

