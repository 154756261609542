import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import { FE_TIMEOUTS, FE_CACHE_MAX_AGE } from '../../../../utils/featureFlags/constants';
var DEFAULT_TIMEOUT = 60000;
var DEFAULT_CACHE_MAX_AGE = 5000;
export var getTimeout = function getTimeout(endpoint, method) {
  var _getFeatureConfig;
  var _useFeatureFlags = useFeatureFlags(),
    getFeatureConfig = _useFeatureFlags.getFeatureConfig;
  var timeout = (_getFeatureConfig = getFeatureConfig(FE_TIMEOUTS)) === null || _getFeatureConfig === void 0 || (_getFeatureConfig = _getFeatureConfig[endpoint]) === null || _getFeatureConfig === void 0 ? void 0 : _getFeatureConfig[method];
  return timeout !== null && timeout !== void 0 ? timeout : DEFAULT_TIMEOUT;
};
export var getMaxAgeCache = function getMaxAgeCache(endpoint, method) {
  var _getFeatureConfig2;
  var _useFeatureFlags2 = useFeatureFlags(),
    getFeatureConfig = _useFeatureFlags2.getFeatureConfig;
  var cache = (_getFeatureConfig2 = getFeatureConfig(FE_CACHE_MAX_AGE)) === null || _getFeatureConfig2 === void 0 || (_getFeatureConfig2 = _getFeatureConfig2[endpoint]) === null || _getFeatureConfig2 === void 0 ? void 0 : _getFeatureConfig2[method];
  return cache !== null && cache !== void 0 ? cache : DEFAULT_CACHE_MAX_AGE;
};