import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import SliderIndicator from './SliderIndicator';
import useCreateSliderCoolDownTimer from './hooks/useCreateSliderCoolDownTimer';

export default function EventsInfoSliders(props) {
  const { slides, lastSlide, eventsAssets } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const { coolDownSlide, handleCoolDownTimer } = useCreateSliderCoolDownTimer();

  const handleSelect = selectedIndex => {
    if (!coolDownSlide.current) return;
    coolDownSlide.current = false;
    lastSlide.current = selectedIndex + 1;
    setActiveIndex(selectedIndex);
    handleCoolDownTimer();
  };

  return (
    <React.Fragment>
      <Carousel wrap={false} activeIndex={activeIndex} onSelect={handleSelect} indicators={false} controls={false}>
        {slides?.map((item, index) => (
          <Carousel.Item key={`Carousel-Item-${index}`}>
            <SlideWrapper>
              <SlideText size={1.7} weight={800}>
                {item.header}
              </SlideText>
              <SlideIcon
                src={eventsAssets[item.icon]}
                alt={'Info title'}
                onError={e => {
                  e.target.style.display = 'none';
                }}
              />
              <SlideText size={2} weight={800}>
                {item.title}
              </SlideText>
              <SlideText>{item.description}</SlideText>
            </SlideWrapper>
          </Carousel.Item>
        ))}
      </Carousel>
      <SliderIndicator
        activeIndex={activeIndex}
        handleSelect={handleSelect}
        slides={slides}
        eventsAssets={eventsAssets}
      />
    </React.Fragment>
  );
}

const windowWidth = window.innerWidth;

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 350px;
`;

const SlideImage = styled.img`
  margin: 8px 0px;
`;

const SlideIcon = styled(SlideImage)`
  height: 130px;
`;

const SlideText = styled.p`
  font-size: ${({ size }) => size || 1.5}em;
  font-weight: ${({ weight }) => weight || 600};
  max-width: ${windowWidth < 1700 ? '460px' : '510px'};
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
`;

