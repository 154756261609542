export default {
  type: 'object',
  properties: {
    cardIcon: {
      type: 'string'
    },
    cardLinkingPopup: {
      type: 'object',
      properties: {
        image: {
          type: 'string'
        }
      }
    },
    dailyWheelIcon: {
      type: 'string'
    },
    enableOrderCard: {
      type: 'boolean'
    },
    highLimitPopup: {
      type: 'array',
      items: [{
        type: 'object',
        properties: {
          icon: {
            type: 'string'
          }
        },
        required: ['icon']
      }]
    },
    playerPropertyId: {
      type: 'string'
    },
    properties: {
      type: 'array',
      items: [{
        type: 'object',
        properties: {
          propertyId: {
            type: 'integer'
          },
          propertyName: {
            type: 'string'
          },
          loyaltyCardName: {
            type: 'string'
          },
          showProperty: {
            type: 'boolean'
          },
          enableOrderCard: {
            type: 'boolean'
          },
          showLinkLoyaltyCardSetting: {
            type: 'boolean'
          },
          termsAndConditionsURL: {
            type: 'string'
          },
          privacyPolicyURL: {
            type: 'string'
          }
        }
      }]
    },
    showLinkLoyaltyCardSetting: {
      type: 'boolean'
    },
    texts: {
      type: 'string'
    },
    isTermsUpdated: {
      type: 'boolean'
    }
  }
};