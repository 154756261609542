import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StarTermsContainer = styled.div`
  height: 30% !important;
`;

export const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'space-around';
  width: '90%';
  height: '90%';
  margin: '1em auto';
`;

export const TermsUpdateScreenTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(14px + (20 - 14) * ((100vw - 800px) / (1600 - 800)));
`;

export const TermsImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const TermsImage = styled.img`
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  height: auto;
`;

export const TermsFooterWrapper = styled.div`
  justify-content: space-between !important;
  height: 10% !important;
  margin-top: 3%;
`;

export const TermsUpdateButtonText = styled.p`
  font-weight: 700 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ termsState }) => (termsState ? theme.palette.primary[28] : theme.palette.primary[1])};
`;

export const GeneralSignImModalTopSpace = styled.div`
  height: 25%;
`;

export const SingInModalContainer = styled.div`
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5vmin;
`;

export const ModalButtonWrapper = styled.div`
  padding-top: 2vmin;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 1vmin;
`;

export const ModalFooterTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5vmin;
`;

