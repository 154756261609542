import { connect } from 'react-redux';
import LinkLoyaltyCard from './LinkLoyaltyCard';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { setAuthCallbackIntent } from '../../state/actions/callbackIntent';
import { setFormInfo, clearFormInfo } from '../../state/actions/formInfo';
import { setAuthorized as _setAuthorized, setToken as _setToken, setRestart as _setRestart } from '../../state/actions/auth';
import { setPlayerId as _setPlayerId } from '../../state/actions/playerInfo';
import { setLastInitApp as _setLastInitApp } from '../../state/actions/load';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    formInfo: state.formInfo,
    callbackIntent: state.callbackIntent
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    clearCallbackIntent: function clearCallbackIntent() {
      dispatch(setAuthCallbackIntent(''));
    },
    prepopulateForm: function prepopulateForm(formInfo) {
      dispatch(setFormInfo(formInfo));
    },
    setAuthorized: function setAuthorized(value) {
      dispatch(_setAuthorized(value));
    },
    setToken: function setToken(token) {
      dispatch(_setToken(token));
    },
    setRestart: function setRestart(restart) {
      dispatch(_setRestart(restart));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setPlayerId: function setPlayerId(playerId) {
      dispatch(_setPlayerId(playerId));
    },
    setLastInitApp: function setLastInitApp(lastInitApp) {
      dispatch(_setLastInitApp(lastInitApp));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LinkLoyaltyCard);