module.exports = {
  dimensions: {
    width: 975,
    height: 30
  },
  nine: {
    top: 5,
    left: 0,
    right: 0,
    bottom: 5
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  }
};