export const styles = {
  buttonContainerStyle: {
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 181,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    marginTop: '20px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
