export default {
  EMAIL: function EMAIL(value) {
    return /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value.trim());
  },
  PASSWORD: function PASSWORD(value) {
    return /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d@$!%*?&]{8,}$/.test(value);
  },
  NOT_EMPTY: function NOT_EMPTY(value) {
    return value !== null && value.trim().length > 0;
  },
  LETTERS_ONLY: function LETTERS_ONLY(value) {
    return value !== null && /^[a-zA-Z]+$/.test(value.trim());
  },
  NAME: function NAME(value) {
    return /^[a-zA-Z -]+$/.test(value);
  },
  NUMBERS_ONLY: function NUMBERS_ONLY(value) {
    return /^[\d]+$/.test(value);
  },
  LENGTH_EQUALTO: function LENGTH_EQUALTO(value, length) {
    return value.trim().length === length;
  },
  LENGTH_GREATER_THAN: function LENGTH_GREATER_THAN(value, length) {
    return value.trim().length >= length;
  },
  WORDS: function WORDS(value) {
    return /^[a-zA-Z ]+$/.test(value);
  },
  POSTAL: function POSTAL(value) {
    return /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s?\d[A-Z]\d)|(0[289][0-9]{2})|([123456789][0-9]{3}))$/.test(value);
  },
  ADULT_ONLY: function ADULT_ONLY(value) {
    return Date.now() - Date.parse(value) > 567993600000 /* Magic number of 18 years in ms */ && new Date(value).getUTCFullYear() >= 1920;
  },
  NO_VALIDATION: function NO_VALIDATION(value) {
    return true;
  },
  CREDIT_CARD: function CREDIT_CARD(value) {
    return /^[\d]+$/.test(value) && value.trim().length >= 13;
  },
  CVV: function CVV(value) {
    return value !== null && /^[\d]+$/.test(value) && value.trim().length > 2 && value.trim().length < 5;
  }
};