import React from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from '../../../components/JSModal';
import SignUpStyles from './SignUpStyles';

export const SignUpWrapper = props => {
  const {
    children,
    isModal,
    isVisible,
    zIndex,
    modalStyle,
    closeModal,
    modalMainBodyStyle,
    disableClose,
    hideCloseBtn,
    showSpinner,
  } = props;
  return isModal ? (
    <Modal
      isVisible={isVisible}
      zIndex={zIndex}
      style={modalStyle ? modalStyle : SignUpStyles.modal}
      className="terms-modal"
      onBackButtonPress={closeModal}
      modalMainBodyStyle={modalMainBodyStyle}
      customBackground
      disableBackDismiss={disableClose}
      hideCloseButton={hideCloseBtn}
    >
      <div className="SignUpModal" style={SignUpStyles.modalContainer}>
        {children}
      </div>
      {showSpinner && (
        <Spinner
          style={{
            position: 'absolute',
            top: '25%',
            left: '25%',
          }}
          animation="grow"
          className="custom-spinner"
        />
      )}
    </Modal>
  ) : (
    <>
      {children}
      {showSpinner && (
        <Spinner
          style={{
            position: 'absolute',
            top: '30%',
            left: '18.5%',
          }}
          animation="grow"
          className="custom-spinner"
        />
      )}
    </>
  );
};

