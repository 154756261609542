import { SET_NEW_AGSI_BET, SET_FEATURE_MODE_ENABLED } from './types';
export var setNewAgsiBet = function setNewAgsiBet(value) {
  return {
    type: SET_NEW_AGSI_BET,
    payload: value
  };
};
export var setFeatureModeEnabled = function setFeatureModeEnabled(value) {
  return {
    type: SET_FEATURE_MODE_ENABLED,
    payload: value
  };
};