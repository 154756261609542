import styled from 'styled-components';
import { Tooltip } from '..';

export const COMPLETE_REQUESTED = {
  TOOLTIP: styled(Tooltip)`
    width: 50vh;
    top: 33vh;
    left: 36vh;
  `,
};

