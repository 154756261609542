module.exports = {
  dimensions: {
    width: 766,
    height: 540
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};