import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import QueryParamProxy from '../queryParamProxy';
import RouteParamConstants from '../routeParamConstants';
import Screens from '../../screens';
import UpgradeType from '../../screens/UpgradeAccountFlow/UpgradeType';
import ENVIRONMENT_VARIABLES from '../environmentVariables';
import rewardCenterMiddleware from '../../screens/RewardCenter/utils/middleware';
import eventsMiddleware from '../../screens/Events/utils/middleware';
export var DynamicActionKey = {
  NO_ACTION: '',
  GO_TO_COIN_STORE: 'gotoStore',
  GO_TO_REWARDS: 'rewards',
  LAUNCH_GAME: 'game',
  // parse text
  LAUNCH_GAMEROOM: 'gameroom',
  // unconfirmed
  REDIRECT_TO_EXTERNAL_PAGE: 'url',
  // parse text
  UPGRADE_GUEST_ACCOUNT: 'upgradeGuestAccount',
  DOWNLOAD_MOBILE_APP: 'downloadMobileApp',
  // unconfirmed
  LINK_LOYALTY_CARD: 'linkLoyaltyCard',
  REDEEM_PROMO_CODE: 'redeemPromoCode',
  // unconfirmed
  LAUNCH_CHALLENGE_INFO: 'challenge',
  VIP_PASS: 'vippass',
  SPECIAL_OFFER: 'offer',
  SNEAK_PEEK: 'sneakPeek',
  MISSIONS: 'missions',
  EVENT_CARD: 'eventCard'
};
export var DynamicAction = {
  /**
   * Get a callback function by passing in a dynamic action key
   * @memberof DynamicAction
   * @param {String} key Dynamic action key [you can import from DynamicAction class]
   * @param {Object} navigation navigation props for the callback to function
   * @returns {Function}
   */
  getActionCallback: function getActionCallback(key, navigation, notificationContext) {
    console.log("Action Triggered: ".concat(key));

    // split up action and payload
    var _key$split = key.split(':'),
      _key$split2 = _slicedToArray(_key$split, 2),
      action = _key$split2[0],
      payload = _key$split2[1];
    switch (action) {
      case DynamicActionKey.NO_ACTION:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
        };
      case DynamicActionKey.DOWNLOAD_MOBILE_APP:
        return function () {
          alert('Please Go Download Mobile App!');
        };
      case DynamicActionKey.GO_TO_COIN_STORE:
      case DynamicActionKey.SPECIAL_OFFER:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.Shop));
        };
      case DynamicActionKey.EVENT_CARD:
        return function () {
          return eventsMiddleware({
            navigation: navigation,
            notificationContext: notificationContext,
            eventId: payload
          });
        };
      case DynamicActionKey.GO_TO_REWARDS:
        return function () {
          return rewardCenterMiddleware({
            navigation: navigation,
            notificationContext: notificationContext
          });
        };
      case DynamicActionKey.LAUNCH_GAME:
        var gameId = payload;
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
          QueryParamProxy.navigate(navigation, Screens.Game, _defineProperty({}, RouteParamConstants.PARAM_GAME_ID, gameId));
        };
      case DynamicActionKey.SNEAK_PEEK:
        var splittedPayload = payload.split(',');
        var targetGameId = splittedPayload[0];
        var sneakPeekEndDate = splittedPayload[1];
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined), RouteParamConstants.PARAM_SNEAK_PEEK_END_DATE, sneakPeekEndDate));
          QueryParamProxy.navigate(navigation, Screens.Game, _defineProperty({}, RouteParamConstants.PARAM_GAME_ID, targetGameId));
        };
      case DynamicActionKey.LAUNCH_GAMEROOM:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
          QueryParamProxy.navigate(navigation, Screens.Tables);
        };
      case DynamicActionKey.VIP_PASS:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.PurchaseSucceeded));
        };
      case DynamicActionKey.LINK_LOYALTY_CARD:
        return function () {
          if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() !== 'true') {
            QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.LinkAccountForm));
          } else if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() === 'true') {
            QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.LinkAccount));
          }
        };
      case DynamicActionKey.REDEEM_PROMO_CODE:
        return function () {
          alert('Please implement me!');
        };
      case DynamicActionKey.REDIRECT_TO_EXTERNAL_PAGE:
        return function () {
          if (payload.indexOf('http') < 0) {
            payload = "https://".concat(payload);
          }
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_EXTERNAL_URL, payload));
        };
      case DynamicActionKey.UPGRADE_GUEST_ACCOUNT:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.UpgradeAccountFlow), RouteParamConstants.PARAM_UPGRADE_TYPE, UpgradeType.COMBINED));
        };
      case DynamicActionKey.MISSIONS:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
          QueryParamProxy.navigate(navigation, Screens.Missions);
        };
      default:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
        };
    }
  }
};
export default DynamicAction;