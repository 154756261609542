module.exports = {
  dimensions: {
    width: 76,
    height: 76
  },
  content: {
    top: 29,
    left: 29,
    right: 29,
    bottom: 29
  },
  optical: {
    top: 26,
    left: 26,
    right: 26,
    bottom: 26
  }
};