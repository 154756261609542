import styled from 'styled-components';

export const SubTitle = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
`;

