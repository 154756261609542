export default {
  type: 'object',
  properties: {
    assetsPackageId: {
      type: 'string'
    },
    otpExpired: {
      type: 'boolean'
    },
    offers: {
      type: 'object',
      properties: {
        rid: {
          type: 'string'
        },
        thumb: {
          type: 'string'
        },
        cat_name: {
          type: 'string'
        },
        rname: {
          type: 'string'
        },
        rewardtype: {
          type: 'string'
        },
        lpts: {
          type: 'number'
        },
        ribbontype: {
          type: 'string'
        }
      }
    },
    filterList: {
      type: 'object',
      properties: {
        rc_id: {
          type: 'string'
        },
        cat_name: {
          type: 'string'
        },
        title_image: {
          type: 'string'
        },
        cat_desc: {
          type: 'string'
        }
      }
    },
    profile: {
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        playerProfileInfo: {
          type: 'object',
          nullable: true,
          properties: {
            memberLevel: {
              type: 'string'
            },
            balances: {
              type: 'object',
              nullable: true,
              properties: {
                displayName: {
                  type: 'string'
                },
                currency: {
                  type: 'string'
                },
                balance: {
                  type: 'string'
                }
              }
            }
          }
        },
        loyaltyExpiryDate: {
          type: 'string'
        },
        linkedCasinoId: {
          type: 'string'
        },
        enableOrderCard: {
          type: 'string'
        },
        displayRewardProfileScreen: {
          type: 'string'
        },
        displayRewardHistoryScreen: {
          type: 'boolean'
        },
        displayRewardLoyaltyScreen: {
          type: 'boolean'
        }
      }
    }
  }
};