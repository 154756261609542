import { ASSIGN_TRANSACTION_UUID, ASSIGN_USER_UUID, INITIALIZE_SESSION_UUID, INITIATE_PURCHASE, SET_ACTION_TRIGGER } from './types';
export var initiatePurchase = function initiatePurchase() {
  var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return {
    type: INITIATE_PURCHASE,
    payload: status
  };
};
export var assignTransationUUID = function assignTransationUUID(value) {
  return {
    type: ASSIGN_TRANSACTION_UUID,
    payload: value
  };
};
export var assignUserUUID = function assignUserUUID(value) {
  return {
    type: ASSIGN_USER_UUID,
    payload: value
  };
};
export var initializeSessionUUID = function initializeSessionUUID(value) {
  return {
    type: INITIALIZE_SESSION_UUID,
    payload: value
  };
};
export var setActionTrigger = function setActionTrigger(value) {
  return {
    type: SET_ACTION_TRIGGER,
    payload: value
  };
};