import { NavigationActions } from '@react-navigation/core';
var _navigator;
function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
}
function navigate(routeName, params) {
  _navigator.dispatch(NavigationActions.navigate({
    routeName: routeName,
    params: params
  }));
}
function getCurrentRoute() {
  var _navigator2;
  return (_navigator2 = _navigator) === null || _navigator2 === void 0 || (_navigator2 = _navigator2.state) === null || _navigator2 === void 0 ? void 0 : _navigator2.nav;
}
export default {
  navigate: navigate,
  setTopLevelNavigator: setTopLevelNavigator,
  getCurrentRoute: getCurrentRoute
};