import { connect } from 'shared/node_modules/react-redux';
import PurchaseFlow from './PurchaseFlow';

const mapStateToProps = (state) => {
  return {
    promoLink: state.pendingMessages.find((pendingMessage) => pendingMessage.type === 'PROMO_LINK'),
    termsAndConditionsURL: state.app.assetsUrls.termsAndConditions,
    paymentProvider: state.shopList.paymentProvider,
  };
};

export default connect(mapStateToProps)(PurchaseFlow);
