export default (function (amount) {
  var numbersArray = Array.from(Array(amount).keys());
  return numbersArray.map(function (num) {
    var label = "".concat(num + 1);
    if ((num + 1).length === 1) {
      label = "0".concat(label);
    }
    return {
      value: num + 1,
      label: label
    };
  });
});