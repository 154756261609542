import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'PROGRESSIVE_JACKPOTS';
var initialState = {
  isFetchingData: false,
  data: [],
  errorMsg: ''
};
var progressiveJackpotsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    progressiveJackpotsRequestData: function progressiveJackpotsRequestData(state, action) {},
    progressiveJackpotsIsFetching: function progressiveJackpotsIsFetching(state, action) {
      state.isFetchingData = action.payload;
    },
    progressiveJackpotsRequestError: function progressiveJackpotsRequestError(state, action) {
      state.isFetchingData = false;
      state.errorMsg = action.payload;
    },
    progressiveJackpotsRequestDataSuccess: function progressiveJackpotsRequestDataSuccess(state, action) {
      state.isFetchingData = false;
      state.data = action.payload.result.progressiveJackpots;
    }
  }
});
var actions = progressiveJackpotsSlice.actions,
  reducer = progressiveJackpotsSlice.reducer;
var progressiveJackpotsRequestData = actions.progressiveJackpotsRequestData,
  progressiveJackpotsIsFetching = actions.progressiveJackpotsIsFetching,
  progressiveJackpotsRequestError = actions.progressiveJackpotsRequestError,
  progressiveJackpotsRequestDataSuccess = actions.progressiveJackpotsRequestDataSuccess;
export { progressiveJackpotsRequestData, progressiveJackpotsIsFetching, progressiveJackpotsRequestError, progressiveJackpotsRequestDataSuccess };
export default reducer;