import React from 'react';

export default function(props) {
  return (
    <div style={props.customStyle} className="ActivityIndicator">
      <div className="ActivityItem" />
      <div className="ActivityItem" />
      <div className="ActivityItem" />
    </div>
  );
}
