import { PAGE_SIZE } from '../../screens/Home/Home';
export var generateGameListData = function generateGameListData(pagedGames, pageNumber, ftueStep) {
  var scrollItemIndex = 0;
  var totalGamesCount = 0;
  var topRow = [];
  var bottomRow = [];
  pagedGames.forEach(function (gamePage, pageIndex) {
    var gameLength = gamePage.length;
    totalGamesCount += gamePage.length;
    gamePage.forEach(function (game, index) {
      if (gameLength === PAGE_SIZE) {
        index < PAGE_SIZE / 2 ? topRow.push(game) : bottomRow.push(game);
      } else {
        index % 2 === 0 ? topRow.push(game) : bottomRow.push(game);
      }
      if (pageIndex === pageNumber) {
        scrollItemIndex = ftueStep !== null ? 0 : topRow.length - 5;
      }
    });
  });
  var gameList = topRow.map(function (item, index) {
    return [item, bottomRow[index]];
  });
  return {
    gameList: gameList,
    scrollItemIndex: scrollItemIndex,
    totalGamesCount: totalGamesCount
  };
};