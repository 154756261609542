export var TooltipTypes = {
  COINS: 'coinBar',
  XP: 'xpBar',
  TIER: 'tierBar',
  LOYALTY: 'loyaltyBar',
  FTUE: 'ftue',
  MISSIONS: 'missions',
  REWARD_CENTER: 'rewardCenter',
  LINKING_ACCOUNT: 'linkingAccount',
  EVENTS: 'events'
};