export default {
  getUID: function getUID() {
    return '99';
  },
  getDevice: function getDevice() {
    return 'web';
  },
  getSysVer: function getSysVer() {
    return '1.0';
  },
  getBundleVer: function getBundleVer() {
    return '1.0';
  },
  getBundleId: function getBundleId() {
    return 'bundle_id';
  }
};