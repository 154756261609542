import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme}
  text-align: center;
  padding: 40px;
  height: ${window.innerHeight}px;
`;

export const LogoAsset = styled.img`
  width: 70%;
  margin: 0px;
`;

export const DividerDiv = styled.div`
  height: 20px;
  width: 20px;
`;

export const StoreLogoAsset = styled.img`
  width: 100%;
`;

export const LinkAssetHolder = styled.a`
  margin-top: 50px;
`;

export const DisclaimerText = styled.p`
  font-size: 8px;
  margin-top: 10px;
`;

