import styled from 'styled-components';

const BarContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow:
    0 20px 30px 0 rgba(0, 0, 0, 0.1),
    0 5px 4px 0 rgba(0, 0, 0, 0.15),
    0 4px 4px 0 rgba(0, 0, 0, 0.6);

  ${({ theme }) => theme}
  ${({ barContainerStyle }) => barContainerStyle}
  ${({ animation }) => animation}
`;

const Progress = styled.div`
  height: 2.4rem;
  width: ${({ progress }) => progress}%;
  transition: width 0.5s ease-in-out;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow:
    0 20px 30px 0 rgba(0, 0, 0, 0.1),
    0 5px 4px 0 rgba(0, 0, 0, 0.15),
    0 4px 4px 0 rgba(0, 0, 0, 0.6);
  ${({ theme }) => theme}
  ${({ progressStyle }) => progressStyle}
`;

const BarText = styled.span`
  font-weight: 700;
  margin: 0 auto;
  ${({ theme }) => theme}
  ${({ customStyle }) => customStyle}
`;

const BarIcon = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  object-fit: contain;
  z-index: 1;
  ${({ customStyle }) => customStyle}
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled(Container)`
  position: absolute;
`;

const BarShineEffect = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 1rem;
  width: ${({ progress }) => `calc(${progress}% - 20%)`};
  height: 45%;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.15) inset;
  -webkit-box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.15) inset;
  -moz-box-shadow: 0px 0px 8px 4px rgba(255, 255, 255, 0.15) inset;
  transition: width 0.5s ease-in-out;
  ${({ customStyle }) => customStyle}
`;

export { BarContainer, Progress, BarText, BarIcon, TextContainer, Container, BarShineEffect };

