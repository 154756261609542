import React from 'react';
import styled from 'styled-components';
import InviteScreen from './components/InviteScreen';
import LimitReachedScreen from './components/LimitReachedScreen';
import NotificationScreen from './components/NotificationScreen';

const FriendsInvite = props => {
  return (
    <>
      <Container>
        <InviteScreen {...props} />
        <LimitReachedScreen {...props} />
        <NotificationScreen {...props} />
      </Container>
    </>
  );
};

export default FriendsInvite;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

