import generateRender from 'shared/utils/generateRender';
import StreakDaysRowStar from './StreakDaysRowStar';
import StreakDaysRowDefault from './StreakDaysRowDefault';

const StreakDaysRow = generateRender({
  default: StreakDaysRowDefault,
  star: StreakDaysRowStar,
});

export default StreakDaysRow;

