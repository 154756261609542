import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import generateRender from '../../../../utils/generateRender';
import { handleTooltip } from './tooltipCommands';
var getPositionLinkAccountTooltip = function getPositionLinkAccountTooltip() {
  return generateRender({
    default: {
      left: window.innerWidth >= 1920 ? '69%' : window.innerWidth >= 1700 ? '72vw' : '73vw'
    },
    admiral: {
      left: window.innerWidth >= 1920 ? '67.5%' : window.innerWidth >= 1700 ? '72vw' : '73vw'
    },
    admes: {
      left: window.innerWidth >= 1920 ? '67.5%' : window.innerWidth >= 1700 ? '72vw' : '73vw'
    }
  });
};
var getPositionRewardCenterTooltip = function getPositionRewardCenterTooltip() {
  return generateRender({
    default: {
      top: 'calc(100% - 280px)'
    },
    admiral: {
      top: 'calc(100% - 310px)'
    },
    admes: {
      top: 'calc(100% - 310px)'
    }
  });
};
export var handleLinkAccountTooltip = function handleLinkAccountTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: _objectSpread({
      top: '12vh',
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto'
    }, getPositionLinkAccountTooltip()),
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    arrowStyle: {
      left: 70
    }
  };
  handleTooltip(details, props, actionCommand);
};
export var handleRewardCenterTooltip = function handleRewardCenterTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: _objectSpread({
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '400px',
      left: 'calc(50% - 200px)'
    }, getPositionRewardCenterTooltip()),
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    bottom: true
  };
  handleTooltip(details, props, actionCommand);
};
var getDetailEventsTooltip = function getDetailEventsTooltip(title) {
  return {
    title: title,
    animationState: 'AnimationFadeIn',
    style: {
      zIndex: '9999',
      fontWeight: '600',
      top: '10vh',
      left: '1vw',
      width: '23vw',
      height: '13vh'
    },
    displayTextStyle: {
      fontSize: '15px',
      fontStyle: 'italic',
      flex: 1
    },
    showArrow: false
  };
};
export var handleEventsTooltip = function handleEventsTooltip(actionCommand, props) {
  var details = getDetailEventsTooltip(actionCommand.tooltip.text);
  handleTooltip(details, props, actionCommand);
};