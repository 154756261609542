module.exports = {
  dimensions: {
    width: 115,
    height: 125
  },
  content: {
    top: 12,
    left: 12,
    right: 12,
    bottom: 12
  },
  optical: {
    top: 12,
    left: 12,
    right: 12,
    bottom: 12
  }
};