import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import { BarContainer, BarIcon, BarShineEffect, BarText, Container, Progress, TextContainer } from './styledComponents';

const Bar = ({
  progress,
  max = 100,
  barIcon,
  iconStyle = {},
  label,
  labelStyle = {},
  percentageStyle = {},
  animation = null,
  barContainerStyle = {},
  progressStyle = {},
  shineEffect = false,
  shineEffectStyles = {},
  showPercentage = false,
  showProgress = false,
}) => {
  const { Bar } = useContext(ThemeContext);
  const percentage = progress !== null && progress !== undefined ? (progress / max) * 100 : null;

  return (
    <>
      <BarContainer theme={Bar.BarContainer} animation={animation} barContainerStyle={barContainerStyle}>
        <BarIcon src={barIcon} customStyle={iconStyle} />
        <TextContainer>
          <BarText
            theme={Bar.BarText}
            customStyle={progress !== null && progress !== undefined ? percentageStyle : labelStyle}
          >
            {showPercentage ? `${percentage.toFixed(0)}%` : label}
          </BarText>
        </TextContainer>
        <Container>
          <Progress theme={Bar.BarProgress} progress={showProgress && progress} progressStyle={progressStyle} />
        </Container>
      </BarContainer>
      {shineEffect && <BarShineEffect progress={progress} customStyle={shineEffectStyles} />}
    </>
  );
};

export default Bar;

