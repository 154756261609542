export var checkIfOtpIsRequired = function checkIfOtpIsRequired(selectedPropertyId, properties) {
  var selectedProperty = properties.find(function (property) {
    return property.propertyId == selectedPropertyId;
  });
  return (selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.requiresOtp) || false;
};
export var checkIfOtpRequeridedConsents = function checkIfOtpRequeridedConsents(selectedPropertyId, properties) {
  var selectedProperty = properties.find(function (property) {
    return property.propertyId == selectedPropertyId;
  });
  return (selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.requiresConsents) || false;
};
export var OTP_TRANSACTION_DATA_KEY = 'otpAuthTransaction';