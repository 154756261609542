import React from 'react';
import SFormSubtitle from 'shared/screens/LinkAccountForm/components/FormSubtitle';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { numberWithCommas } from 'shared/utils/number';
import CopySelector from 'shared/utils/copySelector';

const DefaultFormSubtitle = ({ copys }) => {
  return (
    <SFormSubtitle
      render={args => {
        const { linkedLoyaltyCardBonus } = args;
        return (
          <p style={{ textAlign: 'center' }}>
            {CopySelector.getCopy(CopySelector.COPY_TYPES.LINK_CARD_SUBTITLE, copys)}
            <br />
            <span style={{ fontWeight: 'bold' }}>
              {getText(TEXT_KEY.EARN)} {numberWithCommas(linkedLoyaltyCardBonus.amount)}{' '}
              {linkedLoyaltyCardBonus.currency} {getText(TEXT_KEY.WHEN_YOU_LINK_YOUR_ACCOUNT)}
            </span>
          </p>
        );
      }}
    />
  );
};

export default DefaultFormSubtitle;

