module.exports = {
  dimensions: {
    width: 455,
    height: 39
  },
  // nine: {
  //   top: 8,
  //   left: 16,
  //   right: 16,
  //   bottom: 8
  // },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};