import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const MainContainer = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Main = styled.div`
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: flex-start;
`;

const ActivityIndicatorContainer = styled.div`
  height: 60vh;
  max-height: 630px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 60vh;
  max-height: 550px;
  width: 100%;
  margin-top: 0;
  padding: 38px 15px 0 15px;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
`;

const TitleText = styled.p`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: ${theme.palette.common[1]};
  margin-top: 0;
  margin-bottom: 0;
`;

const Divider = styled(ImageBW)`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const CustomInputLabel = styled.span`
  color: ${theme.palette.common[1]};
`;

const OfferButtonContainerStyle = styled.div`
  height: 100%;
  width: 100%;
`;

const modalStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6vh 16vw 0',
  zIndex: '10',
};

const ModalMainBodyStyle = {
  width: '100%',
  maxWidth: '1100px',
  maxHeight: '58vh',
};

export {
  modalStyle,
  ModalMainBodyStyle,
  MainContainer,
  Main,
  ActivityIndicatorContainer,
  DetailsContainer,
  TitleText,
  Divider,
  CustomInputLabel,
  OfferButtonContainerStyle,
};

