import * as yup from 'yup';
import { getText, TEXT_KEY } from '../../utils/localization';
import Utils from '../../utils/platform';
export var initialValues = {
  email: '',
  password: ''
};
var isWeb = Utils.getPlatform() === 'web';
export var validationSchema = yup.object({
  email: yup.string().email(isWeb ? function () {
    return getText(TEXT_KEY.INVALID_EMAIL_FORMAT);
  } : getText(TEXT_KEY.INVALID_EMAIL_FORMAT)).required(isWeb ? function () {
    return getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED);
  } : getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED)),
  password: yup.string().min(6, isWeb ? function () {
    return getText(TEXT_KEY.PASSWORD_LENGTH);
  } : getText(TEXT_KEY.PASSWORD_LENGTH)).required(isWeb ? function () {
    return getText(TEXT_KEY.LOGIN_PASSWORD_REQUIRED);
  } : getText(TEXT_KEY.LOGIN_PASSWORD_REQUIRED))
});