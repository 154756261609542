module.exports = {
  dimensions: {
    width: 92,
    height: 100
  },
  nine: {
    top: 180,
    left: 180,
    right: 180,
    bottom: 180
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  },
  content: {
    top: 12,
    left: 1,
    right: 1,
    bottom: 18
  }
};