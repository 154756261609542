import { getText, TEXT_KEY } from '../../utils/localization';
export default function ValidatorError() {
  return {
    EMAIL: getText(TEXT_KEY.INVALID_EMAIL_FORMAT),
    PASSWORD: getText(TEXT_KEY.VALIDATOR_PASSWORD),
    NOT_EMPTY: getText(TEXT_KEY.VALIDATOR_NOT_EMPTY),
    LETTERS_ONLY: getText(TEXT_KEY.VALIDATOR_LETTERS_ONLY),
    NUMBERS_ONLY: getText(TEXT_KEY.VALIDATOR_NUMBERS_ONLY),
    WORDS: getText(TEXT_KEY.VALIDATOR_WORDS),
    POSTAL: getText(TEXT_KEY.VALIDATOR_POSTAL),
    ADULT_ONLY: getText(TEXT_KEY.VALIDATOR_ADULT_ONLY),
    CREDIT_CARD: getText(TEXT_KEY.VALIDATOR_CREDIT_CARD),
    CVV: getText(TEXT_KEY.VALIDATOR_CVV),
    NO_VALIDATION: "",
    LENGTH_GREATER_THAN: getText(TEXT_KEY.PASSWORD_LENGTH)
  };
}